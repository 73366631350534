import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { JobSeekerEmploymentDetailModel } from 'src/app/model/JobSeekerEmploymentDetail';
import { JobSeekerSkillCertificationModel } from 'src/app/model/JobSeekerSkillCertification';
import { AuthService } from 'src/app/services/auth.service';
import { JobSeekerService } from 'src/app/services/jobSeeker';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-job-seeker-skillcertification',
  templateUrl: './job-seeker-skillcertification.component.html',
  styleUrls: ['./job-seeker-skillcertification.component.scss']
})
export class JobSeekerSkillcertificationComponent implements OnInit {
  constructor(private authService: AuthService, public masterService: MasterService,
    private tost: ToastrService, private jobSeekerSrv: JobSeekerService) {

  }
  ngOnInit(): void {
    this.getSkillCertificationAll(this.authService.getAuthStorage().jobseekerId);
    this.monthList = this.masterService.monthList();
    this.yearList = this.masterService.yearList();
  }

  configStatic = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: false,
    // searchOnKey: 'nameEn',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };
  // Job Seeker Employment Detail

  submitEmployment: boolean = false;
  yearList: number[] = [];
  monthList: number[] = [];
  jobSeekerSkillCertification: JobSeekerSkillCertificationModel = {
    id: 0,
    jobSeekerId: this.authService.getAuthStorage().jobseekerId,
    name: '',
    lastUsed: 0,
    experienceYear: 0,
    experienceMonth: 0
  }
  jobSeekerSkillCertificationList: Array<JobSeekerSkillCertificationModel> = [];
  jobSeekerSkillCertificationMandatory = [
    'name',
    'experienceYear',
    'experienceMonth'
  ]
  noExpiry:boolean = false;
  saveSkillCertification() {
    if(this.noExpiry){
      this.jobSeekerSkillCertification.experienceYear = 2099;
      this.jobSeekerSkillCertification.experienceMonth = 12;
    }
    if (this.masterService.validate(this.jobSeekerSkillCertificationMandatory, this.jobSeekerSkillCertification)) {
      //Api call
      if (this.jobSeekerSkillCertification.id == 0) {
        this.jobSeekerSrv.saveKeySkillCertification(this.jobSeekerSkillCertification).subscribe((data) => {
          this.getSkillCertificationAll(this.authService.getAuthStorage().jobseekerId);
          this.tost.success("Operation Succeeded");
          (document.querySelector(".close-btn") as any).click();
          this.newCertification();

        });
      } else {
        this.jobSeekerSrv.updateKeySkillCertification(this.jobSeekerSkillCertification).subscribe((data) => {
          this.getSkillCertificationAll(this.authService.getAuthStorage().jobseekerId);
          this.tost.success("Operation Succeeded");
          (document.querySelector(".close-btn") as any).click();
          this.newCertification();
        });;
      }
    } else {
      this.submitEmployment = true;
    }
  }
  newCertification() {
    this.jobSeekerSkillCertification = {
      id: 0,
      jobSeekerId: this.authService.getAuthStorage().jobseekerId,
      name: '',
      lastUsed: 0,
      experienceYear: 0,
      experienceMonth: 0
    }
    this.noExpiry = false;
  }
  getSkillCertificationById(id: Number) {
    this.jobSeekerSrv.getKeySkillCertificationById(id).subscribe((data) => {
      this.jobSeekerSkillCertification = data;
      if(this.jobSeekerSkillCertification.experienceYear ==2099){
        this.noExpiry = true;
      }
    });
  }
  deleteSkillCertification(id: Number) {
    this.jobSeekerSrv.removeKeySkillCertification(id).subscribe((data) => {
      this.getSkillCertificationAll(this.authService.getAuthStorage().jobseekerId);
      this.tost.success("Operation Succeeded");
    });
  }
  getSkillCertificationAll(id: Number) {
    this.jobSeekerSrv.getKeySkillCertificationBySeekerId(id).subscribe((data) => {
      this.jobSeekerSkillCertificationList = data;
    });
  }
  // Ended Job Seeker Employment Detail


}
