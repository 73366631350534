<div class="page-wraper container">

    <!-- <app-navbar-style-one></app-navbar-style-one> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content p-t30 ">
        <app-loading *ngIf="loader.loader"></app-loading>
        <!-- <app-login *ngIf="type == 'login'" [type]="type" [userType]="userType" (toggle)="toggle($event)"></app-login> -->
        <div class="modal-content margin">
            <div>
                <div class="">
                    <h2 class="modal-title text-center" id="sign_up_popupLabel">Sign Up</h2>
                    <hr />
                </div>

                <div class="modal-body">
                    <div class="twm-tabs-style-2 d-flex align-content-center">
                        <!-- <ul class="nav nav-tabs" id="myTab" role="tablist">

                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#sign-candidate"
                                    type="button"><i class="fas fa-user-tie"></i> Candidate</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#sign-Employer"
                                    type="button"><i class="fas fa-building"></i> Employer</button>
                            </li>

                        </ul> -->
                        <div class="tab-content" id="myTabContent">
                            <!--Signup Candidate Content-->
                            <div class="tab-pane fade show active" id="sign-candidate">
                                <div class="row">

                                    <div class="col-lg-6 col-md-12">
                                        <label>Name:</label>
                                        <div class="form-group mb-3">
                                            <input name="Name" type="text" class="form-control" required=""
                                                placeholder="Name*" [(ngModel)]="user.fullNameEn">
                                            <span class="mandatory" *ngIf="user.fullNameEn == '' && isSubmit">This field
                                                is requied</span>
                                        </div>
                                    </div>


                                    <div class="col-lg-6 col-md-12">
                                        <label>Last Name:</label>
                                        <div class="form-group mb-3">
                                            <input name="Name" type="text" class="form-control" required=""
                                                placeholder="Last Name *" [(ngModel)]="user.fullNameAr">
                                            <span class="mandatory" *ngIf="user.fullNameAr == '' && isSubmit">This field
                                                is requied</span>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-6 col-md-12">
                                        <label>User Name:</label>
                                        <div class="form-group mb-3">
                                            <input name="username" type="text" required="" class="form-control"
                                                placeholder="Usearname*" [(ngModel)]="user.userName">
                                            <span class="mandatory" *ngIf="user.userName == '' && isSubmit">This field
                                                is requied</span>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-6 col-md-12">
                                        <label>Mobile No:</label>
                                        <div class="form-group mb-3">
                                            <input name="mobileNo" type="number" class="form-control" required=""
                                                placeholder="9712838388323" [(ngModel)]="user.mobileNo">
                                            <span class="mandatory"
                                                *ngIf="(user.mobileNo == '' || user.mobileNo == undefined) && isSubmit">This
                                                field is
                                                requied</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <label>Current Location:</label>
                                        <div class="form-group mb-3">
                                            <input name="currentCity" type="text" class="form-control" required=""
                                                placeholder="Olaya Street, 20016 " [(ngModel)]="user.currentCity">
                                            <span class="mandatory" *ngIf="user.currentCity == '' && isSubmit">This
                                                field is requied</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <label>Email:</label>
                                        <div class="form-group mb-3">
                                            <input name="email" type="text" class="form-control" required=""
                                                placeholder="Email*" [(ngModel)]="user.email">
                                            <span class="mandatory" *ngIf="(user.email == '') && isSubmit">This
                                                field is
                                                requied</span>
                                            <span class="mandatory"
                                                *ngIf="(user.email != '' && (!user.email.includes('@') || !user.email.includes('.'))) && isSubmit">Enter
                                                Valid Email</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <label>Password:</label>
                                        <div class="form-group mb-3">
                                            <input name="password" type="password" class="form-control" required=""
                                                placeholder="Password*" [(ngModel)]="user.password">
                                            <span class="mandatory" *ngIf="user.password== '' && isSubmit">This field is
                                                requied</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <label>CV Headline:</label>
                                        <div class="form-group mb-3">
                                            <input name="cvheadline" type="text" class="form-control" required=""
                                                placeholder="Desire Job Title" [(ngModel)]="user.cvheadline">
                                            <span class="mandatory" *ngIf="user.cvheadline == '' && isSubmit">This field
                                                is requied</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group mb-3">

                                            <div class="dashboard-profile-pic">
                                                <div class="dashboard-profile-photo">
                                                    <!-- <img [src]="url" alt=""> -->
                                                    <div class="upload-btn-wrapper">
                                                        <div id="upload-image-grid"></div>
                                                        <button class="site-button button-sm">Upload Resume</button>
                                                        <input type="file" name="myfile" id="" accept=".pdf"
                                                            (change)="uploadPdf($event)">
                                                        <p>{{resumeFile}}</p>
                                                        <!-- <span class="mandatory" *ngIf="file0 == null && isSubmit">This field is requied</span> -->
                                                    </div>
                                                </div>
                                                <span class="mandatory" *ngIf="user.resume == '' && isSubmit">This field
                                                    is requied</span>
                                                <p><b>Resume </b> </p>
                                            </div>

                                        </div>
                                    </div>


                                    <div class="col-sm-12 col-lg-6">
                                        <div class="form-group mb-3">
                                            <div class=" form-check">
                                                <input type="checkbox" [(ngModel)]="termCond" class="form-check-input"
                                                    id="agree1">
                                                <label class="form-check-label" for="agree1">I agree to the <a
                                                        href="javascript:;">Terms and conditions</a></label>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <button class="site-button btn" (click)="onSighUp()">Sign
                                            Up</button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;

                                        Already have an account ? Please <button class="btn btn-primary"
                                            data-bs-target="#sign_up_popup2" data-bs-toggle="modal"
                                            data-bs-dismiss="modal" [routerLink]="'/login'"> Login </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="modal-footer">
                    <span class="modal-f-title">Login or Sign up with</span>
                    <ul class="twm-modal-social">
                        <li><a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="javascript" class="twitter-clr"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="javascript" class="google-clr"><i class="fab fa-google"></i></a></li>
                    </ul>
                </div> -->

            </div>
        </div>
    </div>
</div>
<!-- End Post A Job -->