import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class JobSeekerNotificationService {
    private jobSeekerNotipath = environment.apiUrl + 'JobSeekerNotification/';
    constructor(private http: HttpClient) { }

    getByJobSeekerId(jobSeekerId: Number): Observable<any> {
        return this.http.get(this.jobSeekerNotipath + "ByJobSeekerId?jobSeekerId=" + jobSeekerId);
    }


    getCount(jobSeekerId: Number): Observable<any> {
        return this.http.get(this.jobSeekerNotipath + "GetCount?jobSeekerId=" + jobSeekerId);
    }
    updateBulk(jobSeekerId: Number): Observable<any> {
        return this.http.put(this.jobSeekerNotipath + "UpdateBulk/" + jobSeekerId, {});
    }
    create(data: any): Observable<any> {
        return this.http.post(this.jobSeekerNotipath + "Create", data);
    }
}
