<div class="twm-s-info-wrap mt-3">
    <h4 class="twm-s-title m-t0">
        Languages
        <button type="submit" class="btn site-button action" (click)="showEditToggle()" *ngIf="edit">
            <mat-icon *ngIf="!showEdit">add</mat-icon>
            <mat-icon *ngIf="showEdit">cancel</mat-icon>
        </button>
    </h4>

    <div class="row" *ngIf="showEdit">
        <div class="col-6">
            <label _ngcontent-sva-c15="">Language:</label>
            <input class="form-control" [(ngModel)]="
            jobSeekerLanguageModel.nameEn
                " />

            <span class="mandatory" *ngIf="
                jobSeekerLanguageModel.nameEn ==
                        '' && submitProfile
                ">This field is requied</span>

        </div>
        <div class="col-6">
            <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                <label _ngcontent-sva-c15="">Level:</label>
                <ngx-select-dropdown [(ngModel)]="jobSeekerLanguageModel.levelKnown" [instanceId]="'instance4'"
                    [multiple]="false" [options]="['Beginer','Intermediate','Professional']"></ngx-select-dropdown>

            </div>
            <span class="mandatory" *ngIf="
        jobSeekerLanguageModel.levelKnown ==
                    '' &&
                    submitProfile
            ">This field
                is
                requied</span>
        </div>
    </div>
    <button *ngIf="showEdit" class="btn site-button mt-3" (click)="saveLanguage()">
        Save
    </button>
    <div class="skills-cont d-flex" *ngIf="!showEdit">
        <div class="skills" *ngFor="let item of jobSeekerLanguageModelList">
            <div>

                <span>{{item.nameEn}}</span>
                <mat-icon (click)="remove(item.id)" *ngIf="edit">delete</mat-icon>
            </div>
            <div>
                <span class="level">{{item.levelKnown}}</span>
            </div>

        </div>
    </div>
    <!-- <p *ngIf="!showEdit">
        {{
        jobSeekerProfileSummary.summary
        }}
    </p> -->
</div>