import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JobsModel } from 'src/app/model/Jobs';
import { AuthService } from 'src/app/services/auth.service';
import { JobService } from 'src/app/services/job.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-job-widget',
  templateUrl: './job-widget.component.html',
  styleUrls: ['./job-widget.component.scss']
})
export class JobWidgetComponent implements OnInit {
  @Input() job: JobsModel | null;
  baseUrl: string = environment.apiUrl + "ProfilePhoto/";
  error: boolean = false;

  constructor(public masterService: MasterService, private jobSrv: JobService, private authService: AuthService,
    public loader: LoaderService, private router: Router) {
    this.loader.loader = false;
  }
  ngOnInit(): void {
    this.error = false;
    if (this.job.company[0] != null) {
      this.job.company = this.job.company[0];
    }
    // if (this.job.company)
  }

  imageError() {
    this.error = true;
  }

  urlChange(url: string) {
    try {
      let item = this.job;
      // url = url + "&job=" + (this.job.jobTitle as any).toString().replaceAll(" ", "-").toLowerCase() + "-in-company-" + this.job?.company['nameEn'].replaceAll(" ", "-").toLowerCase() + "-" + "at-location" + "-" + this.job.jobLocation.replaceAll(" ", "-").toLowerCase() + "-" + this.job?.country?.nameEn.replaceAll(" ", "-").toLowerCase();
      let readPath = "" + (this.job.jobTitle as any).toString().replaceAll(" ", "-").toLowerCase() + "-in-company-" + this.job?.company['nameEn'].replaceAll(" ", "-").toLowerCase() + "-" + "at-location" + "-" + this.job.jobLocation.replaceAll(" ", "-").toLowerCase() + "-" + this.job?.country?.nameEn.replaceAll(" ", "-").toLowerCase();
      this.router.navigate(['/job-detail'], { queryParams: { Id: this.job.id, job: readPath } });
      
      // return url;
    } catch (e) {
      console.log(e);
      // return url;
    }
  }

}
