import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EMPTY, NEVER, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginRouteResolverService  {
  private previousUrl!: string;

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    if (this.refresh(state.url)) {
      this.previousUrl = state.url;
      //console.log('EMPTY',EMPTY,'::: State URL:-', state.url);
      return EMPTY;
    }
    this.previousUrl = state.url;
    console.log('NEVER',NEVER);
    return NEVER;
  }

  private refresh(currentUrl: string): boolean {
    return !this.previousUrl || this.previousUrl === currentUrl;
  }
}
