<div class="twm-s-info-wrap">
    <h4 class="twm-s-title m-t0">
        Key Skills
        <button type="submit" class="btn site-button action" (click)="showEditToggle()" *ngIf="edit">
            <mat-icon *ngIf="!showEdit">add</mat-icon>
            <mat-icon *ngIf="showEdit">cancel</mat-icon>
        </button>
    </h4>
    <textarea *ngIf="showEdit" class="form-control mt-3" rows="7" [(ngModel)]="
    jobSeekerSkillModel.nameEn
        "></textarea>

    <span class="mandatory" *ngIf="
        jobSeekerSkillModel.nameEn ==
                '' && submitProfile
        ">This field is requied</span>
    <button *ngIf="showEdit" class="btn site-button mt-3" (click)="saveSkill()">
        Save
    </button>

    <div class="skills-cont d-flex" *ngIf="!showEdit">
        <div class="skills  mb-2" *ngFor="let item of jobSeekerSkillModelList">
            <span>{{item.nameEn}}</span>
            <mat-icon (click)="remove(item.id)" *ngIf="edit">delete</mat-icon>
        </div>
    </div>
    <!-- <p *ngIf="!showEdit">
        {{
        jobSeekerProfileSummary.summary
        }}
    </p> -->
</div>