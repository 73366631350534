<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Page Title -->
<div class="cont mt-5">
    <div class="d-table twm-home4-banner-section">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item mt-5">
                    <h2 class="h2">Privacy Policy</h2>
                    <!-- <ul>
                        <li>
                            <img src="assets/img/home-three/title-img-two.png" alt="Image">
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>About</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- New -->
<div class="new-area pb-70">
    <div class="container">
        <p>
            Privacy Policy for Mahad Jobs Management Services Private Limited
            Effective Date: 28/10/2023
            Welcome to Mahad Jobs (“Mahad Jobs Management Services Private Limited”), a mobile
            application ("App") designed to provide users with a platform to connect job seekers and
            employers. This Privacy Policy outlines how Mahad Jobs collects, uses, shares, and protects user
            information.
            1. Information Collection
            1.1 Information You Provide:
            Account Information: When you register or use our App, you may provide personal information such
            as your name, email address, phone number, and employment history.
            User Content: You may choose to submit additional information such as resumes, cover letters, and
            any other content related to job applications.
            1.2 Automatically Collected Information:
            Device Information: We may collect information about your device, including device model,
            operating system, unique device identifiers, and mobile network information.
            Usage Information: Our servers automatically record information about your usage of the App,
            including the features used, time spent on the App, and interactions with the App.
            2. Use of Information
            2.1 Personal Information:
            Account Management: We use your personal information to manage your account, authenticate
            users, and provide access to the features and functionalities of the App.
            Job Matching: We use your provided information to match job seekers with potential employment
            opportunities and assist employers in finding suitable candidates.
            2.2 Non-Personal Information:
            Improvement of Services: We use non-personal information to analyze user trends, improve the
            App's features, and personalize user experiences.
            3. Information Sharing
            3.1 Sharing with Employers:
            Your profile and submitted information may be shared with potential employers who have job
            openings matching your qualifications.
            3.2 Service Providers:
            We may share information with third-party service providers to assist with the operation of the App,
            such as hosting, data analysis, and customer support.
            3.3 Legal Compliance:
            We may disclose information in response to legal requests, comply with applicable laws, or protect
            the rights, property, and safety of Mahad Jobs and its users.
            4. Data Security
            We take reasonable measures to safeguard your information against unauthorized access,
            alteration, disclosure, or destruction.
            5. Third-Party Links
            The App may contain links to third-party websites or services. Mahad Jobs is not responsible for the
            privacy practices or content of these third-party sites.
            6. Children's Privacy
            Mahad Jobs does not knowingly collect personal information from children under the age of 13. If
            we become aware of such information being collected, we will take steps to delete it.
            7. Changes to this Privacy Policy
            We reserve the right to modify this Privacy Policy at any time. We will notify users of any material
            changes through the App or via other communication methods.
            8. Contact Information
            If you have any questions or concerns regarding this Privacy Policy or your information, please
            contact us at info@mahadjobs.com
            Please note, this is a template and should be customized to accurately reflect the policies and
            practices of Mahad Jobs. Additionally, it's essential to comply with local and international laws
            regarding data privacy and protection. It's advised to seek legal counsel to ensure the policy aligns
            with all relevant regulations.
        </p>

    </div>
</div>