import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { JobSeekerLanguageModel } from 'src/app/model/JobSeekerLangugage';
import { JobSeekerSkillModel } from 'src/app/model/JobSeekerSkill';
import { AuthService } from 'src/app/services/auth.service';
import { JobSeekerService } from 'src/app/services/jobSeeker';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-job-seeker-language',
  templateUrl: './job-seeker-language.component.html',
  styleUrls: ['./job-seeker-language.component.scss']
})
export class JobSeekerLanguageComponent implements OnInit {
  @Input() edit = true;
  @Input() seekerId: number;
  showEdit: boolean = false;
  submitProfile: boolean = false;
  constructor(private authService: AuthService, private masterService: MasterService,
    private tost: ToastrService, private jobSeekerSrv: JobSeekerService) {

  }
  ngOnInit(): void {
    this.jobSeekerLanguageModel.jobSeekerId = this.seekerId == 0 ? this.authService.getAuthStorage().jobseekerId : this.seekerId;
    this.getJobSeekerLanguageList();
  }

  jobSeekerLanguageModel: JobSeekerLanguageModel = {
    id: 0,
    jobSeekerId: 0,
    nameEn: '',
    nameAr: '',
    levelKnown: ''
  }
  jobSeekerLanguageModelList: Array<JobSeekerLanguageModel> = [];

  showEditToggle() {
    this.showEdit = !this.showEdit;
  }

  getJobSeekerLanguageList() {
    this.jobSeekerSrv.getLanguageBySeekerId(this.jobSeekerLanguageModel.jobSeekerId).subscribe((data) => {
      this.jobSeekerLanguageModelList = data;
    });
  }

  saveLanguage() {
    if (this.jobSeekerLanguageModel.nameEn.trim() == '' || this.jobSeekerLanguageModel.levelKnown.trim() == '') {
      this.submitProfile = true;
      return;
    }
    this.submitProfile = false;
    this.jobSeekerSrv.saveLanguage(this.jobSeekerLanguageModel).subscribe((data) => {
      this.getJobSeekerLanguageList();
      this.tost.success("Added Successfully");
      this.showEditToggle();
    });
  }

  assignLevel(level: string) {
    this.jobSeekerLanguageModel.levelKnown = level;
  }

  remove(id: number) {
    this.jobSeekerSrv.removeLanguage(id).subscribe((data) => {
      this.getJobSeekerLanguageList();
      this.tost.success("Deleted Successfully");
    });
  }
}
