import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage, } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { JobSeekerModel } from 'src/app/model/JobSeeker';
import { JobSeekerService } from 'src/app/services/jobSeeker';

@Component({
  selector: 'app-imagecropper',
  templateUrl: './imagecropper.component.html',
  styleUrls: ['./imagecropper.component.scss'],
  standalone: true,
  imports: [ImageCropperComponent, CommonModule]
})
export class ImagecrpComponent implements OnInit  {
  imageChangedEvent: Event | null = null;
  croppedImage: SafeUrl = '';
  isHidden = true
  readerValue;
  @Input() jobSeekerModel: JobSeekerModel;
  @Input() assignUrl: () => void;
  constructor(
    private jobSeekerSrv: JobSeekerService,
    private tost: ToastrService
  ) {
  }
  ngOnInit(): void {
    
  }
  triggerFileInputClick() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }

  fileChangeEvent(event: Event): void {
    this.isHidden = false;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    var reader = new FileReader();
    var self = this;
    reader.readAsDataURL(event.blob);
    reader.onloadend = function () {
      self.readerValue = reader.result;
      console.log(self.readerValue);
    };
  }

  imageLoaded(image: LoadedImage) {
    console.log(image)
  }

  handleUploadCroppedPic() {
    debugger
    let type: string = this.readerValue?.toString().split(";base64,")[0].split("/")[1];
    if (this.jobSeekerModel.profilePicturePath == "" || this.jobSeekerModel.profilePicturePath == undefined || this.jobSeekerModel.profilePicturePath == null) {
      this.jobSeekerModel.profilePicturePath = "new" + "." + type;
    } else {
      this.jobSeekerModel.profilePicturePath = this.jobSeekerModel.profilePicturePath.split(".")[0] + "." + type;
    }
    this.jobSeekerModel["base64"] = this.readerValue.toString().split(";base64,")[1];
    this.jobSeekerSrv.addPicProfileInfo(this.jobSeekerModel).subscribe((data) => {
      this.tost.success("Uploaded successfully");
      this.assignUrl()
      window.location.reload()
    });
  }
}
