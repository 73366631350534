<div class="page-wraper container">

    <!-- <app-navbar-style-one></app-navbar-style-one> -->
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content p-t50">
        <app-loading *ngIf="loader.loader"></app-loading>
        <!-- <app-login *ngIf="type == 'login'" [type]="type" [userType]="userType" (toggle)="toggle($event)"></app-login> -->

        <fieldset class="modal-content margin">
            <div>
                <div class="">
                    <h2 class="text-center" id="sign_up_popupLabel">Login</h2>
                    <hr />
                </div>

                <div class="modal-body">
                    <div class="twm-tabs-style-2 d-flex align-content-center">
                        <div class="tab-content" id="myTabContent">
                            <!--Signup Candidate Content-->
                            <div [ngClass]="'tab-pane fade show active'" id="sign-candidate">
                                <form [formGroup]="form" (ngSubmit)="login()">
                                    <div class="row">

                                        <div class="col-lg-6 col-md12">
                                            <label>Email:</label>
                                            <div class="form-group mb-3">
                                                <input type="text" class="form-control" placeholder="Email"
                                                    formControlName="userName">
                                                <span
                                                    *ngIf="(username?.dirty || username?.touched) && username?.errors?.['required']">
                                                    This Field is required!
                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12">
                                            <label>Password:</label>
                                            <div class="form-group mb-3">
                                            <input type="password" class="form-control" placeholder="Password"
                                                formControlName="password">
                                            <span
                                                *ngIf="(password?.dirty || password?.touched) && password?.errors?.['required']">
                                                This Field is required!
                                            </span>
                                        </div>
                                        </div>

                                        <!-- <div class="col-sm-12 col-lg-12">
                                            <div class="form-group mb-3">
                                                <div class=" form-check">
                                                    <input type="checkbox" class="form-check-input" id="agree1">
                                                    <label class="form-check-label" for="agree1">I agree to the <a
                                                            href="javascript:;">Terms and conditions</a></label>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-md-12">
                                            <button class="site-button">Login</button>&nbsp;&nbsp;&nbsp;&nbsp;

                                            Are you new User? Please <button class="btn btn-primary"
                                                data-bs-target="#sign_up_popup2" data-bs-toggle="modal"
                                                data-bs-dismiss="modal" [routerLink]="'/signup'"> Register </button>
                                            <br><span class="modal-f-title pointer mandatory" routerLink="/forget-pwd">Forget
                                                Password?</span>
                                        </div>
                                        <!-- <div class="col-md-12">
                                            <button class="site-button">Forget Password</button>&nbsp;&nbsp;&nbsp;&nbsp;

                                        </div> -->

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="modal-footer">
                    <span class="modal-f-title">Login or Sign up with</span>
                    <span class="ms-3 modal-f-title" routerLink="/forget-pwd">Forget Password?</span>
                    <ul class="twm-modal-social">
                        <li><a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="javascript" class="twitter-clr"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="javascript" class="google-clr"><i class="fab fa-google"></i></a></li>
                    </ul>
                </div> -->

            </div>

        </fieldset>
    </div>
</div>