import { Component, Input, OnInit } from '@angular/core';
import { CompanyModel } from 'src/app/model/Compnay';
import { JobsModel } from 'src/app/model/Jobs';
import { AuthService } from 'src/app/services/auth.service';
import { JobService } from 'src/app/services/job.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-widget',
  templateUrl: './company-widget.component.html',
  styleUrls: ['./company-widget.component.scss']
})
export class CompanyWidgetComponent implements OnInit {

  @Input() company: CompanyModel | null;
  baseUrl: string = environment.apiUrl + "ProfilePhoto/";
  error: boolean = false;

  constructor(public masterService: MasterService, private jobSrv: JobService, private authService: AuthService,
    public loader: LoaderService) {
    this.loader.loader = false;
  }
  ngOnInit(): void {
    console.log(this.company, "asklsaksakjjksa");
  }
  imageError() {
    this.error = true;
  }
  urlChange(url: string) {
    try {
      url = (url as any).toString().replaceAll(" ", "-").toLowerCase();
      return url;
    } catch (e) {
      return url;
    }
  }
}
