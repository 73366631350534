<div class="page-content" style="transform: none;">



    <!-- OUR BLOG START -->
    <div class="section-full p-tgood  p-b90 site-bg-white" style="transform: none;">


        <app-loading *ngIf="loader.loader"></app-loading>


        <div class="container" style="transform: none;">

            <h2 class="text-center"> Jobs </h2>
            <hr />
            <div class="row" style="transform: none;">

                <div class="col-lg-4 col-md-12 rightSidebar"
                    style="position: relative; overflow: visible; box-sizing: border-box; min-height: 1px;">


                    <button *ngIf="isMobile == true" (click)="toggleFilter()" class="site-button right-mobile"><span></span>{{!show?'  Filter  ':'    Apply   '}}<span></span></button>


                    <div *ngIf="show" class="theiaStickySidebar"
                        style="padding-top: 0px; padding-bottom: 1px; position: static; transform: none; top: 0px; left: 186.6px;">
                        <div class="side-bar">

                            <div class="sidebar-elements search-bx">

                                <form>

                                    <div class="form-group mb-4">
                                        <h4 class="section-head-small mb-4">Keyword</h4>
                                        <div class="input-group">
                                            <input type="text" [value]="this.jobFilter.jobTitle" class="form-control"
                                                placeholder="Job Title or Keyword" (keyup)="filterTitle($event)">
                                            <button class="btn" type="button"><i class="feather-search"></i></button>
                                        </div>
                                    </div>

                                    <div class="form-group mb-4">
                                        <h4 class="section-head-small mb-4">Sort By</h4>

                                        <ngx-select-dropdown [options]="['Most recent','Most relevant']"
                                            [instanceId]="'instance21'" (change)="sortFilter($event.value)"
                                            [multiple]="false"></ngx-select-dropdown>


                                    </div>

                                    <div class="form-group mb-4">
                                        <h4 class="section-head-small mb-4">Location</h4>
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Search location"
                                                (keyup)="filterLocation($event)">
                                            <!-- <button class="btn" type="button"><i class="feather-map-pin"></i></button> -->
                                        </div>
                                        <ul style="max-height: 200px; overflow-y: scroll;">
                                            <div *ngFor="let item of cityList; let i = index " class="mt-3">
                                                <li
                                                    *ngIf="locationFilter == '' || (item.nameEn| lowercase).includes(locationFilter)">
                                                    <div class=" form-check">
                                                        <input type="checkbox" class="form-check-input"
                                                            [id]="'exampleCheck3' + item.nameEn"
                                                            (change)="locationFilter2(item.id)"
                                                            [checked]="this.jobFilter.jobLocationId.includes(item.id)">
                                                        <label class="form-check-label"
                                                            for="exampleCheck1">{{item.nameEn}}</label>
                                                    </div>
                                                    <span class="twm-job-type-count"
                                                        *ngIf="locationFilter == '' || (item.nameEn| lowercase).includes(locationFilter)">{{item.count}}</span>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>

                                    <div class="twm-sidebar-ele-filter">
                                        <!-- <h4 class="section-head-small mb-4">Job Type</h4> -->
                                        <h4 class="section-head-small mb-4">Functional Area</h4>
                                        <div class="input-group mb-4">
                                            <input type="text" class="form-control" placeholder="Search..."
                                                (keyup)="filterArea($event)">
                                        </div>
                                        <ul style="max-height: 200px; overflow-y: scroll;">
                                            <div *ngFor="let item of functionalAreaList; let i = index ">
                                                <li
                                                    *ngIf="areaFilter == '' || (item.nameEn| lowercase).includes(areaFilter)">
                                                    <div class=" form-check">
                                                        <input type="checkbox" class="form-check-input"
                                                            [id]="'exampleCheck1' + item.nameEn"
                                                            (change)="functionalareaFilter(item.id)"
                                                            [checked]="this.jobFilter.functinalAreaId.includes(item.id)">
                                                        <label class="form-check-label"
                                                            for="exampleCheck1">{{item.nameEn}}</label>
                                                    </div>
                                                    <span class="twm-job-type-count"
                                                        *ngIf="areaFilter == '' || (item.nameEn| lowercase).includes(areaFilter)">{{item.count}}</span>
                                                </li>

                                            </div>

                                            <!-- <li>
                                            <div class=" form-check">
                                                <input type="checkbox" class="form-check-input" id="exampleCheck2">
                                                <label class="form-check-label" for="exampleCheck2">Full Time</label>
                                            </div>
                                            <span class="twm-job-type-count">07</span>
                                        </li> -->

                                        </ul>
                                    </div>

                                    <div class="twm-sidebar-ele-filter">
                                        <h4 class="section-head-small mb-4">Date Posts</h4>
                                        <ul>
                                            <!-- <li>
                                            <div class="form-check">
                                                <input type="radio" class="form-check-input" id="exampleradio1">
                                                <label class="form-check-label" for="exampleradio1">Last hour</label>
                                            </div>
                                        </li> -->
                                            <li>
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleradio2"
                                                        (change)="dateFilter('1')"
                                                        [checked]="this.jobFilter.postDate[0] == '1'">
                                                    <label class="form-check-label" for="exampleradio2">Last 1
                                                        days</label>
                                                </div>
                                            </li>

                                            <li>
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleradio3"
                                                        (change)="dateFilter('7')"
                                                        [checked]="this.jobFilter.postDate[0] == '7'">
                                                    <label class="form-check-label" for="exampleradio3">Last 7
                                                        days</label>
                                                </div>
                                            </li>

                                            <li>
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleradio4"
                                                        (change)="dateFilter('14')"
                                                        [checked]="this.jobFilter.postDate[0] == '14'">
                                                    <label class="form-check-label" for="exampleradio4">Last 14
                                                        days</label>
                                                </div>
                                            </li>

                                            <li>
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleradio5"
                                                        (change)="dateFilter('30')"
                                                        [checked]="this.jobFilter.postDate[0] == '30'">
                                                    <label class="form-check-label" for="exampleradio5">Last 30
                                                        days</label>
                                                </div>
                                            </li>

                                            <!-- <li>
                                            <div class="form-check">
                                                <input type="radio" class="form-check-input" id="exampleradio6">
                                                <label class="form-check-label" for="exampleradio6">All</label>
                                            </div> -->
                                            <!-- </li> -->

                                        </ul>
                                    </div>

                                    <div class="twm-sidebar-ele-filter">
                                        <h4 class="section-head-small mb-4">Type of employment</h4>
                                        <ul>
                                            <li *ngFor="let item of jobTypeList">
                                                <div class="form-check" (change)="typeOfEmp(item.id)">
                                                    <input type="checkbox" class="form-check-input" [id]="item.nameEn">
                                                    <label class="form-check-label"
                                                        for="Freelance1">{{item.nameEn}}</label>
                                                </div>
                                            </li>
                                            <!-- <li>
                                            <div class="form-check">
                                                <input type="radio" class="form-check-input" id="FullTime1">
                                                <label class="form-check-label" for="FullTime1">Full Time</label>
                                            </div>
                                        </li> -->
                                        </ul>
                                    </div>

                                </form>

                            </div>

                            <div class="widget tw-sidebar-tags-wrap" style="display: none;">
                                <h4 class="section-head-small mb-4">Tags</h4>

                                <div class="tagcloud">
                                    <a href="job-list.html">General</a>
                                    <a href="job-list.html">Jobs </a>
                                    <a href="job-list.html">Payment</a>
                                    <a href="job-list.html">Application </a>
                                    <a href="job-list.html">Work</a>
                                    <a href="job-list.html">Recruiting</a>
                                    <a href="job-list.html">Employer</a>
                                    <a href="job-list.html">Income</a>
                                    <a href="job-list.html">Tips</a>
                                </div>
                            </div>


                        </div>
                        <div *ngIf="!isMobile" class="twm-advertisment" style="background-image:url(images/add-bg.jpg);">
                            <div class="overlay"></div>
                            <h4 class="twm-title">Looking for change?</h4>
                            <p>Get Best Matched Jobs <br> Add Resume NOW!</p>
                            <a class="site-button white" (click)="resume()">Submit</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <!--Filter Short By-->
                    <div class="product-filter-wrap d-flex justify-content-between align-items-center">
                        <span class="woocommerce-result-count-left">Showing {{(jobPerPage * (currPage)) + 1}} from total
                            of
                            {{totalJobPerPage}} jobs</span>

                        <form class="woocommerce-ordering twm-filter-select" method="get">
                            <!-- <span class="woocommerce-result-count">Short By</span> -->
                            <!-- <div class="dropdown bootstrap-select wt-select-bar-2"><select class="wt-select-bar-2 selectpicker" data-live-search="true" data-bv-field="size">
                                <option>Most Recent</option>
                                <option>Freelance</option>
                                <option>Full Time</option>
                                <option>Internship</option>
                                <option>Part Time</option>
                                <option>Temporary</option>
                            </select><button type="button" tabindex="-1" class="btn dropdown-toggle btn-light" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-2" aria-haspopup="listbox" aria-expanded="false" title="Most Recent"><div class="filter-option"><div class="filter-option-inner"><div class="filter-option-inner-inner">Most Recent</div></div> </div></button><div class="dropdown-menu "><div class="bs-searchbox"><input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-2" aria-autocomplete="list"></div><div class="inner show" role="listbox" id="bs-select-2" tabindex="-1"><ul class="dropdown-menu inner show" role="presentation"></ul></div></div></div> -->
                            <!-- <div class="dropdown bootstrap-select wt-select-bar-2"><select class="wt-select-bar-2 selectpicker" data-live-search="true" data-bv-field="size">
                                <option>Show 10</option>
                                <option>Show 20</option>
                                <option>Show 30</option>
                                <option>Show 40</option>
                                <option>Show 50</option>
                                <option>Show 60</option>
                            </select><button type="button" tabindex="-1" class="btn dropdown-toggle btn-light" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-3" aria-haspopup="listbox" aria-expanded="false" title="Show 10"><div class="filter-option"><div class="filter-option-inner"><div class="filter-option-inner-inner">Show 10</div></div> </div></button><div class="dropdown-menu "><div class="bs-searchbox"><input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-3" aria-autocomplete="list"></div><div class="inner show" role="listbox" id="bs-select-3" tabindex="-1"><ul class="dropdown-menu inner show" role="presentation"></ul></div></div></div> -->
                        </form>

                    </div>

                    <div class="twm-jobs-list-wrap">
                        <ul>
                            <li
                                *ngFor="let item of jobList | paginate: { itemsPerPage: jobPerPage, currentPage: currPage + 1,totalItems:totalJobPerPage }">
                                <app-job-widget [job]="item"></app-job-widget>
                        </ul>
                        <div class="pagination-outer">
                            <div class="pagination-style1">
                                <pagination-controls (pageChange)="paginate($event)"></pagination-controls>

                            </div>
                        </div>
                    </div>

                    <!-- <div class="pagination-outer">
                        <div class="pagination-style1">
                            <ul class="clearfix">
                                <li class="prev"><a href="javascript:;"><span> <i class="fa fa-angle-left"></i> </span></a></li>
                                <li><a href="javascript:;">1</a></li>
                                <li class="active"><a href="javascript:;">2</a></li>
                                <li><a href="javascript:;">3</a></li>
                                <li><a class="javascript:;" href="javascript:;"><i class="fa fa-ellipsis-h"></i></a></li>
                                <li><a href="javascript:;">5</a></li>
                                <li class="next"><a href="javascript:;"><span> <i class="fa fa-angle-right"></i> </span></a></li>
                            </ul>
                        </div>
                    </div> -->

                </div>

            </div>
        </div>
    </div>
    <!-- OUR BLOG END -->



</div>