<div class="section-full  p-b90 site-bg-gray twm-job-categories-area2">
    <!-- TITLE START-->
    <div class="section-head center wt-small-separator-outer">
        <div class="wt-small-separator site-text-primary">
            <div>Jobs by top employers</div>
        </div>
        <!-- <h4 class="wt-title">Choose Your Desire Category</h4> -->
    </div>
    <!-- TITLE END-->

    <div class="container">
        <div class="twm-job-categories-section-2">
            <div class="job-categories-style1">
                <div class="row">
                    <!-- COLUMNS 1 -->
                    <!-- <div class="col-lg-3 col-md-6" *ngFor="let city of companyList">
                        <div class="job-categories-block-2 m-b30" > -->
                    <!-- <div class="twm-media">
                     <div class="flaticon-dashboard"></div>
                 </div>   
                                                 -->
                    <div class="col-12 col-lg-10 col-md-12">
                        <owl-carousel-o [options]="customOptions">
                            <ng-template carouselSlide *ngFor="let city of companyList">
                                <div class="twm-content ms-5 owl">
                                    <img width="133" height="84" [src]="baseUrl + city?.id.toString() +
                            '.png'" />
                                    <a [href]="
                                    '/view-ind-company?id=' + city.id
                                ">{{ city.nameEn }}</a>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
        <!-- </div>
        </div> -->
    </div>
</div>