<div class="page-wraper">

    <app-navbar-style-one></app-navbar-style-one>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">


        <!-- Candidate Detail V2 START -->
        <div class="section-full p-b10 bg-white bg"
            [ngStyle]="{'background-image':'url(' + baseUrlCover + company.userDefined1+')'}">
            <div class="twm-candi-self-wrap-2 overlay-wraper">
                <!-- <div class="overlay-main site-bg-primary opacity-01"></div> -->
                <div class="container">
                    <div class="twm-candi-self-info-2 info">
                        <div class="twm-candi-self-top">
                            <div class="twm-candi-fee">Company</div>
                            <div class="twm-media ">
                                <img *ngIf="!error" [src]="baseUrl + company.userDefined2" alt="#"
                                    (error)="imageError()" />
                                <div *ngIf="error">

                                    <i class="fas fa-business-time i"></i>
                                </div>
                            </div>
                            <h3 class="twm-job-title">{{company.nameEn}}</h3>
                            <!-- <div class="upload-btn-wrapper">
                                <div id="upload-image-grid1"></div>
                                <button class="site-button button-sm">Upload Photo</button>
                                <input type="file" name="myfile" id="1" accept=".png"
                                    (change)="handleUploadPic($event)">
                            </div> -->
                            <div class="twm-mid-content">

                                <h4 class="twm-job-title"><mat-icon>location_on</mat-icon>
                                    {{company.country?.nameEn}} - {{company.location?.nameEn}} </h4>

                                <!-- <p>Senior UI / UX Designer and Developer at Google INC</p>
                                <p class="twm-candidate-address"><i class="feather-map-pin"></i>United States</p> -->

                            </div>
                        </div>
                        <!-- <div class="twm-ep-detail-tags">
                            <button class="de-info twm-bg-sky"><i class="fa fa-plus"></i> Follow</button>
                        </div> -->
                        <div class="twm-candi-self-bottom">
                            <!-- <a [href]="url" class="site-button">Preview CV</a>
                            <div class="upload-btn-wrapper">
                                <div id="upload-image-grid"></div>
                                <a class="site-button secondry">Upload CV</a>
                                <input type="file" name="myfile" id="" accept=".pdf" (change)="handleUpload($event)">
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="true">
            <div class="section-content mt-3">
                <div class="row d-flex justify-content-center">
                    <div class="cabdidate-de-info">

                        <div class="twm-s-info-wrap mb-5">
                            <h4 class="twm-s-title m-t0">Overview
                            </h4>
                            <div class="row">
                                <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                                    <div class="d-flex">
                                        <mat-icon class="me-3 icon">calendar_month</mat-icon>
                                        <div class="row">
                                            <div class="col-12">
                                                <b>Establishment Year</b>
                                            </div>
                                            <div class="col-12">
                                                <p>{{company.creationDate.split("T")[0]}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                                    <div class="d-flex">
                                        <mat-icon class="me-3 icon">laptop_mac</mat-icon>
                                        <div class="row">
                                            <div class="col-12">
                                                <b>Sector</b>
                                            </div>
                                            <div class="col-12">
                                                <p>{{company.industry?.nameEn}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-xl-4 col-sm-6 col-12">
                                    <div class="d-flex">
                                        <mat-icon class="me-3 icon">work</mat-icon>
                                        <div class="row">
                                            <div class="col-12">
                                                <b>Posted Jobs</b>
                                            </div>
                                            <div class="col-12">
                                                <p>{{jobList.length}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="twm-s-info-wrap mb-5">
                            <h4 class="twm-s-title m-t0">Company Description
                            </h4>
                            <div class="row">
                                <div class="col-12">
                                    <p [innerHtml]="company.descriptions"></p>

                                </div>
                            </div>
                        </div>
                        <div class="twm-s-info-wrap mb-5">
                            <h4 class="twm-s-title m-t0">Active Jobs from {{company.nameEn}}
                            </h4>
                            <div class="twm-jobs-list-wrap">
                                <ul>
                                    <li *ngFor="let item of jobList | paginate: { itemsPerPage: 7, currentPage: p }">
                                        <app-job-widget [job]="item"></app-job-widget>
                                </ul>
                                <div class="pagination-outer">
                                    <div class="pagination-style1">
                                        <pagination-controls (pageChange)="paginate($event)"></pagination-controls>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>