import { Component, OnInit } from '@angular/core';
import { CompanyModel } from 'src/app/model/Compnay';
import { JobsModel } from 'src/app/model/Jobs';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { JobService } from 'src/app/services/job.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-ind-company',
  templateUrl: './view-ind-company.component.html',
  styleUrls: ['./view-ind-company.component.scss']
})
export class ViewIndCompanyComponent implements OnInit {
  urlComp: string = "";
  p: any;
  baseUrl: string = environment.apiUrl + "ProfilePhoto/";
  baseUrlCover: string = environment.apiUrl + "ProfilePhoto/";
  error: boolean = false;
  constructor(masterService: MasterService, private companySrv: CompanyService,
    private authService: AuthService, private jobSrv: JobService,
    public loader: LoaderService) {
    // this.masterService = masterService;
    this.loader.loader = true;
  }
  ngOnInit(): void {
    var urlString = window.location.href;
    var url = new URL(urlString);
    var id = url.searchParams.get("id");
    if (id != null && id != undefined && id != "") {
      this.company.id = parseInt(id);
      this.assignUrl();
      this.getCompany();
    }
  }

  company: CompanyModel = {
    id: 0,
    creationUserName: '',
    creationDate: '',
    lastUpdateUserName: '',
    lastUpdateDate: '',
    deletedOn: '',
    deletedBy: '',
    nameEn: '',
    nameAr: '',
    descriptions: '',
    emailId: '',
    locationId: 0,
    countryId: 0,
    phoneNo: '',
    country: "",
    industryId: 0,
    crnumber: '',
    location: undefined,
    industry: undefined,
    establishmentYear: 0,
    address: '',
    userDefined1: '',
    userDefined2: '',
    userDefined3: '',
    userDefined4: ''
  }
  companyList: Array<CompanyModel> = [];

  assignUrl() {
    this.urlComp = environment.apiUrl + 'ProfilePhoto/' + this.company.id + '.png';
  }

  getCompany() {
    this.companySrv.getByIdWithDetail(this.company.id).subscribe((company) => {
      this.company = company[0];
      this.error = false;
      this.getJobList();
    })
  }

  job: JobsModel = {
    id: 0,
    companyId: this.company.id,
    jobTitle: "",
    experieance: "",
    jobTypeId: 0,
    jobLocationId: 0,
    nationalityId: 0,
    applicant: 0,
    gender: "",
    totalNoOfVacancy: 0,
    functinalAreaId: 0,
    jobDescription: "",
    jobViews: 0,
    creationDate: "",
    jobType: null,
    jobLocation: null,
    functinalArea: null,
    nationality: null,
    company: null,
    userDefined1: "",
    userDefined2: "",
    userDefined3: "",
    userDefined4: "",
    countryId: 0,
    country: "",
    keySkill: ''
  }
  jobList: Array<JobsModel> = [];

  getJobList() {
    this.loader.loader = true;
    this.jobSrv.getByCompanyId(this.company.id).subscribe((data) => {
      data = data.reverse()
      this.jobList = data;
      this.loader.loader = false;
    });
  }

  paginate(p: any) {
    this.p = p;
  }
  imageError() {
    this.error = true;
  }

}
