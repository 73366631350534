<div class="page-wraper" style="margin-top: 91px">
    <app-navbar-style-one></app-navbar-style-one>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">
        <app-loading *ngIf="loader.loader"></app-loading>

        <div class="twm-home4-banner-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h2>Search your desire Jobs</h2>
                        <div class="row text-center">
                            <!--Title-->
                            <div class="form-group col-xl-8 col-lg-8 col-md-8">
                                <div class="twm-single-iput">
                                    <input (keyup)="filter($event)" type="text" required="" class="form-control w-100"
                                        placeholder="search by keyword,Job title, designation and company name ect ... " />
                                </div>
                            </div>

                            <!--Find job btn-->
                            <div class="form-group col-xl-4 col-lg-4 col-md-4">
                                <a [href]="'/view-job?jobTitle=' + search">
                                    <button type="button" class="site-button w-100">
                                        Search
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="card-container d-none">
                            <div class="card float-md-end">
                                <div class="card-body">
                                    <h4>Let Employers Find You!</h4>
                                    <p>
                                        Get discovered by 10,000+ Employers &
                                        apply to jobs in a single click
                                    </p>
                                    <button class="btn btn-warning w-100">
                                        Register for free
                                    </button>
                                </div>

                                <div class="card-footer">
                                    <p>
                                        Get notified about jobs matching your
                                        criteria
                                    </p>
                                    <a href="#123"> Create Job alert</a>
                                </div>
                            </div>
                            <br clear="all" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- JOBS CATEGORIES SECTION START -->
        <div class="section-full site-bg-gray twm-job-categories-area2 mb-0 container">
            <div class="job-by-category-location">
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <div class="row wrapper d-flex justify-content-center h-100">
                            <div class="wt-small-separator site-text-primary">
                                <div>Jobs by Categories</div>
                            </div>
                            <!-- <h4 class="wt-title">Choose Your Desire Category</h4> -->
                            <!-- [href]="'/view-job?funcId=' + city.id + '&category='+city.nameEn.replaceAll(' ','-').toLowerCase()" -->
                            <div class="indItem" *ngFor="let city of courseList.slice(0,6)">
                                <a [routerLink]="['/view-job']" [queryParams]="{ funcId: city.id
                                    ,category:city.nameEn.replaceAll(' ','-').toLowerCase()
                                 }">{{
                                    city.nameEn
                                    }}</a>
                                <div class="twm-jobs-available num-job">
                                    {{ city.count }} Jobs
                                </div>
                            </div>
                            <div class="more" routerLink="/view-job">
                                <div class="more-text">More</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 wrapper-new">
                        <div class="row wrapper d-flex justify-content-center h-100">
                            <div class="wt-small-separator site-text-primary">
                                <div>Jobs by Location</div>
                            </div>
                            <!-- <h4 class="wt-title">Choose Your Desire Category</h4> -->
                            <div class="indItem loc" *ngFor="let city of cityList">
                                <!-- [href]="'/view-job?locId=' + city.id  + '&location='+city.nameEn.replaceAll(' ','-').toLowerCase()" -->
                                <a [routerLink]="['/view-job']"
                                    [queryParams]="{ locId: city.id,location: city.nameEn.replaceAll(' ','-').toLowerCase()}">{{
                                    city.nameEn
                                    }}</a>
                                <!--<div class="twm-jobs-available num-job">
                                    {{ city.count }} Jobs
                                </div> -->
                            </div>
                            <div class="more" routerLink="/view-job">
                                <div class="more-text">More</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="job-by-employer-container">
                <div class="row">
                    <div class="col-lg-12 col-12">
                        <div class="row wrapper d-flex ">
                            <div class="wt-small-separator site-text-primary">
                                <div>Jobs by Top Employer</div>
                            </div>
                            <div class="image-container-emp">
                                <!-- [href]="'/view-ind-company?id=' + city.id + '&company='+city.nameEn.replaceAll(' ','-').toLowerCase()" -->
                                <a class="" *ngFor="let city of companyList; index as i"
                                    [routerLink]="['/view-ind-company']"
                                    [queryParams]="{ id: city.id,company: city.nameEn.replaceAll(' ','-').toLowerCase()}">
                                    <img (error)="imageErrorComp(i)" class="emp-job" *ngIf="city.error != true"
                                        [src]="baseUrl +city?.userDefined2 " />
                                    <i *ngIf="city.error == true" class="far fa-building i"></i>
                                </a>
                            </div>
                            <div class="more-emp" routerLink="/view-company">
                                <div class="more-text">More</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-full p-t80 site-bg-white twm-how-it-work-area2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <!-- TITLE START-->
                        <div class="section-head left wt-small-separator-outer">
                            <div class="wt-small-separator site-text-primary">
                                <div>How It Works</div>
                            </div>
                            <h2 class="wt-title">
                                Follow our steps we will help you.
                            </h2>
                        </div>
                        <ul class="description-list">
                            <li>
                                <i class="feather-check"></i>
                                Trusted &amp; Quality Job
                            </li>
                            <li>
                                <i class="feather-check"></i>
                                International Job
                            </li>
                            <li>
                                <i class="feather-check"></i>
                                No Extra Charge
                            </li>
                            <li>
                                <i class="feather-check"></i>
                                Top Companies
                            </li>
                        </ul>
                        <!-- TITLE END-->
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="twm-w-process-steps-2-wrap">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6">
                                    <div class="twm-w-process-steps-2">
                                        <div class="twm-w-pro-top bg-clr-sky-light bg-sky-light-shadow">
                                            <span class="twm-large-number text-clr-sky">01</span>
                                            <div class="twm-media">
                                                <span>
                                                    <!-- <img src="images/work-process/icon1.png" alt="icon1"> -->
                                                    <mat-icon class="twm-media-icon">badge</mat-icon>
                                                </span>
                                            </div>
                                            <h4 class="twm-title">
                                                Register<br />Your Account
                                            </h4>
                                            <p>
                                                Quick and easy account registration
                                                for seamless access to job opportunities
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-6">
                                    <div class="twm-w-process-steps-2">
                                        <div class="twm-w-pro-top bg-clr-yellow-light bg-yellow-light-shadow">
                                            <span class="twm-large-number text-clr-yellow">02</span>
                                            <div class="twm-media">
                                                <span>
                                                    <!-- <img src="images/work-process/icon4.png" alt="icon1"> -->
                                                    <mat-icon class="twm-media-icon">search</mat-icon>
                                                </span>
                                            </div>
                                            <h4 class="twm-title">
                                                Search <br />
                                                Your Job
                                            </h4>
                                            <p>
                                                Discover your ideal job with our user-friendly
                                                and comprehensive search functionality.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-6">
                                    <div class="twm-w-process-steps-2">
                                        <div class="twm-w-pro-top bg-clr-pink-light bg-pink-light-shadow">
                                            <span class="twm-large-number text-clr-pink">03</span>
                                            <div class="twm-media">
                                                <span>
                                                    <!-- <img src="images/work-process/icon3.png" alt="icon1"> -->
                                                    <mat-icon class="twm-media-icon">view_in_ar</mat-icon>
                                                </span>
                                            </div>
                                            <h4 class="twm-title">
                                                Apply <br />For Dream Job
                                            </h4>
                                            <p>
                                                Take the next step towards your dream job
                                                by submitting a seamless application process.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-6">
                                    <div class="twm-w-process-steps-2">
                                        <div class="twm-w-pro-top bg-clr-green-light bg-clr-light-shadow">
                                            <span class="twm-large-number text-clr-green">04</span>
                                            <div class="twm-media">
                                                <span>
                                                    <!-- <img src="images/work-process/icon3.png" alt="icon1"> -->
                                                    <mat-icon class="twm-media-icon">description</mat-icon>
                                                </span>
                                            </div>
                                            <h4 class="twm-title">
                                                Upload <br />Your Resume
                                            </h4>
                                            <p>
                                                Showcase your qualifications and experience by
                                                effortlessly uploading your resume for consideration.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="twm-how-it-work-section"></div>
            </div>
        </div>
        <!-- HOW IT WORK SECTION END -->

        <!-- EXPLORE NEW LIFE START -->
        <div class="section-full p-t50 p-b50 site-bg-white twm-explore-area2">
            <div class="container">
                <div class="section-content">
                    <div class="twm-explore-content-2">
                        <div class="row">
                            <div class="col-lg-8 col-md-12">
                                <div class="twm-explore-content-outer2">
                                    <div class="twm-explore-top-section">
                                        <div class="twm-title-small">
                                            For Employee
                                        </div>
                                        <div class="twm-title-large">
                                            <h2>
                                                We help you connect with the
                                                organizer
                                            </h2>
                                            <p>
                                                Get paid easily and security.
                                                Use our resources to become
                                                independent and showcase your
                                                professional skills.
                                            </p>
                                        </div>
                                        <!-- <div class="twm-read-more">
                                            <a href="about-1.html" class="site-button">Read More</a>
                                        </div> -->
                                    </div>

                                    <div class="twm-explore-bottom-section">
                                        <div class="row">
                                            <!--block 1-->
                                            <div class="col-lg-4 col-md-6">
                                                <div class="counter-outer-two">
                                                    <div class="icon-content">
                                                        <div class="tw-count-number text-clr-yellow-2">
                                                            <span class="counter">500</span>+
                                                        </div>
                                                        <p class="icon-content-info">
                                                            daily active users
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--block 2-->
                                            <div class="col-lg-4 col-md-6">
                                                <div class="counter-outer-two">
                                                    <div class="icon-content">
                                                        <div class="tw-count-number text-clr-green">
                                                            <span class="counter">500</span>+
                                                        </div>
                                                        <p class="icon-content-info">
                                                            Open job positions
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <!--block 3-->
                                            <div class="col-lg-4 col-md-12">
                                                <div class="counter-outer-two">
                                                    <div class="icon-content">
                                                        <div class="tw-count-number text-clr-pink">
                                                            <span class="counter">1</span>K+
                                                        </div>
                                                        <p class="icon-content-info">
                                                            stories shared
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="twm-explore-media-wrap2">
                                    <div class="twm-media">
                                        <img src="https://thewebmax.org/jobzilla/images/boy-large.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- EXPLORE NEW LIFE END -->

        <!-- JOB POST START -->
        <div class="section-full p-t50 p-b50 site-bg-gray twm-bg-ring-wrap2">
            <div class="twm-bg-ring-right"></div>
            <div class="twm-bg-ring-left"></div>
            <div class="container">
                <div class="wt-separator-two-part">
                    <div class="row wt-separator-two-part-row">
                        <div class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-left">
                            <!-- TITLE START-->
                            <div class="section-head left wt-small-separator-outer">
                                <div class="wt-small-separator site-text-primary">
                                    <div>All Jobs Post</div>
                                </div>
                                <h2 class="wt-title">
                                    Find Your Career You Deserve it
                                </h2>
                            </div>
                            <!-- TITLE END-->
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right text-right">
                            <a routerLink="/view-job" class="site-button">Browse All Jobs</a>
                        </div>
                    </div>
                </div>

                <div class="section-content">
                    <div class="twm-jobs-grid-wrap">
                        <div class="row">
                            <div class="col-lg-4 col-md-6" *ngFor="let item of jobListShow; index as i">
                                <div class="twm-jobs-grid-style1 m-b30">
                                    <div class="twm-media flex">
                                        <img *ngIf="!item.error" (error)="imageError(i)" [src]="
                                                baseUrl +
                                                item?.company[0].userDefined2
                                            " alt="#" />
                                        <i *ngIf="item.error" class="fas fa-business-time i"></i>
                                        <!-- <p class="twm-job-address">{{item.company}}</p> -->
                                    </div>
                                    <span class="twm-job-post-duration">{{
                                        masterService.getDateDiff(
                                        item.creationDate
                                        )
                                        }}</span>
                                    <div class="twm-jobs-category green">
                                        <span class="twm-bg-green">{{
                                            item.jobType
                                            }}</span>
                                    </div>
                                    <div class="twm-mid-content">
                                        <h4>{{ item.company[0]["nameEn"] }}</h4>
                                        <a routerLink="/job-detail" class="twm-job-title">
                                            <h4>{{ item.jobTitle }}</h4>
                                        </a>
                                        <p class="twm-job-address">
                                            {{ item.jobLocation }}, {{item.country.nameEn}}
                                        </p>
                                        <!-- <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a> -->
                                    </div>
                                    <div class="twm-right-content">
                                        <div class="twm-jobs-amount">
                                            {{ item.userDefined1 }} - {{ item.userDefined3 }}
                                            <span>/ {{ item.userDefined2 }}</span>
                                        </div>
                                        <a (click)="urlChange('/job-detail?Id=' + item.id, item)"
                                            class="twm-jobs-browse site-text-primary">Browse Job</a>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right text-right">
                                <a  routerLink="/view-job" class=" site-button">Browse All Jobs</a>
                            </div> -->

                            <!-- <div class="col-lg-4 col-md-6">
                                <div class="twm-jobs-grid-style1 m-b30">
                                    <div class="twm-media">
                                        <img src="images/jobs-company/pic2.jpg" alt="#">
                                    </div>
                                    <span class="twm-job-post-duration">15 days ago</span>
                                    <div class="twm-jobs-category green"><span class="twm-bg-brown">Intership</span></div>
                                    <div class="twm-mid-content">
                                        <a routerLink="/job-detail" class="twm-job-title">
                                            <h4>Senior Rolling Stock Technician</h4>
                                        </a>
                                        <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                        <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                    </div>
                                    <div class="twm-right-content">
                                        <div class="twm-jobs-amount">$7 <span>/ Hour</span></div>
                                        <a routerLink="/job-detail" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="twm-jobs-grid-style1  m-b30">
                                    <div class="twm-media">
                                        <img src="images/jobs-company/pic3.jpg" alt="#">
                                    </div>
                                    <span class="twm-job-post-duration">6 Month ago</span>
                                    <div class="twm-jobs-category green"><span class="twm-bg-purple">Fulltime</span></div>
                                    <div class="twm-mid-content">
                                        <a routerLink="/job-detail" class="twm-job-title">
                                            <h4 class="twm-job-title">IT Department Manager</h4>
                                        </a>
                                        <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                        <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                    </div>
                                    <div class="twm-right-content">

                                        <div class="twm-jobs-amount">$2000 - $2500 <span>/ Month</span></div>
                                        <a routerLink="/job-detail" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- JOB POST END -->

        <!-- BLOG POST START -->
        <div class="section-full p-t50 p-b50">
            <div class="twm-bg-ring-right"></div>
            <div class="twm-bg-ring-left"></div>
            <div class="container">
                <div class="wt-separator-two-part">
                    <div class="row wt-separator-two-part-row">
                        <div class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-left">
                            <!-- TITLE START-->
                            <div class="section-head left wt-small-separator-outer">
                                <div class="wt-small-separator site-text-primary">
                                    <div>All Blog Post</div>
                                </div>
                                <h2 class="wt-title">
                                    Discover Our Latest Blogs
                                </h2>
                            </div>
                            <!-- TITLE END-->
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right text-right">
                            <a routerLink="/blog" class="site-button">Browse All Blogs</a>
                        </div>
                    </div>
                </div>

                <div class="section-content">
                    <div class="twm-jobs-grid-wrap">
                        <div class="row">
                            <div *ngFor="let blog of blogList.slice(0,6)" class="col-md-4 mb-5">
                                <div class="shadow d-flex flex-column"
                                    style="height: 100%; display: flex; flex-direction: column; justify-content: space-between; border-radius: 9px; overflow: hidden;">
                                    <div style="height: 240px; object-fit: cover; border-radius: 9px;">
                                        <img class="blog-img" [src]="baseBlogUrl + blog.userDefined1" alt="Card Image">
                                    </div>
                                    <div class="px-3 py-2">
                                        <p class="advice-color mb-1">Informational blog posts</p>
                                        <h5 class="line-clamp-2">{{ blog?.headingEn }}</h5>
                                        <p class="card-subtitle mb-2 text-muted">BY <span style="color:#1969d9">MAHAD
                                                JOBS</span> |
                                            {{currentYear}}</p>
                                        <p class="card-text line-clamp-3" [innerHtml]="blog?.descriptions"></p>
                                    </div>
                                    <div class="px-3 py-2">
                                        <button type="button" (click)="handelCardClick(blog?.id)"
                                            class="site-button w-100 rounded-3 ">
                                            View Full Article
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-4 col-md-6" *ngFor="let item of jobListShow; index as i">
                                <div class="twm-jobs-grid-style1 m-b30">
                                    <div class="twm-media flex">
                                        <img *ngIf="!item.error" (error)="imageError(i)" [src]="
                                        baseUrl +
                                        item?.company[0].userDefined2
                                    " alt="#" />
                                        <i *ngIf="item.error" class="fas fa-business-time i"></i>
                                    </div>
                                    <span class="twm-job-post-duration">{{
                                        masterService.getDateDiff(
                                        item.creationDate
                                        )
                                        }}</span>
                                    <div class="twm-jobs-category green">
                                        <span class="twm-bg-green">{{
                                            item.jobType
                                            }}</span>
                                    </div>
                                    <div class="twm-mid-content">
                                        <h4>{{ item.company[0]["nameEn"] }}</h4>
                                        <a routerLink="/job-detail" class="twm-job-title">
                                            <h4>{{ item.jobTitle }}</h4>
                                        </a>
                                        <p class="twm-job-address">
                                            {{ item.jobLocation }}, {{item.country.nameEn}}
                                        </p>
                                    </div>
                                    <div class="twm-right-content">
                                        <div class="twm-jobs-amount">
                                            {{ item.userDefined1 }} - {{ item.userDefined3 }}
                                            <span>/ {{ item.userDefined2 }}</span>
                                        </div>
                                        <a [href]="urlChange('/job-detail?Id=' + item.id, item)"
                                            class="twm-jobs-browse site-text-primary">Browse Job</a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- BLOG POST END -->


            <!-- FOR EMPLOYEE START -->
            <div class="section-full p-t50 p-b50 twm-for-employee-area site-bg-white">
                <div class="container">
                    <div class="section-content">
                        <div class="row">
                            <div class="col-lg-5 col-md-12">
                                <!-- TITLE START-->
                                <div class="section-head left wt-small-separator-outer">
                                    <div class="wt-small-separator site-text-primary">
                                        <div>MahadJobs - Infinite Opportunities</div>
                                    </div>
                                    <h2 class="wt-title">
                                        Success in Cross-Functional Collaboration.
                                    </h2>
                                </div>
                                <ul class="description-list">
                                    <li>
                                        <i class="feather-check"></i>
                                        Accomplished professional
                                    </li>
                                    <li>
                                        <i class="feather-check"></i>
                                        Strong track record
                                    </li>
                                    <li>
                                        <i class="feather-check"></i>
                                        Demonstrated ability
                                    </li>
                                    <li>
                                        <i class="feather-check"></i>
                                        Results-driven team player
                                    </li>
                                </ul>
                                <!-- TITLE END-->
                            </div>
                            <!-- <div class="col-lg-5 col-md-12">
                            <div class="twm-explore-media-wrap">
                                <div class="twm-media">
                                    <img src="images/boy-large.png" alt="" />
                                </div>
                            </div>
                        </div> -->

                            <div class="col-lg-7 col-md-12">
                                <div class="twm-explore-content-outer-3">
                                    <div class="twm-explore-content-3">
                                        <div class="twm-title-small">
                                            Explore New Life
                                        </div>
                                        <div class="twm-title-large">
                                            <h2>
                                                Adaptable Professional with Proven Success
                                            </h2>
                                            <p>
                                                In a rapidly changing business landscape,
                                                adaptability and collaboration are key to
                                                achieving success. As an accomplished professional,
                                                I have consistently demonstrated my ability to thrive
                                                in dynamic environments by leveraging my strong
                                                interpersonal skills and a diverse background.
                                            </p>
                                        </div>
                                        <div class="twm-upload-file">
                                            <button type="button" class="site-button" (click)="resume()">
                                                Upload Your Resume
                                                <i class="feather-upload"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="twm-l-line-1"></div>
                                    <div class="twm-l-line-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- FOR EMPLOYEE END -->
        </div>
        <!-- CONTENT END -->

        <!-- FOOTER START -->

        <!-- FOOTER END -->

        <!-- BUTTON TOP START -->
        <button class="scroltop" style="display: none">
            <span class="fa fa-angle-up relative" id="btn-vibrate"></span>
        </button>

        <!--Model Popup Section Start-->
        <!--Signup popup -->
        <div class="modal fade twm-sign-up" id="sign_up_popup" aria-hidden="true" aria-labelledby="sign_up_popupLabel"
            tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content">
                    <form>
                        <div class="modal-header">
                            <h2 class="modal-title" id="sign_up_popupLabel">
                                Sign Up
                            </h2>
                            <p>
                                Sign Up and get access to all the features of
                                Jobzilla
                            </p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div class="modal-body">
                            <div class="twm-tabs-style-2">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <!--Signup Candidate-->
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" data-bs-toggle="tab"
                                            data-bs-target="#sign-candidate" type="button">
                                            <i class="fas fa-user-tie"></i>Candidate
                                        </button>
                                    </li>
                                    <!--Signup Employer-->
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#sign-Employer"
                                            type="button">
                                            <i class="fas fa-building"></i>Employer
                                        </button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <!--Signup Candidate Content-->
                                    <div class="tab-pane fade show active" id="sign-candidate">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="username" type="text" required="" class="form-control"
                                                        placeholder="Usearname*" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="email" type="text" class="form-control" required=""
                                                        placeholder="Password*" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="phone" type="text" class="form-control" required=""
                                                        placeholder="Email*" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="phone" type="text" class="form-control" required=""
                                                        placeholder="Phone*" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" id="agree1" />
                                                        <label class="form-check-label" for="agree1">I agree to the
                                                            <a href="javascript:;">Terms and
                                                                conditions</a></label>
                                                        <p>
                                                            Already registered?
                                                            <button class="twm-backto-login"
                                                                data-bs-target="#sign_up_popup2" data-bs-toggle="modal"
                                                                data-bs-dismiss="modal">
                                                                Log in here
                                                            </button>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="submit" class="site-button">
                                                    Sign Up
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Signup Employer Content-->
                                    <div class="tab-pane fade" id="sign-Employer">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="username" type="text" required="" class="form-control"
                                                        placeholder="Usearname*" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="email" type="text" class="form-control" required=""
                                                        placeholder="Password*" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="phone" type="text" class="form-control" required=""
                                                        placeholder="Email*" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="phone" type="text" class="form-control" required=""
                                                        placeholder="Phone*" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" id="agree2" />
                                                        <label class="form-check-label" for="agree2">I agree to the
                                                            <a href="javascript:;">Terms and
                                                                conditions</a></label>
                                                        <p>
                                                            Already registered?
                                                            <button class="twm-backto-login"
                                                                data-bs-target="#sign_up_popup2" data-bs-toggle="modal"
                                                                data-bs-dismiss="modal">
                                                                Log in here
                                                            </button>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="submit" class="site-button">
                                                    Sign Up
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <span class="modal-f-title">Login or Sign up with</span>
                            <ul class="twm-modal-social">
                                <li>
                                    <a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="javascript" class="twitter-clr"><i class="fab fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in"></i></a>
                                </li>
                                <li>
                                    <a href="javascript" class="google-clr"><i class="fab fa-google"></i></a>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--Login popup -->
        <div class="modal fade twm-sign-up" id="sign_up_popup2" aria-hidden="true" aria-labelledby="sign_up_popupLabel2"
            tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form>
                        <div class="modal-header">
                            <h2 class="modal-title" id="sign_up_popupLabel2">
                                Login
                            </h2>
                            <p>
                                Login and get access to all the features of Jobzilla
                            </p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="twm-tabs-style-2">
                                <ul class="nav nav-tabs" id="myTab2" role="tablist">
                                    <!--Login Candidate-->
                                    <li class="nav-item">
                                        <button class="nav-link active" data-bs-toggle="tab"
                                            data-bs-target="#login-candidate" type="button">
                                            <i class="fas fa-user-tie"></i>Candidate
                                        </button>
                                    </li>
                                    <!--Login Employer-->
                                    <li class="nav-item">
                                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#login-Employer"
                                            type="button">
                                            <i class="fas fa-building"></i>Employer
                                        </button>
                                    </li>
                                </ul>

                                <div class="tab-content" id="myTab2Content">
                                    <!--Login Candidate Content-->
                                    <div class="tab-pane fade show active" id="login-candidate">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="username" type="text" required="" class="form-control"
                                                        placeholder="Usearname*" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="email" type="text" class="form-control" required=""
                                                        placeholder="Password*" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input"
                                                            id="Password3" />
                                                        <label class="form-check-label rem-forgot"
                                                            for="Password3">Remember
                                                            me
                                                            <a href="javascript:;">Forgot Password</a></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="submit" class="site-button">
                                                    Log in
                                                </button>
                                                <div class="mt-3 mb-3">
                                                    Don't have an account ?
                                                    <button class="twm-backto-login" data-bs-target="#sign_up_popup"
                                                        data-bs-toggle="modal" data-bs-dismiss="modal">
                                                        Sign Up
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Login Employer Content-->
                                    <div class="tab-pane fade" id="login-Employer">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="username" type="text" required="" class="form-control"
                                                        placeholder="Usearname*" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <input name="email" type="text" class="form-control" required=""
                                                        placeholder="Password*" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group mb-3">
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input"
                                                            id="Password4" />
                                                        <label class="form-check-label rem-forgot"
                                                            for="Password4">Remember
                                                            me
                                                            <a href="javascript:;">Forgot Password</a></label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <button type="submit" class="site-button">
                                                    Log in
                                                </button>
                                                <div class="mt-3 mb-3">
                                                    Don't have an account ?
                                                    <button class="twm-backto-login" data-bs-target="#sign_up_popup"
                                                        data-bs-toggle="modal" data-bs-dismiss="modal">
                                                        Sign Up
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <span class="modal-f-title">Login or Sign up with</span>
                            <ul class="twm-modal-social">
                                <li>
                                    <a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="javascript" class="twitter-clr"><i class="fab fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in"></i></a>
                                </li>
                                <li>
                                    <a href="javascript" class="google-clr"><i class="fab fa-google"></i></a>
                                </li>
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--Model Popup Section End-->
    </div>