import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { LoginComponent } from './components/pages/login/login.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { AboutComponent } from './components/pages/about/about.component';
import { CandidatesDetailsComponent } from './components/pages/candidates-details/candidates-details.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { AuthService } from './services/auth.service';
import { LoginRouteResolverService } from './services/login-route-resolver.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './services/auth.guard';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './components/common/side-nav/side-nav.component';
import { EmployerTopNavComponent } from './components/common/employer-top-nav/employer-top-nav.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ViewJobComponent } from './components/pages/view-job/view-job.component';
import { JobDetailComponent } from './components/pages/job-detail/job-detail.component';
import { UserProfileComponent } from './components/pages/user-profile/user-profile.component';
import { MatTabsModule } from '@angular/material/tabs';
import { LoadingComponent } from './components/common/loading/loading.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SignupComponent } from './components/pages/signup/signup.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { JobWidgetComponent } from './components/common/job-widget/job-widget.component';
import { ViewCompanyComponent } from './components/pages/view-company/view-company.component';
import { CompanyWidgetComponent } from './components/common/company-widget/company-widget.component';
import { ViewIndCompanyComponent } from './components/pages/view-ind-company/view-ind-company.component';
import { JobByEmployerComponent } from './components/common/job-by-employer/job-by-employer.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { JobSeekerKeyskillComponent } from './components/common/job-seeker-keyskill/job-seeker-keyskill.component';
import { JobSeekerSkillcertificationComponent } from './components/common/job-seeker-skillcertification/job-seeker-skillcertification.component';
import { JobSeekerLanguageComponent } from './components/common/job-seeker-language/job-seeker-language.component';
import { FogetPwdComponent } from './components/pages/foget-pwd/foget-pwd.component';
import { ResetPwdComponent } from './components/pages/reset-pwd/reset-pwd.component';
import { ChangePwdComponent } from './components/pages/change-pwd/change-pwd.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { JobQuestionComponent } from './components/common/job-question/job-question.component';
import { SafePipe, SafePipeModule } from 'safe-pipe';
import { JobQuestionAnswerComponent } from './components/common/job-question-answer/job-question-answer.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { JobSeekerProfDetailComponent } from './components/common/job-seeker-prof-detail/job-seeker-prof-detail.component'

import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SideNavNotiComponent } from './components/common/side-nav-noti/side-nav-noti.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ImagecrpComponent } from './components/common/imagecropper/imagecropper.component';
import { ListFromCountPipe } from './list-from-count.pipe';
import { BottombarComponent } from './components/common/bottombar/bottombar.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeThreeComponent,
    PreloaderComponent,
    NavbarStyleOneComponent,
    FooterStyleOneComponent,
    LoginComponent,
    ComingSoonComponent,
    ErrorComponent,
    AboutComponent,
    CandidatesDetailsComponent,
    BlogDetailsComponent,
    BlogComponent,
    ContactComponent,
    SideNavComponent,
    EmployerTopNavComponent,
    ViewJobComponent,
    JobDetailComponent,
    UserProfileComponent,
    LoadingComponent,
    SignupComponent,
    EmployersComponent,
    JobWidgetComponent,
    ViewCompanyComponent,
    CompanyWidgetComponent,
    ViewIndCompanyComponent,
    JobByEmployerComponent,
    JobSeekerKeyskillComponent,
    JobSeekerSkillcertificationComponent,
    JobSeekerLanguageComponent,
    FogetPwdComponent,
    ResetPwdComponent,
    ChangePwdComponent,
    JobQuestionComponent,
    JobQuestionAnswerComponent,
    JobSeekerProfDetailComponent,
    SideNavNotiComponent,
    PrivacyPolicyComponent,
    ListFromCountPipe,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    NgxPaginationModule,
    CarouselModule,
    SafePipeModule,
    MatCheckboxModule,
    SelectDropDownModule,
    MatSidenavModule,
    MatButtonModule,
    ImagecrpComponent,
    BottombarComponent
  ],
  providers: [
    AuthService,
    LoginRouteResolverService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
