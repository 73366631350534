import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JobSeekerService {
  private employmentpath = environment.apiUrl + 'JobSeeker/';
  private educationpath = environment.apiUrl + 'JobSeeker/';
  private profileSummarypath = environment.apiUrl + 'JobSeeker/';
  private profileInfopath = environment.apiUrl + 'JobSeeker/';
  private personalInfopath = environment.apiUrl + 'JobSeeker/';
  private personalSkillpath = environment.apiUrl + 'JobSeeker/';
  private skillCertificatepath = environment.apiUrl + 'JobSeeker/';

  private eventguestpath = environment.apiUrl + 'EventGuests/';

  constructor(private http: HttpClient) { }

  saveKeySkill(skill: any): Observable<any> {
    return this.http.post<any>(this.employmentpath + "add-keyskill", skill);
  }
  getKeySkillBySeekerId(id: any): Observable<any> {
    return this.http.get<any>(this.employmentpath + "keyskill-by-seekerId?id=" + id);
  }
  getKeySkillById(id: any): Observable<any> {
    return this.http.get<any>(this.employmentpath + "keyskill-by-id?id=" + id);
  }
  removeKeySkill(id: any): Observable<any> {
    return this.http.delete<any>(this.employmentpath + "delete-keyskill?id=" + id);
  }

  saveKeySkillCertification(skill: any): Observable<any> {
    return this.http.post<any>(this.employmentpath + "add-skillcertification", skill);
  }
  updateKeySkillCertification(skill: any): Observable<any> {
    return this.http.put<any>(this.employmentpath + "update-skillcertification", skill);
  }
  getKeySkillCertificationBySeekerId(id: any): Observable<any> {
    return this.http.get<any>(this.employmentpath + "skillcertification-by-seekerId?id=" + id);
  }
  getKeySkillCertificationById(id: any): Observable<any> {
    return this.http.get<any>(this.employmentpath + "skillcertification-by-id?id=" + id);
  }
  removeKeySkillCertification(id: any): Observable<any> {
    return this.http.delete<any>(this.employmentpath + "delete-skillcertification?id=" + id);
  }

  saveLanguage(skill: any): Observable<any> {
    return this.http.post<any>(this.employmentpath + "add-language", skill);
  }
  updateLanguage(skill: any): Observable<any> {
    return this.http.post<any>(this.employmentpath + "update-language", skill);
  }
  getLanguageBySeekerId(id: any): Observable<any> {
    return this.http.get<any>(this.employmentpath + "language-by-seekerId?id=" + id);
  }
  getLanguageById(id: any): Observable<any> {
    return this.http.get<any>(this.employmentpath + "language-by-id?id=" + id);
  }
  removeLanguage(id: any): Observable<any> {
    return this.http.delete<any>(this.employmentpath + "delete-language?id=" + id);
  }

  saveEmploymentDetails(employmentDetails: any): Observable<any> {
    return this.http.post<any>(this.employmentpath + "add-employement-detail", employmentDetails);
  }
  updateEmploymentDetails(employmentDetails: any): Observable<any> {
    return this.http.put<any>(this.employmentpath + "update-employement-detail", employmentDetails);
  }
  getEmploymentById(id: Number): Observable<any> {
    return this.http.get<any>(this.employmentpath + "employment-by-id?id=" + id);
  }
  deleteEmploymentById(id: Number): Observable<any> {
    return this.http.delete<any>(this.employmentpath + "delete-employement-detail?id=" + id);
  }
  getEmploymentAll(id: Number): Observable<any> {
    return this.http.get<any>(this.employmentpath + "employment-by-seekerId?seekerId=" + id);
  }

  saveEducationDetails(educationDetails: any): Observable<any> {
    return this.http.post<any>(this.educationpath + "add-education", educationDetails);
  }
  updateEducationDetails(educationDetails: any): Observable<any> {
    return this.http.put<any>(this.educationpath + "update-education", educationDetails);
  }
  getEducationById(id: Number): Observable<any> {
    return this.http.get<any>(this.educationpath + "education-by-id?id=" + id);
  }
  deleteEducationById(id: Number): Observable<any> {
    return this.http.delete<any>(this.educationpath + "delete-education?id=" + id);
  }
  getEducationAll(id: Number): Observable<any> {
    return this.http.get<any>(this.educationpath + "education-by-seekerId?seekerId=" + id);
  }

  saveProfileSummary(profileSummary: any): Observable<any> {
    return this.http.post<any>(this.profileSummarypath + "add-profile-summary", profileSummary);
  }
  updateProfileSummary(profileSummary: any): Observable<any> {
    return this.http.put<any>(this.profileSummarypath + "update-profile-summary", profileSummary);
  }
  getProfileSummary(id: any): Observable<any> {
    return this.http.get<any>(this.profileSummarypath + "summary-by-seekerId?userId=" + id);
  }

  saveProfileInfo(data: any): Observable<any> {
    return this.http.post<any>(this.profileInfopath + "create", data);
  }
  addCvProfileInfo(data: any): Observable<any> {
    return this.http.post<any>(this.profileInfopath + "add-cv", data);
  }
  addClProfileInfo(data: any): Observable<any> {
    return this.http.post<any>(this.profileInfopath + "add-cover-letter", data);
  }
  addPicProfileInfo(data: any): Observable<any> {
    return this.http.post<any>(this.profileInfopath + "add-pic", data);
  }
  updateProfileInfo(data: any): Observable<any> {
    console.log(data, this.profileInfopath + "update");
    console.log(data, this.profileInfopath + "update");
    return this.http.put<any>(this.profileInfopath + "update", data);
  }
  getProfileInfo(id: any): Observable<any> {
    return this.http.get<any>(this.profileInfopath + "userId?userId=" + id);
  }

  getProfileInfoBySeekerId(id: any): Observable<any> {
    return this.http.get<any>(this.profileInfopath + "seekerId?seekerId=" + id);
  }

  savePersonalInfo(data: any): Observable<any> {
    return this.http.post<any>(this.personalInfopath + "add-personal-detail", data);
  }
  getPPersonalInfo(id: any): Observable<any> {
    return this.http.get<any>(this.personalInfopath + "personalinfo-seeker-id?seekerId=" + id);
  }
  updatePersonalInfo(data: any): Observable<any> {
    return this.http.put<any>(this.personalInfopath + "update-personal-detail", data);
  }

  saveSkill(data: any): Observable<any> {
    return this.http.post<any>(this.personalSkillpath, data);
  }

  getPSkillInfo(id: any): Observable<any> {
    return this.http.get<any>(this.personalSkillpath + id);
  }



  GetSingleEventUserResponse(
    eventId: number,
    guestId: string
  ): Observable<any> {
    return this.http.get<any>(
      this.eventguestpath + 'event-guest/' + eventId + '/' + guestId + ''
    );
  }

  DeleteEventGuest(id: number): Observable<any> {
    return this.http.delete<any>(this.eventguestpath + 'delete/' + id);
  }
}
