<nav id="sidebar-admin-wraper" [ngClass]="active">
    <div class="page-logo">
        <a><img src="https://mahadjobs.com/wp-content/uploads/2023/02/mahadjobs-no-back.png" alt=""></a>
    </div>
    
    <div class="scroll-wrapper admin-nav scrollbar-macosx" style="position: relative;"><div class="admin-nav scrollbar-macosx scroll-content" style="height: 538px; margin-bottom: 0px; margin-right: 0px; max-height: none;">
        <ul>
            <li [ngClass]="selected == 0? 'active':''" (click)="route('/employer-dashboard')">
                <a ><i class="fa fa-home"></i><span class="admin-nav-text">Dashboard</span></a>
            </li>

            <li [ngClass]="selected == 1? 'active':''" (click)="route('/employer-company')" >
                <a routerLink="/employer-company"><i class="fa fa-user-tie"></i><span class="admin-nav-text">Company Profile</span></a>
            </li>

            <li [ngClass]="selected == 2? 'active':''" class="has-child" (click)="toggleMenu()">
                <a href="javascript:;"><i class="fa fa-suitcase"></i><span class="admin-nav-text">Jobs</span></a><div><i  class="fa fa-angle-right submenu-toogle"></i></div>
                <ul class="sub-menu" [ngStyle]="{'display': display}">
                    <li (click)="route('/employer-postjob')"> <a><span class="admin-nav-text">Post a New Jobs</span></a></li>
                    <li (click)="route('/employer-managejob')"> <a><span class="admin-nav-text">Manage Jobs</span></a></li>
                </ul>
            </li>
            <!-- <li [ngClass]="selected == 3? 'active':''" (click)="route('/employer-viewcandidate')">
                <a><i class="fa fa-user-friends"></i><span class="admin-nav-text">Candidates</span></a>
            </li> -->
            <!-- <li>
                <a href="dash-bookmark.html"><i class="fa fa-bookmark"></i><span class="admin-nav-text">Bookmark Resumes</span></a>
            </li>

            <li>
                <a href="dash-package.html"><i class="fa fa-money-bill-alt"></i><span class="admin-nav-text">Packages</span></a>
            </li>
              
            <li class="has-child">
                <a href="javascript:;"><i class="fa fa-envelope"></i><span class="admin-nav-text">Messages <sup class="twm-msg-noti">5</sup></span></a><div class="fa fa-angle-right submenu-toogle"></div>
                <ul class="sub-menu">
                    <li> <a href="dash-messages.html"><span class="admin-nav-text">MSG Style-1</span></a></li>
                    <li> <a href="dash-messages_2.html"><span class="admin-nav-text">MSG Style-2</span></a></li>
                </ul>
            </li>

            <li>
                <a href="dash-resume-alert.html"><i class="fa fa-bell"></i><span class="admin-nav-text">Resume Alerts</span></a>
            </li>  
            
            <li>
                <a href="dash-my-profile.html"><i class="fa fa-user"></i><span class="admin-nav-text">My Profile</span></a>
            </li>

            <li>
                <a href="dash-change-password.html"><i class="fa fa-fingerprint"></i><span class="admin-nav-text">Change Password</span></a>
            </li>
            
            <li>
                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#delete-dash-profile"><i class="fa fa-trash-alt"></i><span class="admin-nav-text">Delete Profile</span></a>
            </li>  
                                -->
            <li>
                <a (click)="logout()" data-bs-toggle="modal" data-bs-target="#logout-dash-profile"><i class="fa fa-share-square"></i><span class="admin-nav-text">Logout</span></a>
            </li>                    
            
        </ul>
    </div><div class="scroll-element scroll-x"><div class="scroll-element_outer"><div class="scroll-element_size"></div><div class="scroll-element_track"></div><div class="scroll-bar" style="width: 0px;"></div></div></div><div class="scroll-element scroll-y"><div class="scroll-element_outer"><div class="scroll-element_size"></div><div class="scroll-element_track"></div><div class="scroll-bar" style="height: 0px;"></div></div></div></div>   
</nav>