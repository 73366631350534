import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { MessageService } from 'src/app/services/observables.service';

@Component({
  selector: 'app-footer-style-one',
  templateUrl: './footer-style-one.component.html',
  styleUrls: ['./footer-style-one.component.scss']
})
export class FooterStyleOneComponent implements OnInit {

  isMobile: boolean = false;
  constructor(private router: Router, private msgService: MessageService) { }

  ngOnInit(): void {

    // this.getIsMobile();
    this.routerListner();
    // this.listenMessage();
    this.listenMobileEvent()
  }
  ngAfterViewInit(){
    console.log("footer done");
    this.getIsMobile();
  }

  getIsMobile() {
    this.isMobile = localStorage.getItem('isMobile') == 'true' ? true : false;

  }
  routerListner() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe((event1: NavigationEnd) => {
        this.getIsMobile();
        // if (!(event instanceof NavigationEnd)) {
        //   return;
        // }
      });
  }
  listenMobileEvent() {
    window.addEventListener("flutterInAppWebViewPlatformReady", function (event) {
      (window as any).flutter_inappwebview.callHandler('handlerFoo')
        .then(function (result) {
          // print to the console the data coming
          // from the Flutter side.
          console.log(JSON.stringify(result), "fomr mobile");
          if (result["isMobile"] == 'true') {
            localStorage.setItem('isMobile', 'true');
            console.log("sending msg fofoter");

            console.log("send msg");
            // msgService.sendMessage("isMobile");
            // this.sendMsg();
            // this.router.navigateByUrl("/");
          }

          (window as any).flutter_inappwebview
            .callHandler('handlerFooWithArgs', 1, true, ['bar', 5], { foo: 'baz' }, result);
        });
    });
  }
  listenMessage() {
    // console.log("listning");
    this.msgService.getMessage().subscribe(msg => {
      console.log(msg, "msg");
      console.log(msg.text, "msg.text");
      if (msg.text == "isMobile") {
        this.getIsMobile();
      }
    });
  }

}
