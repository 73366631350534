<div class="page-content mt-5" style="transform: none;" *ngIf="job.id != 0">

    <!-- Job Detail V.2 START -->
    <div class="section-full  p-t50 p-b90 bg-white" style="transform: none;">
        <div class="container" style="transform: none;">

            <!-- BLOG SECTION START -->
            <div class="section-content" style="transform: none;">
                <div class="twm-media-bg">
                    <!-- <img [src]="baseUrl + job.companyId + '.png'" alt=""> -->
                    <!-- <div class="twm-jobs-category green"><span class="twm-bg-green">New</span></div> -->
                    <div class="twm-job-self-bottom" *ngIf="!applied">
                        <button class="site-button" role="button" data-bs-target="#sign_up_popup" data-bs-toggle="modal"
                            data-bs-dismiss="modal">
                            Apply Now
                        </button>
                    </div>
                    <div class="twm-job-self-bottom" *ngIf="applied">
                        <button class="site-button" role="button">
                            Applied
                        </button>
                    </div>

                </div>
                <div class="twm-job-self-wrap twm-job-detail-v2 bg" [ngStyle]="{'background-image': 'url('+baseUrl + job.company?.userDefined1 +')',
                    'background-repeat': 'no-repeat',
                    'background-size': 'cover',    'background-position': 'center'}">
                    <div class="twm-job-self-info">
                        <div class="twm-job-self-top">


                            <div class="twm-mid-content">

                                <div class="twm-media logo">
                                    <img *ngIf="!error" [src]="baseUrl + job.company?.userDefined2 + ''" alt="#"
                                        (error)="imageError()">
                                    <i *ngIf="error" class="fas fa-business-time i"></i>
                                </div>
                                <div class="form-group info">
                                    <h4 class="twm-job-title">{{job.jobTitle}} <span class="twm-job-post-duration">/
                                            {{masterService.getDateDiff(job.creationDate)}}</span></h4>
                                    <p class="twm-job-address"><mat-icon
                                            class="icon">location_on</mat-icon>{{job.company.address}}-
                                        {{job.company.location}},
                                        {{job.company.country}}
                                    </p>
                                    <div class="twm-job-self-mid">
                                        <div class="twm-job-self-mid-left">
                                            <!-- <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a> -->
                                            <div class="twm-jobs-amount">{{job.userDefined1}} -
                                                {{job.userDefined3}} / <span>{{job.userDefined2}}</span></div>
                                        </div>
                                        <!-- <div class="twm-job-apllication-area">Application ends:
                                        <span class="twm-job-apllication-date">October 1, 2025</span>
                                    </div> -->
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
                <div class="twm-job-detail-2-wrap" style="transform: none;">
                    <div class="row d-flex justify-content-center" style="transform: none;">
                        <div class="col-lg-4 col-md-12 rightSidebar"
                            style="position: relative; overflow: visible; box-sizing: border-box; min-height: 1px;">






                            <div class="theiaStickySidebar"
                                style="padding-top: 0px; padding-bottom: 1px; position: static; transform: none; top: 0px; left: 105.2px;">
                                <div class="side-bar mb-4">
                                    <div class="twm-s-info2-wrap mb-5">
                                        <div class="twm-s-info2">
                                            <h4 class="section-head-small mb-4">Job Information</h4>
                                            <ul class="twm-job-hilites">
                                                <li>
                                                    <mat-icon class="icon">calendar_month</mat-icon>
                                                    <!-- <i class="fas fa-calendar-alt"></i> -->
                                                    <span class="twm-title">Date Posted</span>
                                                </li>
                                                <li>
                                                    <mat-icon class="icon">visibility</mat-icon>
                                                    <!-- <i class="fas fa-eye"></i> -->
                                                    <span class="twm-title">{{job.jobViews}} Views</span>
                                                </li>
                                                <li>
                                                    <mat-icon class="icon">badge</mat-icon>
                                                    <!-- <i class="fas fa-file-signature"></i> -->
                                                    <span class="twm-title">{{job.applicant}} Applicants</span>
                                                </li>
                                            </ul>
                                            <ul class="twm-job-hilites2">

                                                <li>
                                                    <div class="twm-s-info-inner">
                                                        <mat-icon class="icon">calendar_month</mat-icon>
                                                        <!-- <i class="fas fa-calendar-alt"></i> -->
                                                        <span class="twm-title">Date Posted</span>
                                                        <div class="twm-s-info-discription">{{job.creationDate}}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="twm-s-info-inner">
                                                        <!-- <i class="fas fa-map-marker-alt"></i> -->
                                                        <mat-icon class="icon">location_on</mat-icon>
                                                        <span class="twm-title">Location</span>
                                                        <div class="twm-s-info-discription">{{job.jobLocation}},
                                                            {{job.company?.country}}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="twm-s-info-inner">
                                                        <mat-icon class="icon">person</mat-icon>

                                                        <!-- <i class="fas fa-user-tie"></i> -->
                                                        <span class="twm-title">Job Title</span>
                                                        <div class="twm-s-info-discription">{{job.jobTitle}}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="twm-s-info-inner">
                                                        <mat-icon class="icon">wc</mat-icon>

                                                        <!-- <i class="fas fa-user-tie"></i> -->
                                                        <span class="twm-title">Gender</span>
                                                        <div class="twm-s-info-discription">{{job.gender}}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="twm-s-info-inner">
                                                        <mat-icon class="icon">schedule</mat-icon>

                                                        <!-- <i class="fas fa-clock"></i> -->
                                                        <span class="twm-title">Experience</span>
                                                        <div class="twm-s-info-discription">{{job.experieance}} Year
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="twm-s-info-inner">
                                                        <mat-icon class="icon">paid</mat-icon>

                                                        <!-- <i class="fas fa-money-bill-wave"></i> -->
                                                        <span class="twm-title">Offered Salary</span>
                                                        <div class="twm-s-info-discription">{{job.userDefined1}} -
                                                            {{job.userDefined3}} / {{job.userDefined2}}</div>
                                                    </div>
                                                </li>

                                            </ul>

                                        </div>
                                    </div>

                                    <!-- <div class="widget tw-sidebar-tags-wrap">
                                    <h4 class="section-head-small mb-4">Job Skills</h4>
                                    
                                    <div class="tagcloud">
                                        <a href="javascript:void(0)">Html</a>
                                        <a href="javascript:void(0)">Python</a>
                                        <a href="javascript:void(0)">WordPress</a>                                            
                                        <a href="javascript:void(0)">JavaScript</a>
                                        <a href="javascript:void(0)">Figma</a>
                                        <a href="javascript:void(0)">Angular</a>
                                        <a href="javascript:void(0)">Reactjs</a>
                                        <a href="javascript:void(0)">Drupal</a>
                                        <a href="javascript:void(0)">Joomla</a>
                                    </div>
                                </div> -->

                                </div>
                                <div class="twm-advertisment m-b0" style="background-image:url(images/add-bg.jpg);">
                                    <div class="overlay"></div>
                                    <h4 class="twm-title">Keyskills</h4>
                                    <div class="d-flex key-skill-main mb-1">
                                        <div class="key-skill me-3 mt-3" *ngFor="let item of skillList">

                                            <span>{{item}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-12">
                            <!-- Candidate detail START -->
                            <div class="cabdidate-de-info">

                                <h4 class="twm-s-title m-t0">Company Description:</h4>

                                <p [innerHtml]="job.company.descriptions">
                                </p>

                                <!-- <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.</p> -->

                                <h4>Job Overview</h4>

                                <p [innerHtml]="job.jobDescription">

                                    <!-- {{job.jobDescription}} -->
                                </p>


                                <app-job-question-answer [jobId]="jobId"></app-job-question-answer>

                            </div>
                            <div class="col-md-12 wt-separator-two-part-right text-right">
                                <a [routerLink]="['/view-ind-company']"
                                [queryParams]="{'id':job.company?.id,'company':urlChangeComp(job.company?.nameEn)}" class="site-button1">More jobs by {{job.company?.nameEn}}</a>
                            </div>
                        </div>

                    </div>
                    <div class="section-full p-t50 p-b50 site-bg-gray twm-bg-ring-wrap2 mt-5">
                        <div class="twm-bg-ring-right"></div>
                        <div class="twm-bg-ring-left"></div>
                        <div class="container">
                            <div class="wt-separator-two-part">
                                <div class="row wt-separator-two-part-row">
                                    <div class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-left">
                                        <!-- TITLE START-->
                                        <div class="section-head left wt-small-separator-outer">
                                            <div class="wt-small-separator site-text-primary">
                                                <div>Related jobs</div>
                                            </div>

                                        </div>
                                        <!-- TITLE END-->
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right text-right">
                                        <a routerLink="/view-job" class="site-button">Browse All Jobs</a>
                                    </div>
                                </div>
                            </div>

                            <div class="section-content">
                                <div class="twm-jobs-grid-wrap">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6" *ngFor="let item of jobList; index as i">
                                            <div class="twm-jobs-grid-style1 m-b30">
                                                <div class="twm-media flex">
                                                    <img *ngIf="!item.userDefined4 != 'true'"
                                                        (error)="imageErrorComp(i)" [src]="
                                                    baseUrl +
                                                    item?.company.userDefined2
                                                " alt="#" />
                                                    <i *ngIf="item.userDefined4 == 'true'"
                                                        class="fas fa-business-time i"></i>
                                                    <!-- <p class="twm-job-address">{{item.company}}</p> -->
                                                </div>
                                                <!-- <span class="twm-job-post-duration">{{
                                                    masterService.getDateDiff(
                                                    item.creationDate
                                                    )
                                                    }}</span> -->
                                                <div class="twm-jobs-category green">
                                                    <span class="twm-bg-green">{{
                                                        item.jobType
                                                        }}</span>
                                                </div>
                                                <div class="twm-mid-content">
                                                    <h4>{{ item.company["nameEn"] }}</h4>
                                                    <a routerLink="/job-detail" class="twm-job-title">
                                                        <h4>{{ item.jobTitle }}</h4>
                                                    </a>
                                                    <p class="twm-job-address">
                                                        {{ item.jobLocation }}, {{item.country.nameEn}}
                                                    </p>
                                                    <!-- <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a> -->
                                                </div>
                                                <div class="twm-right-content">
                                                    <div class="twm-jobs-amount">
                                                        {{ item.userDefined1 }} - {{ item.userDefined3 }}
                                                        <span>/ {{ item.userDefined2 }}</span>
                                                    </div>
                                                    <a (click)="urlChange('/job-detail?Id=' + item.id, item)"
                                                        class="twm-jobs-browse site-text-primary">Browse Job</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    </div>
    <div [class]="'modal fade twm-sign-up' + class" id="sign_up_popup" aria-hidden="true"
        aria-labelledby="sign_up_popupLabel" [style]="style" tabindex="-1">
        <app-job-question [jobId]="jobId" [applyToJob]="applyJob" [getJobId]="getJobId"></app-job-question>
    </div>

    <!-- Job Detail V.2 END -->


</div>