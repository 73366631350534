<div class="twm-jobs-list-style1 mb-5" *ngIf="company != null" [routerLink]="['/view-ind-company']"
    [queryParams]="{'id':company.id,'company':urlChange(company.nameEn)}">
    <div class="row">
        <div class="col-lg-4 col-sm-12 flex logo-size">
            <!-- <div class="twm-media">
                <img [src]="baseUrl + '' + '.png'" alt="#">
            </div> -->
            <img *ngIf="!error" [src]="baseUrl + company.userDefined2" alt="#" class="img" (error)="imageError()">
            <i *ngIf="error" class="fas fa-business-time i"></i>
        </div>
        <div class="col-lg-6 col-sm-10 col-12">

            <div class="twm-mid-content">
                <p class="twm-job-address mb-0">{{company.industry["nameEn"]}}</p>
                <h4>{{company.nameEn}}</h4>
                <!-- <a [href]="'/job-detail?Id=' + company.id" class="twm-job-title">
                    <h4>{{company.jobTitle}}<br /><span class="twm-job-post-duration">Posted
                            {{masterService.getDateDiff(company.creationDate)}}</span></h4>
                </a> -->
                <p class="twm-job-address mt-0"><mat-icon class="">location_on</mat-icon>
                    {{company.location["nameEn"]}}, {{company?.country?.nameEn}}
                </p>
                <!-- <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a> -->
            </div>
        </div>
        <!-- <div class="col-12">
            <button class="btn btn-primary"></button>
        </div> -->
    </div>
    <!-- <div class="twm-right-content">
    </div> -->
</div>