import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-side-nav-noti',
  templateUrl: './side-nav-noti.component.html',
  styleUrls: ['./side-nav-noti.component.scss']
})
export class SideNavNotiComponent {
  @Input() jobSeekerNotificationModelList = [];
  @Input() close = () =>{};
  close1(){
    this.close();
  }
}
