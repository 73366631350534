import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public loader:boolean = true;
  
  toggle(){
    this.loader = !this.loader;
  }
}