import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyFilterModel } from 'src/app/model/CompanyFilter';
import { CompanyModel } from 'src/app/model/Compnay';
import { JobFilterModel } from 'src/app/model/JobFilter';
import { CityModel } from 'src/app/model/master/City';
import { CourseModel } from 'src/app/model/master/Course';
import { FunctionalAreaModel } from 'src/app/model/master/FunctionalArea';
import { IndustryModel } from 'src/app/model/master/Industry';
import { JobTypeModel } from 'src/app/model/master/JobType';
import { NationalityModel } from 'src/app/model/master/Nationality';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { JobService } from 'src/app/services/job.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-company',
  templateUrl: './view-company.component.html',
  styleUrls: ['./view-company.component.scss']
})
export class ViewCompanyComponent implements OnInit {
  masterService: MasterService;
  p: any;
  industrySearch1: string = ""
  locationSearch1: string = ""
  isMobile: boolean = false;
  show: boolean = true;
  baseUrl: string = environment.apiUrl + "ProfilePhoto/company";
  currPage: number = 0;
  compPerPage: number = 11;
  totalCompPerPage: number = 0;
  constructor(masterService: MasterService, private companySrv: CompanyService, private authService: AuthService,
    public loader: LoaderService, private router:Router) {
    this.masterService = masterService;
    this.loader.loader = true;
  }
  ngOnInit(): void {
    var urlString = window.location.href;
    var url = new URL(urlString);
    var id = url.searchParams.get("locationId");
    if (id != null && id != undefined && id != "") {
      this.companyFilter.locationId = [parseInt(id)];
    }
    id = url.searchParams.get("industryId");
    if (id != null && id != undefined && id != "") {
      this.companyFilter.industryId = [parseInt(id)];
    }
    id = url.searchParams.get("jobTitle");
    if (id != null && id != undefined && id != "") {
      this.companyFilter.companyName = id.toString();
    }

    this.masterService.getNationalitAll().subscribe((data) => {
      this.nationalityList = data;
      console.log(this.nationalityList);
    });
    this.masterService.getCityAll().subscribe((data) => {
      this.cityList = data;
      console.log(this.cityList);
    });
    this.masterService.getIndustryAll().subscribe((data) => {
      this.industryList = data;
      console.log(this.cityList);
    });
    this.masterService.getJobTypeAll().subscribe((data) => {
      this.jobTypeList = data;
      console.log(this.jobTypeList);
    });
    this.masterService.getfunctionalAreaAll().subscribe((data) => {
      this.functionalAreaList = data;
      console.log(this.functionalAreaList);
    });
    this.masterService.getCourseAll().subscribe((data) => {
      this.coursesList = data;
      console.log(this.functionalAreaList);
    });
    // this.dataSource.sort = this.sort;
    this.getCompanyList();
    this.isMobileFunc()


  }
  industry: IndustryModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  industryList: Array<IndustryModel> = []

  city: CityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  cityList: Array<CityModel> = []

  nationality: NationalityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  nationalityList: Array<NationalityModel> = []

  jobType: JobTypeModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  jobTypeList: Array<JobTypeModel> = []

  functionalArea: FunctionalAreaModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    count: 0
  }
  functionalAreaList: Array<FunctionalAreaModel> = []

  courses: CourseModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  coursesList: Array<CourseModel> = []

  company: CompanyModel = {
    id: 0,
    creationUserName: '',
    creationDate: '',
    lastUpdateUserName: '',
    lastUpdateDate: '',
    deletedOn: '',
    deletedBy: '',
    nameEn: '',
    nameAr: '',
    descriptions: '',
    emailId: '',
    locationId: 0,
    phoneNo: '',
    industryId: 0,
    crnumber: '',
    location: undefined,
    industry: undefined,
    establishmentYear: 0,
    address: '',
    userDefined1: '',
    userDefined2: '',
    userDefined3: '',
    userDefined4: '',
    countryId: 0,
    country: ''
  }
  companyList: Array<CompanyModel> = [];

  companyFilter: CompanyFilterModel = {
    locationId: [],
    industryId: [],
    companyName: '',
    countPerPage: this.compPerPage,
    page: 0
  }

  delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  }
  
  isMobileFunc() {
    if (window.innerWidth < 555) {
      this.isMobile = true;
      this.toggleFilter();
    }
  }
  toggleFilter() {
    this.show = !this.show;
  }

  getCompanyList() {
    this.loader.loader = true;
    this.getCompanyCount();
    this.companySrv.getByFilter(this.companyFilter).subscribe((data) => {
      data = data.reverse()
      this.companyList = data;
      this.loader.loader = false;
    });
  }

  getCompanyCount() {
    this.loader.loader = true;
    this.companySrv.getCountByFilter(this.companyFilter).subscribe((data) => {
      this.totalCompPerPage = data;
    });
  }

  paginate(p: any) {
    this.currPage = (p as number) - 1;
    this.companyFilter.page = this.currPage
    this.getCompanyList()
  }

  locationSearch(event: any) {
    console.log("filterArea");
    this.locationSearch1 = event.target.value;
  }

  locationFilter1(id: Number) {
    console.log("functionalAreaFilter", id);
    if (this.companyFilter.locationId.indexOf(id) == -1) {

      this.companyFilter.locationId.push(id);
    } else {
      let index: number = this.companyFilter.locationId.indexOf(id);
      this.companyFilter.locationId.splice(index, 1);
    }
    this.getCompanyList();
    console.log(this.companyFilter);
  }

  industrySearch(event: any) {
    console.log("filterArea");
    this.industrySearch1 = event.target.value;
  }

  industryFilter(id: Number) {
    console.log("functionalAreaFilter", id);
    if (this.companyFilter.locationId.indexOf(id) == -1) {

      this.companyFilter.locationId.push(id);
    } else {
      let index: number = this.companyFilter.locationId.indexOf(id);
      this.companyFilter.locationId.splice(index, 1);
    }
    this.getCompanyList();
    console.log(this.companyFilter);
  }
  async filterTitle(event: any) {
    this.companyFilter.companyName = event.target.value;
    await this.delay(2000);
    this.getCompanyList();
  }
  resume() {
    let auth = this.authService.isAuth();
    if (auth) {
      let val = this.authService.getAuthStorage();
      if (val.type == "user") {
        this.router.navigateByUrl("/user-profile")

        // window.location.href = "/user-profile";
      }
    } else {
      this.router.navigateByUrl("/login")

      // window.location.href = "/login"
    }
  }

}
