import { Component, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { JobSeekerNotificationModel } from 'src/app/model/JobSeekerNotification';
import { AuthService } from 'src/app/services/auth.service';
import { JobSeekerNotificationService } from 'src/app/services/jobSeekerNotification.service';

@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss']
})
export class NavbarStyleOneComponent implements OnInit {

  urlList: Array<string> = ['/', '/view-company', '/view-job', '/user-profile']
  selected: number = -1;
  isMobile: boolean = false;

  IsAuthenticated: boolean = false;
  name: string = ""
  type: string = "";
  login: boolean = false;
  sideNav: boolean = false;
  jobSeekerNotificationModel: JobSeekerNotificationModel = {
    id: 0,
    jobSeekerId: 0,
    companyId: 0,
    sentDateTime: '',
    message: '',
    subject: '',
    userDefined1: ''
  }
  jobSeekerNotificationModelList: JobSeekerNotificationModel[] = [];;
  count: Number = 0;
  menu: boolean = false;

  constructor(private authService: AuthService, private jobNotiSrv: JobSeekerNotificationService,
    public router: Router
  ) {
    this.routerlisnter()
  }

  ngOnInit(): void {
    this.IsAuthenticated = this.authService.isAuth();
    this.name = this.authService.getAuthStorage().username.split("@")[0];
    if (this.authService.getAuthStorage().jobseekerId != 0 && this.authService.getAuthStorage().jobseekerId != null) {
      this.getCount();
      this.getNoti();
      this.login = true;
    }
    this.type = this.authService.getAuthStorage().type;
    this.setIndex();
    this.getIsMobile();

  }
  getIsMobile() {
    this.isMobile = localStorage.getItem('isMobile') == 'true' ? true : false;

  }
  routerlisnter() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe((event1: NavigationEnd) => {
        let index = this.urlList.indexOf(event1.url);
        console.log(event1.url, "botombar", index)
        this.getIsMobile();

        localStorage.setItem("botomIndex", index.toString());
        this.selected = index;
        this.menu = false;
        // if (!(event instanceof NavigationEnd)) {
        //   return;
        // }
      });
  }
  setIndex() {
    this.selected = parseInt(localStorage.getItem("botomIndex") ?? "-1")
  }



  logout() {
    this.authService.LogOut();
    this.router.navigateByUrl("/");
    // window.location.href = "/"
  }

  getCount() {
    this.jobNotiSrv.getCount(this.authService.getAuthStorage().jobseekerId).subscribe((count) => {
      this.count = count
    })
  }

  updateNoti() {
    this.jobNotiSrv.updateBulk(this.authService.getAuthStorage().jobseekerId).subscribe((count) => {
      this.count = 0;
    })
  }

  getNoti() {
    this.jobNotiSrv.getByJobSeekerId(this.authService.getAuthStorage().jobseekerId).subscribe((data) => {
      this.jobSeekerNotificationModelList = data;

    })
  }

  openNoti() {
    if (this.authService.getAuthStorage().jobseekerId != 0 && this.authService.getAuthStorage().jobseekerId != null) {
      this.updateNoti();
    }
    this.sideNav = true;
  }
  close() {
    this.sideNav = false;
  }
  toggle() {
    this.menu = !this.menu;
  }

  back() {
    history.back()
  }


}
