<div class="upload-btn-wrapper">
    <div id="upload-image-grid"></div>
    <button (click)="triggerFileInputClick()" class="site-button button-sm">
        Upload Photo
    </button>

    <button *ngIf="!isHidden" style="margin-left: 2px;" (click)="handleUploadCroppedPic()" class="site-button button-sm">
        Save
    </button>
    <input id="fileInput" type="file" name="myfile" accept=".png, .jpeg, .jpg" (change)="fileChangeEvent($event)"
        style="display: none;" />
</div>

<image-cropper
style="font-size: 0;padding: 0px;line-height: 0%;"
[imageChangedEvent]="imageChangedEvent"
 [maintainAspectRatio]="true"
 [aspectRatio]="1 / 1" format="png"
(imageCropped)="imageCropped($event)"
(imageLoaded)="imageLoaded($event)">
</image-cropper>
