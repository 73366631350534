import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ResetPwdModel } from 'src/app/model/ResetPwd';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.scss']
})
export class ResetPwdComponent implements OnInit {
  checkPwd: string = "";
  resetPwd: ResetPwdModel = {
    email: '',
    password: '',
    token: '',
    userType: 'user',
  }
  submit: boolean = false;
  constructor(
    private authService: AuthService,
    private router:Router,
    private tost: ToastrService,
    private spinner: NgxSpinnerService,
    public loader: LoaderService,
  ) {
    this.loader.loader = false;

  }
  ngOnInit(): void {
    var urlString = window.location.href;
    var url = new URL(urlString);
    let email0: any = url.searchParams.get("email");
    if (email0 != null && email0 != undefined && email0 != "") {
      this.resetPwd.email = email0;
      let token: any = url.searchParams.get("token");
      if (token != null && token != undefined && token != "") {
        this.resetPwd.token = token;
      }
    }
  }
  onSubmit() {
    this.submit = true;
    if (this.checkPwd == this.resetPwd.password) {
      this.loader.loader = true;
      this.authService.resetPwd(this.resetPwd).subscribe((data) => {
        if (data.isSuccess) {
          this.tost.success("Password reset successfully");
          this.loader.loader = false;
          this.router.navigateByUrl("/login")

          // window.location.href = "/login"
        } else {
          this.pwdErrorMsg(data.objects);

          this.loader.loader = false;
        }
      })
    }
  }
  pwdErrorMsg(data: Array<any>) {
    let err: string = ""
    if (data.length > 0) {
      for (let i in data) {
        err = err + data[i]["description"] + ", "
      }
    }
    this.tost.error("Error, " + err);
  }
}
