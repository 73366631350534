import { Component, OnInit } from '@angular/core';
import { CompanyFilterModel } from 'src/app/model/CompanyFilter';
import { CompanyModel } from 'src/app/model/Compnay';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { JobService } from 'src/app/services/job.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { environment } from 'src/environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-job-by-employer',
  templateUrl: './job-by-employer.component.html',
  styleUrls: ['./job-by-employer.component.scss']
})
export class JobByEmployerComponent implements OnInit {
  baseUrl: string = environment.apiUrl + "ProfilePhoto/company";
  constructor(masterService: MasterService, private jobSrv: JobService, private authService: AuthService,
    private companySrv: CompanyService,
    public loader: LoaderService) {
    // this.masterService = masterService;
    this.loader.loader = true;
  }
  currPage:number = 0;
  compPerPage:number = 11;
  totalCompPerPage:number = 0;
  ngOnInit(): void {
    this.getCompanyList();
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay: false,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    responsive: {
    },
    nav: false
  }

  company: CompanyModel = {
    id: 0,
    creationUserName: '',
    creationDate: '',
    lastUpdateUserName: '',
    lastUpdateDate: '',
    deletedOn: '',
    deletedBy: '',
    nameEn: '',
    nameAr: '',
    descriptions: '',
    emailId: '',
    locationId: 0,
    phoneNo: '',
    industryId: 0,
    crnumber: '',
    location: undefined,
    industry: undefined,
    establishmentYear: 0,
    address: '',
    userDefined1: '',
    userDefined2: '',
    userDefined3: '',
    userDefined4: '',
    countryId: 0,
    country: ''
  }
  companyList: Array<CompanyModel> = [];

  companyFilter: CompanyFilterModel = {
    locationId: [],
    industryId: [],
    companyName: '',
    countPerPage: this.compPerPage,
    page: 0
  }

  getCompanyList() {
    this.loader.loader = true;

    this.companySrv.getByFilter(this.companyFilter).subscribe((data) => {
      data = data.reverse()
      this.companyList = data;
      console.log(this.companyList);
      this.loader.loader = false;
    });
  }
}
