<div class="page-content" style="transform: none;">



    <!-- OUR BLOG START -->
    <div class="section-full p-tgood  p-b90 site-bg-white" style="transform: none;">


        <app-loading *ngIf="loader.loader"></app-loading>


        <div class="container" style="transform: none;">

            <h2 class="text-center"> Employers </h2>
            <hr />
            <div class="row" style="transform: none;">

                <div class="col-lg-4 col-md-12 rightSidebar"
                    style="position: relative; overflow: visible; box-sizing: border-box; min-height: 1px;">


                    <button *ngIf="isMobile == true" (click)="toggleFilter()" class="site-button right-mobile"><span></span>{{!show?'  Filter  ':'    Apply   '}}<span></span></button>




                    <div *ngIf="show" class="theiaStickySidebar"
                        style="padding-top: 0px; padding-bottom: 1px; position: static; transform: none; top: 0px; left: 186.6px;">
                        <div class="side-bar">

                            <div class="sidebar-elements search-bx">

                                <form>

                                    <div class="form-group mb-4">
                                        <h4 class="section-head-small mb-4">Keyword</h4>
                                        <div class="input-group">
                                            <input type="text" [value]="this.companyFilter.companyName"
                                                class="form-control" placeholder="Company Name or Keyword"
                                                (keyup)="filterTitle($event)">
                                            <button class="btn" type="button"><i class="feather-search"></i></button>
                                        </div>
                                    </div>

                                    <div class="form-group mb-4">
                                        <h4 class="section-head-small mb-4">Location</h4>
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Search location"
                                                (keyup)="locationSearch($event)">
                                            <!-- <button class="btn" type="button"><i class="feather-map-pin"></i></button> -->
                                        </div>
                                        <ul style="max-height: 200px; overflow-y: scroll;">
                                            <div *ngFor="let item of cityList; let i = index " class="mt-3">
                                                <li
                                                    *ngIf="locationSearch1 == '' || (item.nameEn| lowercase).includes(locationSearch1)">
                                                    <div class=" form-check">
                                                        <input type="checkbox" class="form-check-input"
                                                            [id]="'exampleCheck3' + item.id"
                                                            (change)="locationFilter1(item.id)"
                                                            [checked]="this.companyFilter.locationId.includes(item.id)">
                                                        <label class="form-check-label"
                                                            for="exampleCheck1">{{item.nameEn}}</label>
                                                    </div>
                                                    <span class="twm-job-type-count"
                                                        *ngIf="locationSearch1 == '' || (item.nameEn| lowercase).includes(locationSearch1)">{{item.count}}</span>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>

                                    <div class="twm-sidebar-ele-filter">
                                        <!-- <h4 class="section-head-small mb-4">Job Type</h4> -->
                                        <h4 class="section-head-small mb-4">Industry</h4>
                                        <div class="input-group mb-4">
                                            <input type="text" class="form-control" placeholder="Search..."
                                                (keyup)="industrySearch($event)">
                                        </div>
                                        <ul style="max-height: 200px; overflow-y: scroll;">
                                            <div *ngFor="let item of industryList; let i = index ">
                                                <li
                                                    *ngIf="industrySearch1 == '' || (item.nameEn| lowercase).includes(industrySearch1)">
                                                    <div class=" form-check">
                                                        <input type="checkbox" class="form-check-input"
                                                            [id]="'exampleCheck1' + item.nameEn"
                                                            (change)="industryFilter(item.id)"
                                                            [checked]="this.companyFilter.industryId == item.id">
                                                        <label class="form-check-label"
                                                            for="exampleCheck1">{{item.nameEn}}</label>
                                                    </div>
                                                    <span class="twm-job-type-count"
                                                        *ngIf="industrySearch1 == '' || (item.nameEn| lowercase).includes(industrySearch1)">{{item.count}}</span>
                                                </li>

                                            </div>

                                        </ul>
                                    </div>

                                </form>

                            </div>

                            <div class="widget tw-sidebar-tags-wrap" style="display: none;">
                                <h4 class="section-head-small mb-4">Tags</h4>

                                <div class="tagcloud">
                                    <a href="job-list.html">General</a>
                                    <a href="job-list.html">Jobs </a>
                                    <a href="job-list.html">Payment</a>
                                    <a href="job-list.html">Application </a>
                                    <a href="job-list.html">Work</a>
                                    <a href="job-list.html">Recruiting</a>
                                    <a href="job-list.html">Employer</a>
                                    <a href="job-list.html">Income</a>
                                    <a href="job-list.html">Tips</a>
                                </div>
                            </div>


                        </div>
                        <div *ngIf="!isMobile" class="twm-advertisment" style="background-image:url(images/add-bg.jpg);">
                            <div class="overlay"></div>
                            <h4 class="twm-title">Looking for change?</h4>
                            <p>Get Best Matched Jobs <br> Add Resume NOW!</p>
                            <a class="site-button white" (click)="resume()">Submit</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <!--Filter Short By-->
                    <div class="product-filter-wrap d-flex justify-content-between align-items-center m-b30">
                        <span class="woocommerce-result-count-left"> Showing {{(compPerPage * (currPage)) + 1}}
                            from total of {{totalCompPerPage}}
                            employers</span>

                    </div>

                    <div class="twm-jobs-list-wrap">
                        <ul>
                            <li
                                *ngFor="let item of companyList | paginate: { itemsPerPage: compPerPage, currentPage: currPage + 1,totalItems:totalCompPerPage }">
                                <app-company-widget [company]="item"></app-company-widget>

                            </li>

                        </ul>
                        <div class="pagination-outer">
                            <div class="pagination-style1">
                                <pagination-controls (pageChange)="paginate($event)"></pagination-controls>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- OUR BLOG END -->



</div>