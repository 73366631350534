import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MasterService {
  private jobTypePath = environment.apiUrl + "Masters/" + 'jobtypes';

  private industryPath = environment.apiUrl + "Masters/" + 'industries';

  private coursePath = environment.apiUrl + "Masters/" + 'courses';

  private eventguestpath = environment.apiUrl + "Masters/" + 'EventGuests';

  private citypath = environment.apiUrl + "Masters/" + 'city/';

  private citypath1 = environment.apiUrl + "Masters/" + 'cityByCountryId?id=';

  private nationalitypath = environment.apiUrl + "Masters/" + 'nationality';

  private functionalAreapath = environment.apiUrl + "Masters/" + 'functionalareas';

  private martialStatuspath = environment.apiUrl + "Masters/martialstatus";

  private visaStatuspath = environment.apiUrl + "Masters/" + 'visastatus';

  private religionpath = environment.apiUrl + "Masters/" + 'religion';

  private countrypath = environment.apiUrl + "Masters/" + 'countries';

  private availibilitypath = environment.apiUrl + "Masters/" + 'availabilities';

  private worklevelpath = environment.apiUrl + "Masters/" + 'worklevel';

  constructor(private http: HttpClient) { }

  linkChecker(url: string) {
    return this.http.get<any>(url);
  }

  getAvailibilityAll(): Observable<any> {
    return this.http.get<any>(this.availibilitypath);
  }

  getWorkLevelAll(): Observable<any> {
    return this.http.get<any>(this.worklevelpath);
  }

  getMartialStatusAll(): Observable<any> {
    return this.http.get<any>(this.martialStatuspath);
  }
  getVisaStatusAll(): Observable<any> {
    return this.http.get<any>(this.visaStatuspath);
  }
  getReligionAll(): Observable<any> {
    return this.http.get<any>(this.religionpath);
  }
  getCountryAll(): Observable<any> {
    return this.http.get<any>(this.countrypath);
  }
  getCityByCountryId(id: Number): Observable<any> {
    return this.http.get<any>(this.citypath1 + id.toString());
  }

  getJobTypeAll(): Observable<any> {
    return this.http.get<any>(this.jobTypePath);
  }

  getJobTypeById(id: Number): Observable<any> {
    return this.http.get<any>(this.jobTypePath + 'Get/' + id);
  }

  getIndustryAll(): Observable<any> {
    return this.http.get<any>(this.industryPath);
  }

  getIndustryById(id: Number): Observable<any> {
    return this.http.get<any>(this.industryPath + 'Get/' + id);
  }

  getCourseAll(): Observable<any> {
    return this.http.get<any>(this.coursePath);
  }

  getCourseById(id: Number): Observable<any> {
    return this.http.get<any>(this.coursePath + 'Get/' + id);
  }

  getCityAll(): Observable<any> {
    return this.http.get<any>(this.citypath);
  }

  getCityById(id: Number): Observable<any> {
    return this.http.get<any>(this.citypath + 'Get/' + id);
  }

  getNationalitAll(): Observable<any> {
    return this.http.get<any>(this.nationalitypath);
  }

  getNationalitById(id: Number): Observable<any> {
    return this.http.get<any>(this.nationalitypath + 'Get/' + id);
  }

  getfunctionalAreaAll(): Observable<any> {
    return this.http.get<any>(this.functionalAreapath);
  }

  getfunctionalAreaById(id: Number): Observable<any> {
    return this.http.get<any>(this.functionalAreapath + 'Get/' + id);
  }

  getDateDiff(date: string) {
    let date_1 = new Date(date.split("T")[0]);
    // console.log(date_1);

    let date_2 = new Date();
    // console.log(date_2);

    let difference = date_2.getTime() - date_1.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays + " Days Ago";
  }

  validate(mandatoryList: Array<string>, model: any): boolean {
    let val: any = "";
    for (const key in model) {
      // console.log(model[key],key,mandatoryList);
      if (mandatoryList.includes(key)) {
        val = model[key];
        if (val == "" || val == 0 || val == null) {
          return false;
        }
      }
    }
    return true;
  }

  yearList(): number[] {
    let yearList = [];
    for (var i = 1900; i <= 2023; i++) {
      yearList.push(i);
    }
    return yearList;
  }
  monthList(): number[] {
    let monthList = [];
    for (var i = 1; i <= 12; i++) {
      monthList.push(i);
    }
    return monthList;
  }


  create(data: any): Observable<any> {
    return this.http.post<any>(this.jobTypePath + 'create-event', data);
  }

  saveResponse(data: any): Observable<any> {
    return this.http.put<any>(this.eventguestpath + 'save-response/', data);
  }

  GetGuestByEventId(id: number): Observable<any> {
    return this.http.get<any>(this.eventguestpath + 'event-guests/' + id);
  }

  GetEventById(id: number): Observable<any> {
    return this.http.get<any>(this.jobTypePath + 'by-id/' + id);
  }

  AddEventGuest(data: any): Observable<any> {
    return this.http.post<any>(this.eventguestpath + 'add-guest', data);
  }

  sendEmail(id: number): Observable<any> {
    return this.http.post<any>(
      this.eventguestpath + 'send-email?Id=' + id,
      null
    );
  }

  GetAllEvents(): Observable<any> {
    return this.http.get<any>(this.jobTypePath + 'all-event/');
  }

  GetSingleEventUserResponse(
    eventId: number,
    guestId: string
  ): Observable<any> {
    return this.http.get<any>(
      this.eventguestpath + 'event-guest/' + eventId + '/' + guestId + ''
    );
  }

  DeleteEventGuest(id: number): Observable<any> {
    return this.http.delete<any>(this.eventguestpath + 'delete/' + id);
  }
}
