import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { QuestionAnswerModel } from 'src/app/model/QuestionAnswer';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { QuestionAnswerService } from 'src/app/services/questionAnswer.service';

@Component({
  selector: 'app-job-question-answer',
  templateUrl: './job-question-answer.component.html',
  styleUrls: ['./job-question-answer.component.scss']
})
export class JobQuestionAnswerComponent implements OnInit {
  @Input() jobId: number = 0;
  questionAnswer: QuestionAnswerModel[] = [];

  constructor(private authService: AuthService,
    private tost: ToastrService,
    public loader: LoaderService,
    private questionAnsSrv: QuestionAnswerService,) {
  }
  ngOnInit(): void {
    this.getQuestionAnswer();
  }
  getQuestionAnswer() {
    this.questionAnsSrv.getByJobSeekerId(this.authService.getAuthStorage().jobseekerId,this.jobId).subscribe((data) => {
      this.questionAnswer = data;
    })
  }


}
