<app-preloader></app-preloader>
<!-- <app-loading></app-loading> -->
<div class="page-wraper">
    <app-navbar-style-one></app-navbar-style-one>
    <router-outlet></router-outlet>
    
    <app-footer-style-one *ngIf="path != '/user-profile'"></app-footer-style-one>

    <!-- HEADER END -->

    <!-- CONTENT START -->
</div>