<div class="page-content" style="transform: none;">



    <!-- OUR BLOG START -->
    <div class="section-full p-tgood  p-b90 site-bg-white" style="transform: none;">


        <app-loading *ngIf="loader.loader"></app-loading>


        <div class="container" style="transform: none;">

            <h2 class="text-center"> Jobs </h2>
            <hr />
            <div class="row" style="transform: none;">

                <div class="col-lg-4 col-md-12 rightSidebar"
                    style="position: relative; overflow: visible; box-sizing: border-box; min-height: 1px;">



                    <button *ngIf="isMobile == true" (click)="toggleFilter()" class="site-button right-mobile"><span></span>{{!show?'  Filter  ':'    Apply   '}}<span></span></button>


                    <div  *ngIf="show"  class="theiaStickySidebar"
                        style="padding-top: 0px; padding-bottom: 1px; position: static; transform: none; top: 0px; left: 186.6px;">
                        <div class="side-bar">

                            <div class="sidebar-elements search-bx">

                                <form>

                                    <!-- <div class="form-group mb-4">
                                    <div _ngcontent-sva-c15="" class="form-group city-outer-bx has-feedback">
                                        <label _ngcontent-sva-c15="">Category:</label>
                                        <div _ngcontent-sva-c15="" class="ls-inputicon-box">
                                            <div _ngcontent-sva-c15="" class="dropdown bootstrap-select wt-select-box">
                                                <select _ngcontent-sva-c15="" name="team-size" data-live-search="true" title="team-size" id="city" data-bv-field="size" class="wt-select-box selectpicker" style="display: none;">
                                                    <option *ngFor="let city of coursesList" [data-value]="city.id">{{city.nameEn}}</option>
                                                </select><div class="nice-select wt-select-box selectpicker" tabindex="0">
                                                        <span class="current"></span>
                                                        <ul class="list">
                                                            <li *ngFor="let city of coursesList" [data-value]="city.nameEn" class="option" (click)="assignFunctionalArea(city.id)">{{city.nameEn}}</li>-->
                                    <!-- <li data-value="" *ngFor="let city of cityList" (click)="assignCity(city.id)" class="option">{{city.nameEn}}</li> -->
                                    <!-- </ul>
                                                    </div>
                                                    <button _ngcontent-sva-c15="" type="button" tabindex="-1" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false" title="team-size" data-id="city" class="btn dropdown-toggle bs-placeholder btn-light">
                                                        <div _ngcontent-sva-c15="" class="filter-option">
                                                            <div _ngcontent-sva-c15="" class="filter-option-inner">
                                                                <div _ngcontent-sva-c15="" class="filter-option-inner-inner">team-size</div>
                                                            </div>
                                                        </div>
                                                    </button><div _ngcontent-sva-c15="" class="dropdown-menu"><div _ngcontent-sva-c15="" class="bs-searchbox"><input _ngcontent-sva-c15="" type="search" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-1" aria-autocomplete="list" class="form-control"></div>
                                                    <div _ngcontent-sva-c15="" role="listbox" id="bs-select-1" tabindex="-1" class="inner show">
                                                        <ul _ngcontent-sva-c15="" role="presentation" class="dropdown-menu inner show"></ul></div></div></div><mat-icon _ngcontent-sva-c15="" role="img" class="mat-icon notranslate fs-input-icon material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">translate</mat-icon>
                                                    </div>
                                        </div> -->
                                    <!-- <h4 class="section-head-small mb-4">Category</h4>
                                    <div class="dropdown bootstrap-select wt-select-bar-large"><select class="wt-select-bar-large selectpicker" data-live-search="true" data-bv-field="size">
                                        <option>All Category</option>
                                        <option>Web Designer</option>
                                        <option>Developer</option>
                                        <option>Acountant</option>
                                    </select><button type="button" tabindex="-1" class="btn dropdown-toggle btn-light" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-1" aria-haspopup="listbox" aria-expanded="false" title="All Category"><div class="filter-option"><div class="filter-option-inner"><div class="filter-option-inner-inner">All Category</div></div> </div></button><div class="dropdown-menu "><div class="bs-searchbox"><input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-1" aria-autocomplete="list"></div><div class="inner show" role="listbox" id="bs-select-1" tabindex="-1"><ul class="dropdown-menu inner show" role="presentation"></ul></div></div></div> -->


                                    <!-- </div>  -->

                                    <div class="form-group mb-4">
                                        <h4 class="section-head-small mb-4">Keyword</h4>
                                        <div class="input-group">
                                            <input type="text" [value]="this.jobFilter.jobTitle" class="form-control"
                                                placeholder="Job Title or Keyword" (keyup)="filterTitle($event)">
                                            <button class="btn" type="button"><i class="feather-search"></i></button>
                                        </div>
                                    </div>

                                    <div class="form-group mb-4">
                                        <h4 class="section-head-small mb-4">Location</h4>
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Search location"
                                                (keyup)="filterLocation($event)">
                                            <!-- <button class="btn" type="button"><i class="feather-map-pin"></i></button> -->
                                        </div>
                                        <ul style="max-height: 200px; overflow-y: scroll;">
                                            <div *ngFor="let item of cityList; let i = index " class="mt-3">
                                                <li
                                                    *ngIf="locationFilter == '' || (item.nameEn| lowercase).includes(locationFilter)">
                                                    <div class=" form-check">
                                                        <input type="checkbox" class="form-check-input"
                                                            [id]="'exampleCheck3' + item.nameEn"
                                                            (change)="locationFilter2(item.id)"
                                                            [checked]="this.jobFilter.jobLocationId == item.id">
                                                        <label class="form-check-label"
                                                            for="exampleCheck1">{{item.nameEn}}</label>
                                                    </div>
                                                    <span class="twm-job-type-count"
                                                        *ngIf="locationFilter == '' || (item.nameEn| lowercase).includes(locationFilter)">{{item.count}}</span>
                                                </li>
                                            </div>
                                        </ul>
                                    </div>

                                    <div class="twm-sidebar-ele-filter">
                                        <!-- <h4 class="section-head-small mb-4">Job Type</h4> -->
                                        <h4 class="section-head-small mb-4">Functional Area</h4>
                                        <div class="input-group mb-4">
                                            <input type="text" class="form-control" placeholder="Search..."
                                                (keyup)="filterArea($event)">
                                        </div>
                                        <ul style="max-height: 200px; overflow-y: scroll;">
                                            <div *ngFor="let item of functionalAreaList; let i = index ">
                                                <li
                                                    *ngIf="areaFilter == '' || (item.nameEn| lowercase).includes(areaFilter)">
                                                    <div class=" form-check">
                                                        <input type="checkbox" class="form-check-input"
                                                            [id]="'exampleCheck1' + item.nameEn"
                                                            (change)="functionalareaFilter(item.id)"
                                                            [checked]="this.jobFilter.functinalAreaId == item.id">
                                                        <label class="form-check-label"
                                                            for="exampleCheck1">{{item.nameEn}}</label>
                                                    </div>
                                                    <span class="twm-job-type-count"
                                                        *ngIf="areaFilter == '' || (item.nameEn| lowercase).includes(areaFilter)">{{item.count}}</span>
                                                </li>

                                            </div>

                                            <!-- <li>
                                            <div class=" form-check">
                                                <input type="checkbox" class="form-check-input" id="exampleCheck2">
                                                <label class="form-check-label" for="exampleCheck2">Full Time</label>
                                            </div>
                                            <span class="twm-job-type-count">07</span>
                                        </li> -->

                                        </ul>
                                    </div>

                                    <div class="twm-sidebar-ele-filter">
                                        <h4 class="section-head-small mb-4">Date Posts</h4>
                                        <ul>
                                            <!-- <li>
                                            <div class="form-check">
                                                <input type="radio" class="form-check-input" id="exampleradio1">
                                                <label class="form-check-label" for="exampleradio1">Last hour</label>
                                            </div>
                                        </li> -->
                                            <li>
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleradio2"
                                                        (change)="dateFilter('1')"
                                                        [checked]="this.jobFilter.postDate[0] == '1'">
                                                    <label class="form-check-label" for="exampleradio2">Last 1
                                                        days</label>
                                                </div>
                                            </li>

                                            <li>
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleradio3"
                                                        (change)="dateFilter('7')"
                                                        [checked]="this.jobFilter.postDate[0] == '7'">
                                                    <label class="form-check-label" for="exampleradio3">Last 7
                                                        days</label>
                                                </div>
                                            </li>

                                            <li>
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleradio4"
                                                        (change)="dateFilter('14')"
                                                        [checked]="this.jobFilter.postDate[0] == '14'">
                                                    <label class="form-check-label" for="exampleradio4">Last 14
                                                        days</label>
                                                </div>
                                            </li>

                                            <li>
                                                <div class="form-check">
                                                    <input type="checkbox" class="form-check-input" id="exampleradio5"
                                                        (change)="dateFilter('30')"
                                                        [checked]="this.jobFilter.postDate[0] == '30'">
                                                    <label class="form-check-label" for="exampleradio5">Last 30
                                                        days</label>
                                                </div>
                                            </li>

                                            <!-- <li>
                                            <div class="form-check">
                                                <input type="radio" class="form-check-input" id="exampleradio6">
                                                <label class="form-check-label" for="exampleradio6">All</label>
                                            </div> -->
                                            <!-- </li> -->

                                        </ul>
                                    </div>

                                    <div class="twm-sidebar-ele-filter">
                                        <h4 class="section-head-small mb-4">Type of employment</h4>
                                        <ul>
                                            <li *ngFor="let item of jobTypeList">
                                                <div class="form-check" (change)="typeOfEmp(item.id)">
                                                    <input type="checkbox" class="form-check-input" [id]="item.nameEn">
                                                    <label class="form-check-label"
                                                        for="Freelance1">{{item.nameEn}}</label>
                                                </div>
                                            </li>
                                            <!-- <li>
                                            <div class="form-check">
                                                <input type="radio" class="form-check-input" id="FullTime1">
                                                <label class="form-check-label" for="FullTime1">Full Time</label>
                                            </div>
                                        </li> -->
                                        </ul>
                                    </div>

                                </form>

                            </div>

                            <div class="widget tw-sidebar-tags-wrap" style="display: none;">
                                <h4 class="section-head-small mb-4">Tags</h4>

                                <div class="tagcloud">
                                    <a href="job-list.html">General</a>
                                    <a href="job-list.html">Jobs </a>
                                    <a href="job-list.html">Payment</a>
                                    <a href="job-list.html">Application </a>
                                    <a href="job-list.html">Work</a>
                                    <a href="job-list.html">Recruiting</a>
                                    <a href="job-list.html">Employer</a>
                                    <a href="job-list.html">Income</a>
                                    <a href="job-list.html">Tips</a>
                                </div>
                            </div>


                        </div>
                        <div class="twm-advertisment" style="background-image:url(images/add-bg.jpg);">
                            <div class="overlay"></div>
                            <h4 class="twm-title">Recruiting?</h4>
                            <p>Get Best Matched Jobs On your <br>
                                Email. Add Resume NOW!</p>
                            <a href="about-1.html" class="site-button white">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <!--Filter Short By-->
                    <div class="product-filter-wrap d-flex justify-content-between align-items-center m-b30">
                        <span class="woocommerce-result-count-left">Showing total of {{jobList.length}} jobs</span>

                        <form class="woocommerce-ordering twm-filter-select" method="get">
                            <!-- <span class="woocommerce-result-count">Short By</span> -->
                            <!-- <div class="dropdown bootstrap-select wt-select-bar-2"><select class="wt-select-bar-2 selectpicker" data-live-search="true" data-bv-field="size">
                                <option>Most Recent</option>
                                <option>Freelance</option>
                                <option>Full Time</option>
                                <option>Internship</option>
                                <option>Part Time</option>
                                <option>Temporary</option>
                            </select><button type="button" tabindex="-1" class="btn dropdown-toggle btn-light" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-2" aria-haspopup="listbox" aria-expanded="false" title="Most Recent"><div class="filter-option"><div class="filter-option-inner"><div class="filter-option-inner-inner">Most Recent</div></div> </div></button><div class="dropdown-menu "><div class="bs-searchbox"><input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-2" aria-autocomplete="list"></div><div class="inner show" role="listbox" id="bs-select-2" tabindex="-1"><ul class="dropdown-menu inner show" role="presentation"></ul></div></div></div> -->
                            <!-- <div class="dropdown bootstrap-select wt-select-bar-2"><select class="wt-select-bar-2 selectpicker" data-live-search="true" data-bv-field="size">
                                <option>Show 10</option>
                                <option>Show 20</option>
                                <option>Show 30</option>
                                <option>Show 40</option>
                                <option>Show 50</option>
                                <option>Show 60</option>
                            </select><button type="button" tabindex="-1" class="btn dropdown-toggle btn-light" data-bs-toggle="dropdown" role="combobox" aria-owns="bs-select-3" aria-haspopup="listbox" aria-expanded="false" title="Show 10"><div class="filter-option"><div class="filter-option-inner"><div class="filter-option-inner-inner">Show 10</div></div> </div></button><div class="dropdown-menu "><div class="bs-searchbox"><input type="search" class="form-control" autocomplete="off" role="combobox" aria-label="Search" aria-controls="bs-select-3" aria-autocomplete="list"></div><div class="inner show" role="listbox" id="bs-select-3" tabindex="-1"><ul class="dropdown-menu inner show" role="presentation"></ul></div></div></div> -->
                        </form>

                    </div>

                    <div class="twm-jobs-list-wrap">
                        <ul>
                            <li *ngFor="let item of jobList | paginate: { itemsPerPage: 7, currentPage: p }">
                                <div class="twm-jobs-list-style1 mb-5">
                                    <div class="twm-media">
                                        <img [src]="baseUrl + item.companyId + '.png'" alt="#">
                                        <p class="twm-job-address">{{item.company}}</p>
                                    </div>
                                    <div class="twm-mid-content">
                                        <h4>{{item.company['nameEn']}}</h4>
                                        <a [href]="'/job-detail?Id=' + item.id" class="twm-job-title">
                                            <h4>{{item.jobTitle}}<br /><span class="twm-job-post-duration">Posted
                                                    {{masterService.getDateDiff(item.creationDate)}}</span></h4>
                                        </a>
                                        <p class="twm-job-address">{{item.location}}</p>
                                        <!-- <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a> -->
                                    </div>
                                    <div class="twm-right-content">
                                        <div class="twm-jobs-category green"><span
                                                class="twm-bg-green">{{item.jobType}}</span></div>
                                        <div class="twm-jobs-amount">{{item.userDefined1}} <span>/
                                                {{item.userDefined2}}</span></div>
                                        <a [href]="'/job-detail?Id=' + item.id"
                                            class="twm-jobs-browse site-text-primary">Browse Job</a>
                                    </div>
                                </div>
                            </li>

                            <!-- <li>
                                 <div class="twm-jobs-list-style1 mb-5">
                                     <div class="twm-media">
                                         <img src="images/jobs-company/pic2.jpg" alt="#">
                                     </div>
                                     <div class="twm-mid-content">
                                         <a href="job-detail.html" class="twm-job-title">
                                             <h4>Sr. Rolling Stock Technician<span class="twm-job-post-duration">/ 15 days ago</span></h4>
                                         </a>
                                         <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                         <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                     </div>
                                     <div class="twm-right-content">
                                         <div class="twm-jobs-category green"><span class="twm-bg-brown">Intership</span></div>
                                         <div class="twm-jobs-amount">$2500 <span>/ Month</span></div>
                                         <a href="job-detail.html" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                     </div>
                                 </div>
                             </li>

                             <li>
                                 <div class="twm-jobs-list-style1 mb-5">
                                     <div class="twm-media">
                                         <img src="images/jobs-company/pic3.jpg" alt="#">
                                     </div>
                                     <div class="twm-mid-content">
                                         <a href="job-detail.html" class="twm-job-title">
                                             <h4 class="twm-job-title">IT Department Manager<span class="twm-job-post-duration"> / 6 Month ago</span></h4>
                                         </a>
                                         <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                         <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                     </div>
                                     <div class="twm-right-content">
                                         <div class="twm-jobs-category green"><span class="twm-bg-purple">Fulltime</span></div>
                                         <div class="twm-jobs-amount">$2500 <span>/ Month</span></div>
                                         <a href="job-detail.html" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                     </div>
                                 </div>
                             </li> -->

                            <!-- <li>
                                <div class="twm-jobs-list-style1 mb-5">
                                    <div class="twm-media">
                                        <img src="images/jobs-company/pic4.jpg" alt="#">
                                    </div>
                                    <div class="twm-mid-content">
                                        <a href="job-detail.html" class="twm-job-title">
                                            <h4 class="twm-job-title">Art Production Specialist   <span class="twm-job-post-duration">/ 2 days ago</span></h4>
                                        </a>
                                        <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                        <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                    </div>
                                    <div class="twm-right-content">
                                        <div class="twm-jobs-category green"><span class="twm-bg-sky">Freelancer</span></div>
                                        <div class="twm-jobs-amount">$3000 <span>/ Month</span></div>
                                        <a href="job-detail.html" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                    </div>
                                </div>
                             </li> -->

                            <!-- <li>
                                <div class="twm-jobs-list-style1 mb-5">
                                    <div class="twm-media">
                                        <img src="images/jobs-company/pic1.jpg" alt="#">
                                    </div>
                                    <div class="twm-mid-content">
                                        <a href="job-detail.html" class="twm-job-title">
                                            <h4>Senior Web Designer<span class="twm-job-post-duration">/ 1 days ago</span></h4>
                                        </a>
                                        <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                        <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                    </div>
                                    <div class="twm-right-content">
                                        <div class="twm-jobs-category green"><span class="twm-bg-green">New</span></div>
                                        <div class="twm-jobs-amount">$2000 <span>/ Month</span></div>
                                        <a href="job-detail.html" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                    </div>
                                </div>
                            </li> -->

                            <!-- <li>
                                <div class="twm-jobs-list-style1 mb-5">
                                    <div class="twm-media">
                                        <img src="images/jobs-company/pic2.jpg" alt="#">
                                    </div>
                                    <div class="twm-mid-content">
                                        <a href="job-detail.html" class="twm-job-title">
                                            <h4>Sr. Rolling Stock Technician<span class="twm-job-post-duration">/ 15 days ago</span></h4>
                                        </a>
                                        <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                        <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                    </div>
                                    <div class="twm-right-content">
                                        <div class="twm-jobs-category green"><span class="twm-bg-brown">Intership</span></div>
                                        <div class="twm-jobs-amount">$2000 <span>/ Month</span></div>
                                        <a href="job-detail.html" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="twm-jobs-list-style1 mb-5">
                                    <div class="twm-media">
                                        <img src="images/jobs-company/pic3.jpg" alt="#">
                                    </div>
                                    <div class="twm-mid-content">
                                        <a href="job-detail.html" class="twm-job-title">
                                            <h4 class="twm-job-title">IT Department Manager<span class="twm-job-post-duration"> / 6 Month ago</span></h4>
                                        </a>
                                        <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                        <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                    </div>
                                    <div class="twm-right-content">
                                        <div class="twm-jobs-category green"><span class="twm-bg-purple">Fulltime</span></div>
                                        <div class="twm-jobs-amount">$1800 <span>/ Month</span></div>
                                        <a href="job-detail.html" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="twm-jobs-list-style1 mb-5">
                                    <div class="twm-media">
                                        <img src="images/jobs-company/pic4.jpg" alt="#">
                                    </div>
                                    <div class="twm-mid-content">
                                        <a href="job-detail.html" class="twm-job-title">
                                            <h4 class="twm-job-title">Art Production Specialist   <span class="twm-job-post-duration">/ 2 days ago</span></h4>
                                        </a>
                                        <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                        <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                    </div>
                                    <div class="twm-right-content">
                                        <div class="twm-jobs-category green"><span class="twm-bg-sky">Freelancer</span></div>
                                        <div class="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                        <a href="job-detail.html" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="twm-jobs-list-style1 mb-5">
                                    <div class="twm-media">
                                        <img src="images/jobs-company/pic5.jpg" alt="#">
                                    </div>
                                    <div class="twm-mid-content">
                                        <a href="job-detail.html" class="twm-job-title">
                                            <h4 class="twm-job-title">Recreation &amp; Fitness Worker   <span class="twm-job-post-duration">/ 1 days ago</span></h4>
                                        </a>
                                        <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                        <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                    </div>
                                    <div class="twm-right-content">
                                        <div class="twm-jobs-category green"><span class="twm-bg-golden">Temporary</span></div>
                                        <div class="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                        <a href="job-detail.html" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                    </div>
                                </div>
                            </li>

                            <li>
                               <div class="twm-jobs-list-style1 mb-5">
                                   <div class="twm-media">
                                       <img src="images/jobs-company/pic1.jpg" alt="#">
                                   </div>
                                   <div class="twm-mid-content">
                                       <a href="job-detail.html" class="twm-job-title">
                                           <h4>Senior Web Designer<span class="twm-job-post-duration">/ 1 days ago</span></h4>
                                       </a>
                                       <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                       <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                   </div>
                                   <div class="twm-right-content">
                                       <div class="twm-jobs-category green"><span class="twm-bg-green">New</span></div>
                                       <div class="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                       <a href="job-detail.html" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                   </div>
                               </div>
                            </li>

                            <li>
                               <div class="twm-jobs-list-style1 mb-5">
                                   <div class="twm-media">
                                       <img src="images/jobs-company/pic2.jpg" alt="#">
                                   </div>
                                   <div class="twm-mid-content">
                                       <a href="job-detail.html" class="twm-job-title">
                                           <h4>Sr. Rolling Stock Technician<span class="twm-job-post-duration">/ 15 days ago</span></h4>
                                       </a>
                                       <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                       <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                   </div>
                                   <div class="twm-right-content">
                                       <div class="twm-jobs-category green"><span class="twm-bg-brown">Intership</span></div>
                                       <div class="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                       <a href="job-detail.html" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                   </div>
                               </div>
                            </li>

                            <li>
                               <div class="twm-jobs-list-style1 mb-5">
                                   <div class="twm-media">
                                       <img src="images/jobs-company/pic3.jpg" alt="#">
                                   </div>
                                   <div class="twm-mid-content">
                                       <a href="job-detail.html" class="twm-job-title">
                                           <h4 class="twm-job-title">IT Department Manager<span class="twm-job-post-duration"> / 6 Month ago</span></h4>
                                       </a>
                                       <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                       <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                   </div>
                                   <div class="twm-right-content">
                                       <div class="twm-jobs-category green"><span class="twm-bg-purple">Fulltime</span></div>
                                       <div class="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                       <a href="job-detail.html" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                   </div>
                               </div>
                            </li>

                            <li>
                               <div class="twm-jobs-list-style1 mb-5">
                                   <div class="twm-media">
                                       <img src="images/jobs-company/pic4.jpg" alt="#">
                                   </div>
                                   <div class="twm-mid-content">
                                       <a href="job-detail.html" class="twm-job-title">
                                           <h4 class="twm-job-title">Art Production Specialist   <span class="twm-job-post-duration">/ 2 days ago</span></h4>
                                       </a>
                                       <p class="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                       <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                   </div>
                                   <div class="twm-right-content">
                                       <div class="twm-jobs-category green"><span class="twm-bg-sky">Freelancer</span></div>
                                       <div class="twm-jobs-amount">$1000 <span>/ Month</span></div>
                                       <a href="job-detail.html" class="twm-jobs-browse site-text-primary">Browse Job</a>
                                   </div>
                               </div>
                            </li> -->
                        </ul>
                        <div class="pagination-outer">
                            <div class="pagination-style1">
                                <pagination-controls (pageChange)="paginate($event)"></pagination-controls>

                            </div>
                        </div>
                    </div>

                    <!-- <div class="pagination-outer">
                        <div class="pagination-style1">
                            <ul class="clearfix">
                                <li class="prev"><a href="javascript:;"><span> <i class="fa fa-angle-left"></i> </span></a></li>
                                <li><a href="javascript:;">1</a></li>
                                <li class="active"><a href="javascript:;">2</a></li>
                                <li><a href="javascript:;">3</a></li>
                                <li><a class="javascript:;" href="javascript:;"><i class="fa fa-ellipsis-h"></i></a></li>
                                <li><a href="javascript:;">5</a></li>
                                <li class="next"><a href="javascript:;"><span> <i class="fa fa-angle-right"></i> </span></a></li>
                            </ul>
                        </div>
                    </div> -->

                </div>

            </div>
        </div>
    </div>
    <!-- OUR BLOG END -->



</div>