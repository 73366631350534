import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class QuestionAnswerService {
    private jobQuestionpath = environment.apiUrl + 'QuestionAnswer/';
    constructor(private http: HttpClient) { }

    create(data: any): Observable<any> {
        return this.http.post(this.jobQuestionpath + "Create", data);
    }
    createMultiple(data: any): Observable<any> {
        return this.http.post(this.jobQuestionpath + "CreateMultiple", data);
    }
    getByJobSeekerId(jobSeekerId: string,jobId:Number): Observable<any> {
        return this.http.get(this.jobQuestionpath + "ByJobSeekerId?jobSeekerId=" + jobSeekerId + "&jobId=" + jobId);
    }
    getById(id: string): Observable<any> {
        return this.http.get(this.jobQuestionpath + "ById?id=" + id);
    }
}
