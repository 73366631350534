import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { MessageService } from './services/observables.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    IsAuthenticated: boolean;
    active: string
    type: string;
    path: string;


    constructor(private router: Router, private authService: AuthService, private msgService: MessageService) {
        // this.IsAuthenticated = authService.isAuth();
        // // if(!this.IsAuthenticated){
        // //     router.navigateByUrl("/login")
        // // }
        // console.log(this.authService.getAuthStorage());
        // if(window.location.pathname != "/" && window.location.pathname != "view-job"){
        //     if(!this.IsAuthenticated && !window.location.pathname.includes("signup")){
        //         window.location.href = "/signup";
        //     }
        // }
        this.type = authService.getLoginType();
        // console.log(this.type);
        // if(this.type == "employer" && window.location.pathname == "/"){
        //     window.location.href = '/employer-dashboard'
        // }
    }


    ngOnInit() {
        this.router.events
            .subscribe(
                (event: NavigationStart) => {
                    if (event instanceof NavigationStart) {
                        this.path = event.url
                    }
                });
        this.recallJsFuntions();
    }
    ngAfterContentInit(): void {
        this.listenMobileEvent();

    }
    listenMobileEvent() {
        console.log("sending msg listening");
        window.addEventListener("flutterInAppWebViewPlatformReady", function (event) {
            (window as any).flutter_inappwebview.callHandler('handlerFoo')
                .then(function (result) {
                    // print to the console the data coming
                    // from the Flutter side.
                    console.log(JSON.stringify(result), "fomr mobile");
                    if (result["isMobile"] == 'true') {
                        if (localStorage.getItem('isMobile') != 'true') {
                            localStorage.setItem('isMobile', 'true');

                            window.location.reload();
                        }
                        localStorage.setItem('isMobile', 'true');

                        // this.msgService.sendMessage("isMobile");
                        // this.sendMsg();
                        // this.router.navigateByUrl("/");
                    }

                    (window as any).flutter_inappwebview
                        .callHandler('handlerFooWithArgs', 1, true, ['bar', 5], { foo: 'baz' }, result);
                });
        });
    }
    sendMsg() {
        console.log("send msg");
        this.msgService.sendMessage("isMobile");
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.loader').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                $('.loader').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    toggleActive(classS: string) {
        console.log(classS);
        this.active = classS;
    }
}