import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BlogService {
    private blogpath = environment.apiUrl + 'Blogs/';

    constructor(private http: HttpClient) { }

    getAll(): Observable<any> {
        return this.http.get<any>(this.blogpath + 'all');
    }

    getById(id: number): Observable<any> {
        return this.http.get<any>(this.blogpath + `ById?id=${id}`);
    }
}
