import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { Router } from 'express';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthStorage } from 'src/app/model/AuthStorage';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Output() toggle = new EventEmitter<string>();;

  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private tost: ToastrService,
    private spinner: NgxSpinnerService,
    public loader: LoaderService,
    private router: Router
  ) {

    this.form = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });

  }

  ngOnInit(): void {
    this.loader.loader = false;
  }
  login() {
    this.spinner.show();
    this.authService
      .login(this.form.value)
      .subscribe((data: AuthStorage) => {
        console.log(data);
        if (data.companyId != null && data.companyId != undefined && data.companyId != 0) {
          data.type = "employer";
          data.email = data.username;
          this.tost.error("Either email or password is wrong");
          return;
        } else {
          data.type = "user";
        }
        this.authService.saveAuthStorage(data);
        this.authService.saveToken(data);
        console.log(this.authService.getAuthStorage());
        // window.location.href = '/home'
        this.spinner.hide();
        this.tost.success("Successfully loggedin");
        this.router.navigateByUrl('/user-profile');
        // window.location.href = "/user-profile"
        if (data.type == "employer") {
          // window.location.href = "/employer-dashboard";
        } else {
        }
      });
  }
  get username() {
    return this.form.get('username');
  }

  get password() {
    return this.form.get('password');
  }

  toggle1() {
    this.toggle.emit("signup");
  }

}
