import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFromCount'
})
export class ListFromCountPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): number[] {
    let list = [];
    for (let i = 0; i < value; i++) {
      list.push(i);
    }
    return list;
  }

}
