import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JobApplyModel } from 'src/app/model/JobApply';
import { JobFilterModel } from 'src/app/model/JobFilter';
import { JobQuestionModel } from 'src/app/model/JobQuestion';
import { JobsModel } from 'src/app/model/Jobs';
import { AuthService } from 'src/app/services/auth.service';
import { JobService } from 'src/app/services/job.service';
import { JobApplyService } from 'src/app/services/jobApply.service';
import { JobQuestionService } from 'src/app/services/jobQuestion.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-job-detail',
    templateUrl: './job-detail.component.html',
    styleUrls: ['./job-detail.component.scss'],
})
export class JobDetailComponent implements OnInit {
    jobId: Number = null;
    baseUrl: string = environment.apiUrl + 'ProfilePhoto/';
    applied: boolean = false;
    error: boolean = false;
    skillList: Array<string> = [];

    jobList: Array<JobsModel> = [];

    question: JobQuestionModel = {
        id: 0,
        jobId: 0,
        question: '',
        userDefined1: ''
    }
    questionList: JobQuestionModel[] = [];

    jobApply: JobApplyModel = {
        id: 0,
        jobId: 0,
        jobSeekerId: 0,
        creationDate: '',
        jobSeeker: undefined,
        job: undefined,
    };

    job: JobsModel = {
        id: 0,
        companyId: 0,
        jobTitle: '',
        experieance: '',
        jobTypeId: 0,
        jobLocationId: 0,
        nationalityId: 0,
        gender: '',
        totalNoOfVacancy: 0,
        functinalAreaId: 0,
        jobDescription: '',
        creationDate: '',
        jobViews: 0,
        jobType: null,
        jobLocation: null,
        applicant: 0,
        functinalArea: null,
        nationality: null,
        company: null,
        userDefined1: '',
        userDefined2: '',
        userDefined3: '',
        userDefined4: '',
        countryId: 0,
        country: "",
        keySkill: ''
    };

    jobFilter: JobFilterModel = {
        jobTypeId: [],
        functinalAreaId: [],
        jobLocationId: [],
        experience: [],
        postDate: [],
        companyId: [],
        jobTitle: "",
        sortBy: "",
        jobSeekerId: 0,
        countPerPage: 6,
        page: 0
    }
    constructor(
        private jobService: JobService,
        public masterService: MasterService,
        private authService: AuthService,
        private tost: ToastrService,
        private jobApplyService: JobApplyService,
        public loader: LoaderService,
        private jobSrv: JobService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getJobId();

    }

    updateJobView(id: Number) {
        if (!this.jobApplyService.hasViewedJob(id)) {
            this.jobService.updateJobView(id).subscribe(data => {
                if (data["isSuccess"]) {
                    this.jobApplyService.saveJobViewId(id);
                }
            });
        }
    }

    getJobId() {
        var urlString = window.location.href;
        var url = new URL(urlString);
        var id = url.searchParams.get('Id');
        if (id != null && id != undefined && id != '') {
            this.jobId = parseInt(id);
            if (this.authService.getAuthStorage().jobseekerId != null && this.authService.getAuthStorage().jobseekerId != 0) {
                this.jobApplyService.checkJobById(this.jobId, this.authService.getAuthStorage().jobseekerId).subscribe(job => {
                    this.applied = job;
                })
            }
            this.getJob();
        }
    }

    getJob() {
        this.jobService.getById(this.jobId).subscribe((data: any) => {
            this.job = data[0];
            this.jobFilter.functinalAreaId = [data[0].functinalAreaId];
            this.jobFilter.jobTypeId = [data[0].jobTypeId];
            this.getJobList();
            this.updateJobView(this.job.id);
            if (this.job.keySkill != null) {
                if (this.job.keySkill.includes(",")) {
                    this.skillList = this.job.keySkill.split(",");
                } else {
                    if (!["", ",", " "].includes(this.job.keySkill)) {
                        this.skillList = [this.job.keySkill];
                    }
                }
            }
        });
    }

    applyJob() {
        if (this.authService.isAuthenticated()) {
            this.jobApply.jobId = this.jobId;
            this.jobApply.jobSeekerId =
                this.authService.getAuthStorage().jobseekerId;
            if (this.jobApply.jobSeekerId == null || this.jobApply.jobSeekerId == 0) {
          this.router.navigateByUrl("/login")
                
                // window.location.href = "/login"
            }
            this.jobApplyService.create(this.jobApply).subscribe((data: any) => {
                this.tost.success('Job applied successfully');
                $("#sign_up_popup").modal('hide');
                this.loader.loader = false;
                this.getJobId();
                console.log("sdnksdjkjksdjksd")
            });
        }
        else {
          this.router.navigateByUrl("/login")

            // window.location.href = "/login";
            this.tost.warning('Please Login');
        }
    }
    imageError() {
        this.error = true;
    }

    getJobList() {
        this.loader.loader = true;
        this.jobSrv.getByFilter(this.jobFilter).subscribe((data) => {
            data = data.reverse()
            this.jobList = data;
            this.loader.loader = false;
        });
    }

    imageErrorComp(i:number) {
        this.jobList[i].userDefined4 = "true";
      }

      urlChange(url: string, item) {
        try {
            let readPath = "" + (item.jobTitle as any).toString().replaceAll(" ", "-").toLowerCase() + "-in-company-" + item?.company['nameEn'].replaceAll(" ", "-").toLowerCase() + "-" + "at-location" + "-" + item.jobLocation.replaceAll(" ", "-").toLowerCase() + "-" + item?.country?.nameEn.replaceAll(" ", "-").toLowerCase();
            this.router.navigate(['/job-detail'], { queryParams: { Id: item.id, job: readPath } });
           
        //   url = url + "&job=" + (item.jobTitle as any).toString().replaceAll(" ", "-").toLowerCase() + "-in-company-" + item?.company['nameEn'].replaceAll(" ", "-").toLowerCase() + "-" + "at-location" + "-" + item.jobLocation.replaceAll(" ", "-").toLowerCase() + "-" + item?.country?.nameEn.replaceAll(" ", "-").toLowerCase();
        //   return url;
        } catch (e) {
            console.log(e);
        //   return url;
        }
      }
      urlChangeComp(url: string) {
        try {
          url = (url as any).toString().replaceAll(" ", "-").toLowerCase();
          return url;
        } catch (e) {
          return url;
        }
      }
    
}
