import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-blog-details',
    templateUrl: './blog-details.component.html',
    styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
    blogDetail: any;
    Id: number;
    baseUrl: string = environment.blogImg + "BlogsPic/";
    currentYear: string = new Date().getFullYear().toString()

    constructor(
        private route: ActivatedRoute,
        private blogService: BlogService
    ) { }

    ngOnInit(): void {

        this.route.params.subscribe((params) => {
            this.Id = params['id'];
        })
        this.blogService.getById(this.Id).subscribe((response) => {
            this.blogDetail = response;
            console.log(response)
        });

    }

}
