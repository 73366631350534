import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { CandidatesDetailsComponent } from './components/pages/candidates-details/candidates-details.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { JobDetailComponent } from './components/pages/job-detail/job-detail.component';
import { LoginComponent } from './components/pages/login/login.component';
import { UserProfileComponent } from './components/pages/user-profile/user-profile.component';
import { ViewJobComponent } from './components/pages/view-job/view-job.component';
import { AuthGuard } from './services/auth.guard';
import { SignupComponent } from './components/pages/signup/signup.component';
import { EmployersComponent } from './components/pages/employers/employers.component';
import { ViewCompanyComponent } from './components/pages/view-company/view-company.component';
import { ViewIndCompanyComponent } from './components/pages/view-ind-company/view-ind-company.component';
import { FogetPwdComponent } from './components/pages/foget-pwd/foget-pwd.component';
import { ResetPwdComponent } from './components/pages/reset-pwd/reset-pwd.component';
import { ChangePwdComponent } from './components/pages/change-pwd/change-pwd.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';


const routes: Routes = [
    { path: '', component: HomeThreeComponent },
    { path: 'home-three', component: HomeThreeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'about', component: AboutComponent },
    { path: 'candidate-details', component: CandidatesDetailsComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'blog-details/:id', component: BlogDetailsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'job-detail', component: JobDetailComponent },
    { path: 'view-job', component: ViewJobComponent },
    { path: 'view-company', component: ViewCompanyComponent },
    { path: 'view-ind-company', component: ViewIndCompanyComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'user-profile', component: UserProfileComponent, canActivate: [AuthGuard] },
    { path: 'employers', component: EmployersComponent },
    { path: 'forget-pwd', component: FogetPwdComponent },
    { path: 'reset-pwd', component: ResetPwdComponent },
    { path: 'contact-us', component: ContactComponent },
    { path: 'about', component: AboutComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'change-pwd', component: ChangePwdComponent, canActivate: [AuthGuard] },
    { path: '**', component: ErrorComponent }, // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }