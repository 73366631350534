import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class JobQuestionService {
    private jobQuestionpath = environment.apiUrl + 'JobQuestion/';
    constructor(private http: HttpClient) { }

    getByJobId(jobId: string): Observable<any> {
        return this.http.get(this.jobQuestionpath + "ByJobId?jobId=" + jobId);
    }
    getById(id: string): Observable<any> {
        return this.http.get(this.jobQuestionpath + "ById?id=" + id);
    }
}
