<div class="modal-dialog modal-dialog-centered modal-xl">
    <app-loading *ngIf="loader.loader"></app-loading>
    <div class="modal-content">
        <div>
            <div class="modal-header">
                <h4 class="modal-title" id="sign_up_popupLabel">
                    Employer Question
                </h4>
                <!-- <p>Save and get access to all the features of Jobzilla</p> -->
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body" *ngIf="page == 0">
                <div class="twm-tabs-style-2">
                    <div class="row">
                        <div class="col-12 col-md-6">

                            <div class="twm-candi-self-info-2 mt-1">
                                <div class="twm-candi-self-bottom flex">
                                    <embed height="331px"
                                        *ngIf="resumePath != '' && resumePath != null && resumePath != undefined" [src]="
                                        resumePath" type="application/pdf" />
                                    <div class="pdf-btn-wrapper mt-3">
                                        <a *ngIf="resumePath != '' && resumePath != null" target="_blank"
                                            [href]="resumePath" class="site-button button-sm">Preview
                                            CV</a>
                                        <p class="mandatory button-sm" target="_blank"
                                            *ngIf="resumePath == '' || resumePath == null">
                                            No CV Uploaded</p>


                                        <div class="upload-btn-wrapper ms-5">
                                            <!-- <div id="upload-image-grid"></div> -->
                                            <a class="site-button secondry button-sm">Change CV</a>
                                            <input type="file" name="myfile" id="" accept=".pdf"
                                                (change)="handleUpload($event)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">

                            <div class="cabdidate-de-info mt-1">
                                <div class="twm-candi-self-info-2 flex">
                                    <div class="twm-candi-self-bottom">
                                        <embed height="331px"
                                            *ngIf="coverLetterPath != '' && coverLetterPath != null && coverLetterPath != undefined"
                                            [src]="
                                            coverLetterPath" type="application/pdf" />
                                        <div class="pdf-btn-wrapper mt-3">
                                            <a [href]="coverLetterPath" class="site-button button-sm" target="_blank"
                                                *ngIf="coverLetterPath != '' && coverLetterPath != null">Preview
                                                Cover
                                                Letter</a>
                                            <p class="mandatory button-sm" target="_blank"
                                                *ngIf="coverLetterPath == '' || coverLetterPath == null">
                                                No Cover Letter Uploaded</p>

                                            <div class="upload-btn-wrapper ms-3">
                                                <!-- <div id="upload-image-grid"></div> -->
                                                <a class="site-button secondry button-sm">Change Cover
                                                    Letter</a>
                                                <input type="file" name="myfile" id="" accept=".pdf" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-body" *ngIf="page == 1">
                <div class="twm-tabs-style-2">
                    <div class="row">
                        <div class="col-12" *ngFor="let item of questionAnswerList">
                            <div class="form-group">
                                <label>{{item.question.question}} <span class="mandatory"
                                        *ngIf="item.question.userDefined1=='active'">*</span></label>

                                <div class="ls-inputicon-box">
                                    <input type="text" class="form-control" placeholder="Answer"
                                        [(ngModel)]="item.answer" />
                                    <span class="mandatory" *ngIf="
                                    item.question.userDefined1=='active'  && item.answer == ''  && submit ">This field
                                        is
                                        requied</span>
                                    <i class="fs-input-icon fas fa-comments"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer" *ngIf="!multiPage">
            <button class="site-button " (click)="apply()">Apply</button>
        </div>
        <div class="modal-footer" *ngIf="multiPage">
            <button class="site-button " *ngIf="page == 0" (click)="next()">Question</button>
            <button class="site-button " *ngIf="page == 1" (click)="prev()">Prev</button>
            <button class="site-button " *ngIf="page == 1" (click)="apply()">Apply</button>
        </div>
    </div>
</div>