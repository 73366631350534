<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Page Title -->
<div>
    <!-- Add this HTML code to your page -->
    <section class="blog-area pt-100 pb-60;" style="background-color: #3271d3;">
        <div class="container">
            <div class="row">
                <div class="text-center">
                    <div class="content">
                        <h2 class="text-white">Blog Section</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<hr>

<!-- BLOG CARD START-->
<div class="section-full p-t50 p-b50 twm-for-employee-area site-bg-white">
    <div class="d-flex flex-column justify-content-center text-center">
        <h3 class="font-weight-bold">FROM OUR BLOGS</h3>
        <p class="text-muted">This blog gives job tips for job seekers and career advice for employees preparing
            for interviews and getting a better job
        </p>
    </div>
    <div class="p-t50 container">
        <div class="row">
            <div *ngFor="let blog of blogList" class="col-md-4 mb-5">
                <div class="shadow d-flex flex-column"
                    style="height: 100%; display: flex; flex-direction: column; justify-content: space-between;border-radius: 9px; overflow: hidden;">
                    <div style="height: 240px; object-fit: cover;">
                        <img class="blog-img" [src]="baseUrl + blog.userDefined1" alt="Card Image">
                    </div>
                    <div class="px-3 py-2">
                        <p class="advice-color mb-1">Informational blog posts</p>
                        <h5 class="line-clamp-2">{{ blog?.headingEn }}</h5>
                        <p class="card-subtitle mb-2 text-muted">BY <span style="color:#1969d9">MAHAD JOBS</span> |
                            {{currentYear}}</p>
                        <p class="card-text line-clamp-3" [innerHtml]="blog?.descriptions"></p>
                    </div>
                    <div class="px-3 py-2">
                        <button type="button" (click)="handelCardClick(blog?.id)" class="site-button w-100 rounded-3 ">
                            View Full Article
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- BLOG CARD END -->

<!-- <div *ngFor="let blog of blogList">
    <div
        style="padding: 20px;padding-bottom: 0px; display: flex;justify-content: center;flex-direction: column; align-items: center;">
        <div class="card">
            <div>
                <div class="row no-gutters">
                    <div class="col-md-12" (click)="handelCardClick(blog?.id)">
                        <div class="card-body">
                            <div class="img">
                                <img [src]="baseUrl + blog.userDefined1" />
                            </div>
                            <a class="card-link bg-warning">More</a>
                            <h5 class="card-title">{{ blog?.headingEn }}
                            </h5>
                            <p class="card-text" [innerHtml]="blog?.descriptions">
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Page Title -->

<!-- Blog -->
<section class="blog-area  pb-70">
    <div class="container">
        <div class="row">
        </div>
    </div>
</section>
<!-- End Blog -->

<!-- Footer -->
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- End Footer -->
