import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private compnaypath = environment.apiUrl + 'Company/';

  private eventguestpath = environment.apiUrl + 'EventGuests/';

  constructor(private http: HttpClient) { }

  create(data: any): Observable<any> {
    return this.http.post<any>(this.compnaypath + 'create', data);
  }

  update(data: any): Observable<any> {
    return this.http.put<any>(this.compnaypath + 'update', data);
  }

  getById(id: Number): Observable<any> {
    return this.http.get<any>(this.compnaypath + 'Get/' + id);
  }

  getByIdWithDetail(id: Number): Observable<any> {
    return this.http.get<any>(this.compnaypath + 'by-id?id=' + id);
  }

  getAll(): Observable<any> {
    return this.http.get<any>(this.compnaypath + 'all');
  }

  getByFilter(filter: any): Observable<any> {
    return this.http.post<any>(this.compnaypath + 'filter', filter);
  }
  getCountByFilter(filter: any): Observable<number> {
    return this.http.post<number>(this.compnaypath + 'filterCount', filter);
  }
  getByCompanyId(id: Number): Observable<any> {
    return this.http.get<any>(this.compnaypath + 'companyId?companyId=' + id);
  }

  saveResponse(data: any): Observable<any> {
    return this.http.put<any>(this.eventguestpath + 'save-response/', data);
  }

  GetGuestByEventId(id: number): Observable<any> {
    return this.http.get<any>(this.eventguestpath + 'event-guests/' + id);
  }

  AddEventGuest(data: any): Observable<any> {
    return this.http.post<any>(this.eventguestpath + 'add-guest', data);
  }

  sendEmail(id: number): Observable<any> {
    return this.http.post<any>(
      this.eventguestpath + 'send-email?Id=' + id,
      null
    );
  }


  GetSingleEventUserResponse(
    eventId: number,
    guestId: string
  ): Observable<any> {
    return this.http.get<any>(
      this.eventguestpath + 'event-guest/' + eventId + '/' + guestId + ''
    );
  }

  DeleteEventGuest(id: number): Observable<any> {
    return this.http.delete<any>(this.eventguestpath + 'delete/' + id);
  }
}
