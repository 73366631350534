<div class="we-disp mt-3">
    <h4 class="twm-s-title">
        Skill Certification
    </h4>
    <button type="submit" (click)="newCertification()" class="btn site-button action" data-bs-target="#sign_up_popup3"
        data-bs-toggle="modal" data-bs-dismiss="modal">
        <mat-icon>add</mat-icon>
    </button>
</div>
<div class="twm-timing-list-wrap row">
    <div class="twm-timing-list col-4" *ngFor="
            let item of jobSeekerSkillCertificationList
        ">
        <div class="twm-time-list-date" *ngIf=" item.experienceYear != 2099">
            Expiry: {{ item.experienceMonth }}-{{ item.experienceYear }}
        </div>
        <div class="twm-time-list-date" *ngIf=" item.experienceYear == 2099">
            This Certificate does not expire
        </div>
        <div class="twm-time-list-title">
            {{ item.companyName }}
            <button type="submit" class="btn site-button action" (click)="
            getSkillCertificationById(
                        item.id
                    )
                " data-bs-target="#sign_up_popup3" data-bs-toggle="modal" data-bs-dismiss="modal">
                <mat-icon>edit</mat-icon>
            </button>
            <button (click)="
                    deleteSkillCertification(
                        item.id
                    )
                " type="submit" class="ms-3 btn site-button action mandatory">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <div class="twm-time-list-position">
            {{ item.designation }}
        </div>
        <div class="twm-time-list-discription">
            <p>
                {{
                item.name
                }}
            </p>
        </div>
    </div>

</div>
<div class="modal fade twm-sign-up" id="sign_up_popup3" aria-hidden="true" aria-labelledby="sign_up_popupLabel3"
    tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div>
                <div class="modal-header">
                    <h4 class="modal-title" id="sign_up_popupLabel3">
                        Certification Detail
                    </h4>
                    <!-- <p>Save and get access to all the features of Jobzilla</p> -->
                    <button type="button" class="btn-close close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="twm-tabs-style-2">
                        <div class="row">
                            <div class="col-xl-4 col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>Name:</label>
                                    <div class="ls-inputicon-box">
                                        <input type="text" class="form-control" placeholder="AWS" [(ngModel)]="
                                                                    jobSeekerSkillCertification.name
                                                                " />
                                        <span class="mandatory" *ngIf="
                                                                    jobSeekerSkillCertification.name ==
                                                                        '' &&
                                                                    submitEmployment
                                                                ">This field is
                                            requied</span>
                                        <i class="fs-input-icon fas fa-at"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-12">
                                <div class="form-group">
                                    <label>No Expiry:</label>
                                    <div class="ls-inputicon-box">
                                        <input type="checkbox" [(ngModel)]="noExpiry"
                                            />

                                      
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-12" *ngIf="!noExpiry">
                                <div class="form-group">
                                    <label>Expiry Year:</label>
                                    <div class="ls-inputicon-box">
                                        <ngx-select-dropdown [(ngModel)]=" jobSeekerSkillCertification.experienceYear"
                                            [instanceId]="'instance21'" [multiple]="false" [config]="configStatic"
                                            [options]="yearList"
                                            ></ngx-select-dropdown>

                                        <span class="mandatory" *ngIf="
                                                                    jobSeekerSkillCertification.experienceYear ==
                                                                        0 &&
                                                                    submitEmployment
                                                                ">This field is
                                            requied</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-12"  *ngIf="!noExpiry">
                                <div class="form-group">
                                    <label>Expiry Month:</label>
                                    <div class="ls-inputicon-box">
                                        <ngx-select-dropdown [(ngModel)]=" jobSeekerSkillCertification.experienceMonth"
                                            [instanceId]="'instance21'" [multiple]="false" [config]="configStatic"
                                            [options]="monthList"></ngx-select-dropdown>

                                        <span class="mandatory" *ngIf="
                                                                    jobSeekerSkillCertification.experienceMonth ==
                                                                        0 &&
                                                                    submitEmployment
                                                                ">This field is
                                            requied</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 mt-3">
                                <button type="submit" class="site-button" (click)="
                                                            saveSkillCertification()
                                                        ">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="modal-footer">
                                <span class="modal-f-title">Login or Sign up with</span>
                                <ul class="twm-modal-social">
                                    <li><a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="javascript" class="twitter-clr"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="javascript" class="google-clr"><i class="fab fa-google"></i></a></li>
                                </ul>
                            </div> -->
            </div>
        </div>
    </div>
</div>