import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RegisterModel } from 'src/app/model/RegisterModel';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { UploadMediaService } from 'src/app/services/uploadMedia.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  constructor(
    private masterService: MasterService,
    private fb: FormBuilder,
    private tost: ToastrService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private uploadMedia: UploadMediaService,
    public loader: LoaderService,
    private routerLink: Router

  ) {
    this.loader.loader = false;
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  filePdf: any = null;
  termCond: boolean = false;
  isSubmit: boolean = false;
  resumeFile: string = "";
  user: RegisterModel = {
    fullNameEn: "",
    fullNameAr: "",
    userName: "",
    cvheadline: "",
    currentCity: "",
    mobileNo: "",
    email: "",
    password: "",
    resume: "",
  }
  userRegister = [
    "fullNameEn",
    "fullNameAr",
    "userName",
    "mobileNo",
    "currentCity",
    "email",
    "password",
    "resume",
  ];

  uploadPdf(event: any) {
    this.filePdf = event.target.files[0];
    this.resumeFile = this.filePdf["name"];
    this.handleUploadPdf();
  }
  handleUploadPdf() {
    const file = this.filePdf;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      console.log(reader.result);
      let type: string = reader.result.toString().split(";base64,")[0].split("/")[1];
      var base64: string = reader.result.toString().split(";base64,")[1];
      this.user.resume = base64;
    };
  }

  onSighUp() {
    if (!this.termCond) {
      this.tost.warning("Please accept term conditions");
      return;
    }
    if (!this.user.email.includes("@") || !this.user.email.includes(".")) {
      this.loader.loader = false;
      this.isSubmit = true;
      return;
    }
    this.loader.loader = true;
    this.user.userName = this.user.email;
    this.user.mobileNo = this.user.mobileNo?.toString();
    if (this.masterService.validate(this.userRegister, this.user)) {
      this.spinner.show();
      this.authService.register(this.user)
        .subscribe((data) => {
          this.tost.success("Successfully registered");
          this.user = {
            fullNameEn: "",
            fullNameAr: "",
            userName: "",
            cvheadline: "",
            currentCity: "",
            mobileNo: "",
            email: "",
            password: "",
            resume: "",
          }
          this.loader.loader = false;
          this.routerLink.navigate(["/login"]);
          // window.location.href = "/login"
        });
      this.spinner.hide();
    } else {
      this.isSubmit = true;
      this.loader.loader = false;
    }
  }


}
