import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SentMessageService {
    private path = environment.apiUrl + 'SentMessage/';


    constructor(private http: HttpClient) { }

    sent(data: any): Observable<any> {
        return this.http.post<any>(this.path + "Sent", data);
    }

}