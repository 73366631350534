<div class="page-wraper">
    <app-navbar-style-one></app-navbar-style-one>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">
        <!-- Candidate Detail V2 START -->
        <div class="section-full p-b0 bg-white">
            <div class="row container-fluid">
                <div class="col-xl-3 col-lg-4 col-md-12 rightSidebar m-b30" style="
                        position: relative;
                        overflow: visible;
                        box-sizing: border-box;
                        min-height: 1px;
                    ">
                    <div class="theiaStickySidebar" style="
                            padding-top: 0px;
                            padding-bottom: 1px;
                            position: static;
                            transform: none;
                        ">
                        <div class="side-bar-st-1">
                            <div class="twm-candidate-profile-pic">
                                <img *ngIf="!error" [src]="urlUser" alt="" (error)="imageError()" />
                                <span *ngIf="error"><i class="fas fa-user i"></i></span>
                                <!-- <div class="upload-btn-wrapper">
                                    <div id="upload-image-grid"></div>
                                    <button class="site-button button-sm">
                                        Upload Photo
                                    </button>
                                    <input type="file" name="myfile" id="1" accept=".png"
                                        (change)="handleUploadPic($event)" />
                                </div> -->
                            </div>
                            <app-imagecropper [jobSeekerModel]="jobSeekerModel" [assignUrl]="assignUrl" />
                            <div class="twm-mid-content text-center">
                                <a href="candidate-detail.html" class="twm-job-title">
                                    <h4>{{ name }}</h4>
                                </a>
                                <!-- <p>IT Contractor</p> -->
                            </div>

                            <div class="twm-nav-list-1">
                                <ul class="ul">
                                    <!-- <li><a href="candidate-dashboard.html"><i class="fa fa-tachometer-alt"></i>
                                            Dashboard</a></li> -->
                                    <li [ngClass]="
                                            selectedIndex == 0 ? 'active li' : 'li'
                                        ">
                                        <a class="pointer" (click)="toggle(0)"><i class="fa fa-user  me-3"></i> My
                                            Profile</a>
                                    </li>
                                    <li [ngClass]="
                                            selectedIndex == 1 ? 'active li' : 'li'
                                        ">
                                        <a class="pointer" (click)="toggle(1)"><i class="fa fa-suitcase  me-3"></i>
                                            Applied Jobs</a>
                                    </li>
                                    <li [ngClass]="
                                            selectedIndex == 2 ? 'active li' : ' li'
                                        ">
                                        <a class="pointer" (click)="toggle(2)"><i class="fa fa-receipt  me-3"></i> My
                                            Resume</a>
                                    </li>
                                    <!-- <li [ngClass]="
                                            selectedIndex == 3 ? 'active' : ''
                                        ">
                                        <a (click)="toggle(3)"><i class="fa fa-file-download"></i>
                                            Saved Jobs</a>
                                    </li> -->
                                    <li [ngClass]="
                                            selectedIndex == 4 ? 'active li' : 'li'
                                        ">
                                        <a class="pointer" (click)="toggle(4)"><i
                                                class="fa fa-sign-out me-3"></i>Logout</a>
                                    </li>
                                    <li [ngClass]="
                                            selectedIndex == 5 ? 'active li' : 'li'
                                        ">
                                        <a class="pointer" (click)="toggle(5)"><i class="fas fa-key  me-3"></i>Change
                                            Password</a>
                                    </li>
                                    <!-- <li [ngClass]="selectedIndex == 4? 'active':''"><a
                                            href="candidate-cv-manager.html"><i class="fa fa-paperclip"></i> CV
                                            Manager</a></li> -->
                                    <!-- <li [ngClass]="selectedIndex == 5? 'active':''"><a
                                            href="candidate-job-alert.html"><i class="fa fa-bell"></i> Job Alerts</a>
                                    </li>
                                    <li [ngClass]="selectedIndex == 6? 'active':''"><a
                                            href="candidate-change-password.html"><i class="fa fa-fingerprint"></i>
                                            Change Passeord</a></li>
                                    <li [ngClass]="selectedIndex == 7? 'active':''"><a href="candidate-chat.html"><i
                                                class="fa fa-comments"></i>Chat</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-9 col-lg-8 col-md-12 m-b0 me-0" style="min-height: 79vh;">
                    <div class="wt-bnr-inr overlay-wraper bg-center" style="background-image: url(images/banner/1.jpg)">
                        <div class="overlay-main site-bg-white opacity-01"></div>
                        <div class="container">
                            <div class="wt-bnr-inr-entry">
                                <div class="banner-title-outer">
                                    <div class="banner-title-name">
                                        <h2 class="wt-title">
                                            {{ selectedElement[selectedIndex] }}
                                        </h2>
                                    </div>
                                </div>
                                <!-- BREADCRUMB ROW -->

                                <div>
                                    <ul class="wt-breadcrumb breadcrumb-style-2">
                                        <li><a href="/">Home</a></li>
                                        <li>
                                            {{ selectedElement[selectedIndex] }}
                                        </li>
                                    </ul>
                                </div>

                                <!-- BREADCRUMB ROW END -->
                            </div>
                        </div>
                    </div>

                    <div class="container overflow-hidden">
                        <div class="section-content overflow-hidden">
                            <div class="row d-flex justify-content-center overflow-hidden">
                                <div class="col-lg-11 col-md-12 overflow-hidden">
                                    <mat-tab-group dynamicHeight *ngIf="selectedIndex == 0" class="overflow-hidden">
                                        <mat-tab label="Profile">
                                            <div class="cabdidate-de-info">
                                                <div class="twm-s-info-wrap mb-5">
                                                    <h4 class="section-head-small mb-4">
                                                        Profile Info
                                                    </h4>
                                                    <div class="twm-s-info-4">
                                                        <div class="row">
                                                            <div class="col-xl-4 col-lg-6 col-md-12">
                                                                <div class="form-group">
                                                                    <label>Full
                                                                        name:</label>
                                                                    <div class="ls-inputicon-box">
                                                                        <input type="text" class="form-control"
                                                                            placeholder="3" [(ngModel)]="
                                                                                jobSeekerModel.fullNameEn
                                                                            " />
                                                                        <span class="mandatory" *ngIf="
                                                                                jobSeekerModel.fullNameEn ==
                                                                                    '' &&
                                                                                submitProfileInfo
                                                                            ">This
                                                                            field
                                                                            is
                                                                            requied</span>
                                                                        <i class="fs-input-icon fas fa-at"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-6 col-md-12">
                                                                <div class="form-group">
                                                                    <label>Full
                                                                        name
                                                                        (Ar):</label>
                                                                    <div class="ls-inputicon-box">
                                                                        <input type="text" class="form-control"
                                                                            placeholder="3" [(ngModel)]="
                                                                                jobSeekerModel.fullNameAr
                                                                            " />
                                                                        <span class="mandatory" *ngIf="
                                                                                jobSeekerModel.fullNameAr ==
                                                                                    '' &&
                                                                                submitProfileInfo
                                                                            ">This
                                                                            field
                                                                            is
                                                                            requied</span>
                                                                        <i class="fs-input-icon fas fa-at"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-6 col-md-12">
                                                                <div class="form-group">
                                                                    <label>Email:</label>
                                                                    <div class="ls-inputicon-box">
                                                                        <input type="email" class="form-control"
                                                                            placeholder="m@m.com" [(ngModel)]="
                                                                                jobSeekerModel.eamil
                                                                            " disabled />
                                                                        <span class="mandatory" *ngIf="
                                                                                jobSeekerModel.eamil ==
                                                                                    '' &&
                                                                                submitProfileInfo
                                                                            ">This
                                                                            field
                                                                            is
                                                                            requied</span>
                                                                        <i class="fs-input-icon fas fa-at"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-6 col-md-12">
                                                                <div class="form-group">
                                                                    <label>Mobile:</label>
                                                                    <div class="ls-inputicon-box">
                                                                        <input type="text" class="form-control"
                                                                            placeholder="9713838484838" [(ngModel)]="
                                                                                jobSeekerModel.mobileNo
                                                                            " disabled />
                                                                        <span class="mandatory" *ngIf="
                                                                                jobSeekerModel.mobileNo ==
                                                                                    '' &&
                                                                                submitProfileInfo
                                                                            ">This
                                                                            field
                                                                            is
                                                                            requied</span>
                                                                        <i class="fs-input-icon fas fa-at"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-6 col-md-12">
                                                                <div class="form-group">
                                                                    <label>Current
                                                                        City:</label>
                                                                    <div class="ls-inputicon-box">
                                                                        <input type="text" class="form-control"
                                                                            placeholder="+9713838484838" [(ngModel)]="
                                                                                jobSeekerModel.currentCity
                                                                            " />
                                                                        <span class="mandatory" *ngIf="
                                                                                jobSeekerModel.currentCity ==
                                                                                    '' &&
                                                                                submitProfileInfo
                                                                            ">This
                                                                            field
                                                                            is
                                                                            requied</span>
                                                                        <i class="fs-input-icon fas fa-at"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-6 col-md-12">
                                                                <div class="form-group">
                                                                    <label>CV
                                                                        Headline:</label>
                                                                    <div class="ls-inputicon-box">
                                                                        <input type="text" class="form-control"
                                                                            placeholder="yo bro" [(ngModel)]="
                                                                                jobSeekerModel.cvheadline
                                                                            " />
                                                                        <span class="mandatory" *ngIf="
                                                                                jobSeekerModel.cvheadline ==
                                                                                    '' &&
                                                                                submitProfileInfo
                                                                            ">This
                                                                            field
                                                                            is
                                                                            requied</span>
                                                                        <i class="fs-input-icon fas fa-at"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button class="btn site-button mt-3" (click)="
                                                                saveProfileInfo()
                                                            ">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <mat-tab label="Personal information" class="overflow-hidden">
                                            <div class="twm-s-info-wrap mb-5 overflow-hidden">
                                                <h4 class="section-head-small mb-4">
                                                    Personal Information
                                                </h4>
                                                <div class="twm-s-info-4">
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>Full
                                                                    name:</label>
                                                                <div class="ls-inputicon-box">
                                                                    <input type="text" class="form-control"
                                                                        placeholder="3" [(ngModel)]="
                                                                            jobSeekerModel.fullNameEn
                                                                        " disabled />
                                                                    <span class="mandatory" *ngIf="
                                                                            jobSeekerModel.fullNameEn ==
                                                                                '' &&
                                                                            submitPersonalDetails
                                                                        ">This
                                                                        field is
                                                                        requied</span>
                                                                    <i class="fs-input-icon fas fa-at"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>Last Name</label>
                                                                <div class="ls-inputicon-box">
                                                                    <input type="text" class="form-control"
                                                                        placeholder="3" [(ngModel)]="
                                                                            jobSeekerModel.fullNameAr
                                                                        " disabled />
                                                                    <span class="mandatory" *ngIf="
                                                                            jobSeekerModel.fullNameAr ==
                                                                                '' &&
                                                                            submitPersonalDetails
                                                                        ">This
                                                                        field is
                                                                        requied</span>
                                                                    <i class="fs-input-icon fas fa-at"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>DOB:</label>
                                                                <div class="ls-inputicon-box">
                                                                    <input type="date" class="form-control"
                                                                        placeholder="" [(ngModel)]="
                                                                            jobSeekerPersonalDetailModel.dob
                                                                        " />
                                                                    <span class="mandatory" *ngIf="
                                                                            jobSeekerPersonalDetailModel.dob ==
                                                                                '' &&
                                                                            submitPersonalDetails
                                                                        ">This
                                                                        field is
                                                                        requied</span>
                                                                    <i class="fs-input-icon fas fa-at"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>Gender:</label>
                                                                <div class="ls-inputicon-box">
                                                                    <input type="text" class="form-control"
                                                                        placeholder="Female" [(ngModel)]="
                                                                            jobSeekerPersonalDetailModel.gender
                                                                        " />
                                                                    <span class="mandatory" *ngIf="
                                                                            jobSeekerPersonalDetailModel.gender ==
                                                                                '' &&
                                                                            submitPersonalDetails
                                                                        ">This
                                                                        field is
                                                                        requied</span>
                                                                    <i class="fs-input-icon fas fa-at"></i>
                                                                </div>

                                                            </div>
                                                        </div> -->
                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="form-group city-outer-bx has-feedback">
                                                                    <label _ngcontent-sva-c15="">Gender:</label>


                                                                    <ngx-select-dropdown
                                                                        [ngModel]="jobSeekerPersonalDetailModel.gender"
                                                                        (change)="assignGender(
                                                                            $event.value
                                                                        )" [instanceId]="'instance4'"
                                                                        [multiple]="false" [config]="configStatic"
                                                                        [options]="['Male','Female']"></ngx-select-dropdown>

                                                                </div>
                                                                <span class="mandatory" *ngIf="
                                                                            jobSeekerPersonalDetailModel.gender ==
                                                                                '' &&
                                                                            submitPersonalDetails
                                                                        ">This
                                                                    field is
                                                                    requied</span>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="form-group city-outer-bx has-feedback">
                                                                    <label _ngcontent-sva-c15="">Martial
                                                                        Status:</label>
                                                                    <ngx-select-dropdown
                                                                        [ngModel]="selectedObject(martialStatusModelList,jobSeekerPersonalDetailModel.maritalStatusId)"
                                                                        (change)="assignMartialStatus(
                                                                        $event.value.id
                                                                    )" [instanceId]="'instance1'" [multiple]="false"
                                                                        [config]="config"
                                                                        [options]="martialStatusModelList"></ngx-select-dropdown>

                                                                </div>
                                                            </div>
                                                            <span class="mandatory" *ngIf="
                                                                        jobSeekerPersonalDetailModel.maritalStatusId ==
                                                                            0 &&
                                                                        submitPersonalDetails
                                                                    ">This field
                                                                is
                                                                requied</span>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label _ngcontent-sva-c15="">Visa
                                                                    Status:</label>
                                                                <ngx-select-dropdown
                                                                    [ngModel]="selectedObject(visaStatusModelList,jobSeekerPersonalDetailModel.visaStatusId)"
                                                                    (change)="assignVisaId(
                                                                        $event.value.id
                                                                    )" [instanceId]="'instance1'" [multiple]="false"
                                                                    [config]="config"
                                                                    [options]="visaStatusModelList"></ngx-select-dropdown>

                                                            </div>
                                                            <span class="mandatory" *ngIf="
                                                                            jobSeekerPersonalDetailModel.visaStatusId ==
                                                                                0 &&
                                                                            submitPersonalDetails
                                                                        ">This
                                                                field is
                                                                requied</span>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>Alternative
                                                                    Email:</label>
                                                                <div class="ls-inputicon-box">
                                                                    <input type="email" class="form-control"
                                                                        placeholder="yo@bro.com" [(ngModel)]="
                                                                            jobSeekerPersonalDetailModel.alternateEmailId
                                                                        " />
                                                                    <span class="mandatory" *ngIf="
                                                                            jobSeekerPersonalDetailModel.alternateEmailId ==
                                                                                '' &&
                                                                            submitPersonalDetails
                                                                        ">This
                                                                        field is
                                                                        requied</span>
                                                                    <i class="fs-input-icon fas fa-at"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>Alternative
                                                                    Mobile:</label>
                                                                <div class="ls-inputicon-box">
                                                                    <input type="text" class="form-control"
                                                                        maxlength="13" placeholder="+971378937838"
                                                                        [(ngModel)]="
                                                                            jobSeekerPersonalDetailModel.alternateMobile
                                                                        " />
                                                                    <span class="mandatory" *ngIf="
                                                                            jobSeekerPersonalDetailModel.alternateMobile ==
                                                                                '' &&
                                                                            submitPersonalDetails
                                                                        ">This
                                                                        field is
                                                                        requied</span>
                                                                    <i class="fs-input-icon fas fa-at"></i>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label _ngcontent-sva-c15="">Nationality:</label>

                                                                <ngx-select-dropdown (change)="assignNationality(
                                                                        $event.value.id
                                                                    )"
                                                                    [ngModel]="selectedObject(nationalityList,jobSeekerPersonalDetailModel.nationalityId)"
                                                                    [instanceId]="'instance2'" [multiple]="false"
                                                                    [config]="config" [options]="nationalityList">
                                                                </ngx-select-dropdown>

                                                                <span class="mandatory" *ngIf="
                                                                        jobSeekerPersonalDetailModel.nationalityId ==
                                                                            0 &&
                                                                        submitPersonalDetails
                                                                    ">This field
                                                                    is
                                                                    requied</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group mt-4">
                                                                <mat-checkbox
                                                                    [(ngModel)]="jobSeekerPersonalDetailModel.isDrivingLicense">Driving
                                                                    License?</mat-checkbox>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-12"
                                                            *ngIf="jobSeekerPersonalDetailModel.isDrivingLicense">
                                                            <div class="form-group">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="form-group city-outer-bx has-feedback">
                                                                    <label _ngcontent-sva-c15="">Driving
                                                                        License
                                                                        Issue
                                                                        Country:</label>
                                                                    <ngx-select-dropdown
                                                                        [ngModel]="selectedObject(countryModelList,jobSeekerPersonalDetailModel.issuingCountryId)"
                                                                        (change)="assignCountry(
                                                                        $event.value.id
                                                                    )" [instanceId]="'instance2'" [multiple]="false"
                                                                        [config]="config"
                                                                        [options]="countryModelList"></ngx-select-dropdown>

                                                                </div>
                                                                <span class="mandatory" *ngIf="
                                                                        jobSeekerPersonalDetailModel.issuingCountryId ==
                                                                            0 &&
                                                                        submitPersonalDetails
                                                                    ">This field
                                                                    is
                                                                    requied</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="form-group city-outer-bx has-feedback">
                                                                    <label _ngcontent-sva-c15="">Current
                                                                        Country:</label>

                                                                    <ngx-select-dropdown
                                                                        [ngModel]="selectedObject(countryModelList,jobSeekerPersonalDetailModel.currentCountryId)"
                                                                        (change)="assignCurrCountry(
                                                                        $event.value.id
                                                                    )" [instanceId]="'instance2'" [multiple]="false"
                                                                        [config]="config"
                                                                        [options]="countryModelList"></ngx-select-dropdown>

                                                                </div>
                                                                <span class="mandatory" *ngIf="
                                                                        jobSeekerPersonalDetailModel.currentCountryId ==
                                                                            0 &&
                                                                        submitPersonalDetails
                                                                    ">This field
                                                                    is
                                                                    requied</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="form-group city-outer-bx has-feedback">
                                                                    <label _ngcontent-sva-c15="">Current
                                                                        City:</label>
                                                                    <ngx-select-dropdown
                                                                        [ngModel]="selectedObject(cityList,jobSeekerPersonalDetailModel.currentCityId)"
                                                                        (change)="assignCity(
                                                                        $event.value.id
                                                                    )" [instanceId]="'instance21'" [multiple]="false"
                                                                        [config]="config"
                                                                        [options]="cityList"></ngx-select-dropdown>

                                                                </div>
                                                                <span class="mandatory" *ngIf="
                                                                        jobSeekerPersonalDetailModel.currentCityId ==
                                                                            0 &&
                                                                        submitPersonalDetails
                                                                    ">This field
                                                                    is
                                                                    requied</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <div _ngcontent-sva-c15=""
                                                                    class="form-group city-outer-bx has-feedback">
                                                                    <label _ngcontent-sva-c15="">Religion:</label>

                                                                    <ngx-select-dropdown
                                                                        [ngModel]="selectedObject(religionList,jobSeekerPersonalDetailModel.religionId)"
                                                                        (change)="assignReligionId(
                                                                        $event.value.id
                                                                    )" [instanceId]="'instance2'" [multiple]="false"
                                                                        [config]="config"
                                                                        [options]="religionList"></ngx-select-dropdown>

                                                                </div>
                                                                <span class="mandatory" *ngIf="
                                                                        jobSeekerPersonalDetailModel.religionId ==
                                                                            0 &&
                                                                        submitPersonalDetails
                                                                    ">This field
                                                                    is
                                                                    requied</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-6 col-md-12">
                                                            <div class="form-group">
                                                                <label>Languages
                                                                    Known:</label>
                                                                <div class="ls-inputicon-box">
                                                                    <textarea type="text" class="form-control"
                                                                        placeholder="English, Arabic" [(ngModel)]="
                                                                            jobSeekerPersonalDetailModel.languagesKnown
                                                                        "></textarea>
                                                                    <span class="mandatory" *ngIf="
                                                                            jobSeekerPersonalDetailModel.languagesKnown ==
                                                                                '' &&
                                                                            submitPersonalDetails
                                                                        ">This
                                                                        field is
                                                                        requied</span>
                                                                    <i class="fs-input-icon fas fa-at"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <button class="btn site-button mt-3" (click)="
                                                            savePersonalDetails()
                                                        ">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </mat-tab>
                                    </mat-tab-group>
                                    <!-- <mat-tab label="Third"> Content 3 </mat-tab> -->
                                    <div class="twm-jobs-list-wrap" *ngIf="
                                            selectedIndex == 1 ||
                                            selectedIndex == 3
                                        ">
                                        <ul>
                                            <li *ngFor="
                                                    let item of jobList
                                                        | paginate
                                                            : {
                                                                  itemsPerPage: 7,
                                                                  currentPage: p
                                                              }
                                                ">
                                                <app-job-widget [job]="item"></app-job-widget>
                                            </li>
                                        </ul>
                                        <div class="pagination-outer">
                                            <div class="pagination-style1">
                                                <pagination-controls (pageChange)="
                                                        paginate($event)
                                                    "></pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <!---->
                                    <div class="cabdidate-de-info" *ngIf="selectedIndex == 2">
                                        <div class="twm-candidate-profile-pic1">
                                            <img *ngIf="!error" [src]="urlUser" alt="" (error)="imageError()" />
                                            <span *ngIf="error"><i class="fas fa-user i"></i></span>
                                        </div>
                                        <div class="twm-candi-self-info-2 mt-3">
                                            <div class="twm-candi-self-bottom">
                                                <a *ngIf="jobSeekerModel.resumePath != '' && jobSeekerModel.resumePath != null"
                                                    target="_blank" [href]="url" class="site-button button-sm">Preview
                                                    CV</a>
                                                <div class="upload-btn-wrapper">
                                                    <!-- <div id="upload-image-grid"></div> -->
                                                    <a class="site-button secondry button-sm">Upload CV</a>
                                                    <input type="file" name="myfile" id="" accept=".pdf" (change)="
                                                            handleUpload($event)
                                                        " />
                                                </div>
                                            </div>
                                        </div>


                                        <div class="cabdidate-de-info mt-1" *ngIf="selectedIndex == 2">
                                            <div class="twm-candi-self-info-2">
                                                <div class="twm-candi-self-bottom">
                                                    <a [href]="urlCl" class="site-button button-sm" target="_blank"
                                                        *ngIf="jobSeekerModel.coverLetterPath != '' && jobSeekerModel.coverLetterPath != null">Preview
                                                        Cover
                                                        Letter</a>
                                                    <div class="upload-btn-wrapper">
                                                        <!-- <div id="upload-image-grid"></div> -->
                                                        <a class="site-button secondry button-sm">Upload Cover
                                                            Letter</a>
                                                        <input type="file" name="myfile" id="" accept=".pdf" (change)="
                                                        handleUploadCoverLetter($event)
                                                            " />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="twm-s-info-wrap mb-5 mt-5">
                                                <h4 class="twm-s-title m-t0">
                                                    About Me
                                                    <button type="submit" class="btn site-button action"
                                                        (click)="showEditToggle()">
                                                        <mat-icon *ngIf="!showEdit">edit</mat-icon>
                                                        <mat-icon *ngIf="showEdit">cancel</mat-icon>
                                                    </button>
                                                </h4>
                                                <textarea *ngIf="showEdit" class="form-control mt-3" rows="7"
                                                    [(ngModel)]="
                                                    jobSeekerProfileSummary.summary
                                                "></textarea>
                                                <span class="mandatory" *ngIf="
                                                    jobSeekerProfileSummary.summary ==
                                                        '' && submitProfile
                                                ">This field is requied</span>
                                                <button *ngIf="showEdit" class="btn site-button mt-3"
                                                    (click)="saveProfileSummary()">
                                                    Save
                                                </button>

                                                <p *ngIf="!showEdit">
                                                    {{
                                                    jobSeekerProfileSummary.summary
                                                    }}
                                                </p>

                                                <app-job-seeker-keyskill [edit]="true"
                                                    [seekerId]="0"></app-job-seeker-keyskill>

                                                <app-job-seeker-language [edit]="true"
                                                    [seekerId]="0"></app-job-seeker-language>

                                                <app-job-seeker-prof-detail></app-job-seeker-prof-detail>


                                                <app-job-seeker-skillcertification></app-job-seeker-skillcertification>

                                                <!-- <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi.</p> -->

                                                <div class="we-disp mt-3">
                                                    <h4 class="twm-s-title">
                                                        Employment Detail
                                                    </h4>
                                                    <button type="submit" (click)="newEmployment()"
                                                        class="btn site-button action" data-bs-target="#sign_up_popup"
                                                        data-bs-toggle="modal" data-bs-dismiss="modal">
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                </div>
                                                <div class="twm-timing-list-wrap">
                                                    <div class="twm-timing-list" *ngFor="
                                                        let item of jobSeekerEmploymentDetailList
                                                    ">
                                                        <div class="twm-time-list-date">
                                                            {{ item.fromYear }} to
                                                            {{ item.toYear == 2099 ? "Current": item.toYear}}
                                                        </div>
                                                        <div class="twm-time-list-title">
                                                            {{ item.companyName }}
                                                            <button type="submit" class="btn site-button action"
                                                                (click)="
                                                                getByIdEmployment(
                                                                    item.id
                                                                )
                                                            " data-bs-target="#sign_up_popup" data-bs-toggle="modal"
                                                                data-bs-dismiss="modal">
                                                                <mat-icon>edit</mat-icon>
                                                            </button>
                                                            <button (click)="
                                                                deleteEmployment(
                                                                    item.id
                                                                )
                                                            " type="submit"
                                                                class="ms-3 btn site-button action mandatory">
                                                                <mat-icon>delete</mat-icon>
                                                            </button>
                                                        </div>
                                                        <div class="twm-time-list-position">
                                                            {{ item.designation }}
                                                        </div>
                                                        <div class="twm-time-list-discription">
                                                            <p>
                                                                {{
                                                                item.companyLocation
                                                                }} <mat-icon>location_on</mat-icon>
                                                            </p>
                                                        </div>
                                                        <div class="twm-time-list-discription">
                                                            <p>
                                                                {{
                                                                item.jobProfile
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="we-disp mt-3">
                                                <h4 class="twm-s-title">
                                                    Education &amp; Training
                                                </h4>
                                                <button (click)="newEducation()" type="submit"
                                                    class="btn site-button action" data-bs-target="#sign_up_popup1"
                                                    data-bs-toggle="modal" data-bs-dismiss="modal">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </div>

                                            <div class="twm-timing-list-wrap">
                                                <div class="twm-timing-list" *ngFor="
                                                        let item of jobSeekerEducationDetailList
                                                    ">
                                                    <div class="twm-time-list-date">
                                                        Passing year:
                                                        {{ item.passingYear }}
                                                    </div>
                                                    <div class="twm-time-list-title">
                                                        {{
                                                        item.specialization
                                                        }}
                                                        <button type="submit" class="btn site-button action" (click)="
                                                                getByIdEducation(
                                                                    item.id
                                                                )
                                                            " data-bs-target="#sign_up_popup1" data-bs-toggle="modal"
                                                            data-bs-dismiss="modal">
                                                            <mat-icon>edit</mat-icon>
                                                        </button>
                                                        <button (click)="
                                                                deleteEducation(
                                                                    item.id
                                                                )
                                                            " type="submit"
                                                            class="ms-3 btn site-button action mandatory">
                                                            <mat-icon>delete</mat-icon>
                                                        </button>
                                                    </div>
                                                    <div class="twm-time-list-position">
                                                        {{ item.instituteName }}
                                                    </div>
                                                    <!-- <div class="twm-time-list-position">
                                                            {{ item.specialization }}
                                                        </div> -->
                                                    <div class="twm-time-list-discription">
                                                        <!-- <p>One of the main areas that I work on with my clients is shedding these non-supportive beliefs and
                                                        replacing them with beliefs that will help them to accomplish their desires.</p> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Candidate detail START -->

                                    <!-- <div class="twm-s-map mb-5">
                                        <h4 class="section-head-small mb-4">Location</h4>
                                        <div class="twm-s-map-iframe twm-s-map-iframe-2">
                                            <iframe height="270"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3304.8534521658976!2d-118.2533646842856!3d34.073270780600225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c6fd9829c6f3%3A0x6ecd11bcf4b0c23a!2s1363%20Sunset%20Blvd%2C%20Los%20Angeles%2C%20CA%2090026%2C%20USA!5e0!3m2!1sen!2sin!4v1620815366832!5m2!1sen!2sin"></iframe>
                                        </div>
                                    </div>




                                    <div class="twm-s-contact-wrap mb-5">
                                        <h4 class="section-head-small mb-4">Contact us</h4>
                                        <div class="twm-s-contact twm-s-contact-2">
                                            <div class="row">

                                                <div class="col-lg-12">
                                                    <div class="form-group mb-3">
                                                        <input name="username" type="text" required=""
                                                            class="form-control" placeholder="Name">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group mb-3">
                                                        <input name="email" type="text" class="form-control" required=""
                                                            placeholder="Email">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group mb-3">
                                                        <input name="phone" type="text" class="form-control" required=""
                                                            placeholder="Phone">
                                                    </div>
                                                </div>


                                                <div class="col-lg-12">
                                                    <div class="form-group mb-3">
                                                        <textarea name="message" class="form-control" rows="3"
                                                            placeholder="Message"></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <button type="submit" class="site-button">Submit Now</button>
                                                </div>

                                            </div>

                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="modal fade twm-sign-up" id="sign_up_popup" aria-hidden="true"
                        aria-labelledby="sign_up_popupLabel" tabindex="-1">
                        <div class="modal-dialog modal-dialog-centered modal-xl">
                            <div class="modal-content">
                                <div>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="sign_up_popupLabel">
                                            Employment Detail
                                        </h4>
                                        <!-- <p>Save and get access to all the features of Jobzilla</p> -->
                                        <button type="button" class="btn-close close-btn" id="close-btn1" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>

                                    <div class="modal-body">
                                        <div class="twm-tabs-style-2">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Designation:</label>
                                                        <div class="ls-inputicon-box">
                                                            <input type="text" class="form-control"
                                                                placeholder="Software Developer" [(ngModel)]="
                                                                    jobSeekerEmploymentDetail.designation
                                                                " />
                                                            <span class="mandatory" *ngIf="
                                                                    jobSeekerEmploymentDetail.designation ==
                                                                        '' &&
                                                                    submitEmployment
                                                                ">This field is
                                                                requied</span>
                                                            <i class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Company
                                                            Name:</label>
                                                        <div class="ls-inputicon-box">
                                                            <input type="text" class="form-control" placeholder="Mahad"
                                                                [(ngModel)]="
                                                                    jobSeekerEmploymentDetail.companyName
                                                                " />
                                                            <span class="mandatory" *ngIf="
                                                                    jobSeekerEmploymentDetail.companyName ==
                                                                        '' &&
                                                                    submitEmployment
                                                                ">This field is
                                                                requied</span>
                                                            <i class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Company
                                                            Location:</label>
                                                        <div class="ls-inputicon-box">
                                                            <input type="text" class="form-control" placeholder="London"
                                                                [(ngModel)]="
                                                                    jobSeekerEmploymentDetail.companyLocation
                                                                " />
                                                            <span class="mandatory" *ngIf="
                                                                    jobSeekerEmploymentDetail.companyLocation ==
                                                                        '' &&
                                                                    submitEmployment
                                                                ">This field is
                                                                requied</span>
                                                            <i class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>From Month:</label>
                                                        <div class="ls-inputicon-box">
                                                            <ngx-select-dropdown
                                                                [(ngModel)]="jobSeekerEmploymentDetail.fromMonth"
                                                                [instanceId]="'instance21'" [multiple]="false"
                                                                [config]="configStaticNoSearch"
                                                                [options]="monthList"></ngx-select-dropdown>

                                                            <span class="mandatory" *ngIf="
                                                                    jobSeekerEmploymentDetail.fromMonth ==
                                                                        '' &&
                                                                    submitEmployment
                                                                ">This field is
                                                                requied</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>From Year:</label>
                                                        <div class="ls-inputicon-box">
                                                            <ngx-select-dropdown
                                                                [(ngModel)]="jobSeekerEmploymentDetail.fromYear"
                                                                [instanceId]="'instance21'" [multiple]="false"
                                                                [config]="configStaticNoSearch"
                                                                [options]="yearList"></ngx-select-dropdown>

                                                            <span class="mandatory" *ngIf="
                                                                    jobSeekerEmploymentDetail.fromYear ==
                                                                        0 &&
                                                                    submitEmployment
                                                                ">This field is
                                                                requied</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Is Current:</label>
                                                        <div class="ls-inputicon-box">
                                                            <input type="checkbox"
                                                                [(ngModel)]="currEmployee"/>

                                                            <!-- <span class="mandatory" *ngIf="
                                                                    jobSeekerEmploymentDetail.toMonth ==
                                                                        '' &&
                                                                    submitEmployment
                                                                ">This field is
                                                                requied</span> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12" *ngIf="!currEmployee">
                                                    <div class="form-group">
                                                        <label>To Month:</label>
                                                        <div class="ls-inputicon-box">
                                                            <ngx-select-dropdown
                                                                [(ngModel)]="jobSeekerEmploymentDetail.toMonth"
                                                                [instanceId]="'instance21'" [multiple]="false"
                                                                [config]="configStaticNoSearch"
                                                                [options]="monthList"></ngx-select-dropdown>

                                                            <span class="mandatory" *ngIf="
                                                                    jobSeekerEmploymentDetail.toMonth ==
                                                                        '' &&
                                                                    submitEmployment
                                                                ">This field is
                                                                requied</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12 " *ngIf="!currEmployee">
                                                    <div class="form-group">
                                                        <label>To Year:</label>
                                                        <div class="ls-inputicon-box">
                                                            <ngx-select-dropdown
                                                                [(ngModel)]="jobSeekerEmploymentDetail.toYear"
                                                                [instanceId]="'instance21'" [multiple]="false"
                                                                [config]="configStaticNoSearch"
                                                                [options]="yearList"></ngx-select-dropdown>

                                                            <span class="mandatory" *ngIf="
                                                                    jobSeekerEmploymentDetail.toYear ==
                                                                        0 &&
                                                                    submitEmployment
                                                                ">This field is
                                                                requied</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-12 col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Job Profile:</label>
                                                        <div class="ls-inputicon-box">
                                                            <textarea type="text" class="form-control"
                                                                placeholder="Yo Bro" [(ngModel)]="
                                                                    jobSeekerEmploymentDetail.jobProfile
                                                                "></textarea>
                                                            <span class="mandatory" *ngIf="
                                                                    jobSeekerEmploymentDetail.jobProfile ==
                                                                        '' &&
                                                                    submitEmployment
                                                                ">This field is
                                                                requied</span>
                                                            <i class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-3">
                                                    <button type="submit" class="site-button" (click)="
                                                            saveEmployment()
                                                        ">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="modal-footer">
                                <span class="modal-f-title">Login or Sign up with</span>
                                <ul class="twm-modal-social">
                                    <li><a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="javascript" class="twitter-clr"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="javascript" class="google-clr"><i class="fab fa-google"></i></a></li>
                                </ul>
                            </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade twm-sign-up" id="sign_up_popup1" aria-hidden="true"
                        aria-labelledby="sign_up_popupLabel" tabindex="-1">
                        <div class="modal-dialog modal-dialog-centered modal-xl">
                            <div class="modal-content">
                                <div>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="sign_up_popupLabel">
                                            Education Detail
                                        </h4>
                                        <!-- <p>Save and get access to all the features of Jobzilla</p> -->
                                        <button type="button" class="btn-close close-btn" id="close-btn2" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>

                                    <div class="modal-body">
                                        <div class="twm-tabs-style-2">
                                            <div class="row">
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Specialization:</label>
                                                        <div class="ls-inputicon-box">
                                                            <input type="text" class="form-control"
                                                                placeholder="Telemcommunication Engineering"
                                                                [(ngModel)]="
                                                                    jobSeekerEducationDetail.specialization
                                                                " />
                                                            <span class="mandatory" *ngIf="
                                                                    jobSeekerEducationDetail.specialization ==
                                                                        '' &&
                                                                    submitEducation
                                                                ">This field is
                                                                requied</span>
                                                            <i class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Institute
                                                            Name:</label>
                                                        <div class="ls-inputicon-box">
                                                            <input type="text" class="form-control"
                                                                placeholder="Mahad Group" [(ngModel)]="
                                                                    jobSeekerEducationDetail.instituteName
                                                                " />
                                                            <span class="mandatory" *ngIf="
                                                                    jobSeekerEducationDetail.instituteName ==
                                                                        '' &&
                                                                    submitEducation
                                                                ">This field is
                                                                requied</span>
                                                            <i class="fs-input-icon fas fa-at"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <label>Passing
                                                            Year:</label>
                                                        <div class="ls-inputicon-box">
                                                            <ngx-select-dropdown [options]="yearList"
                                                                [(ngModel)]="jobSeekerEducationDetail.passingYear"
                                                                [instanceId]="'instance21'" [multiple]="false"
                                                                [config]="configStaticNoSearch"></ngx-select-dropdown>

                                                            <span class="mandatory" *ngIf="
                                                                    jobSeekerEducationDetail.passingYear ==
                                                                        0 &&
                                                                    submitEducation
                                                                ">This field is
                                                                requied</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">
                                                        <div _ngcontent-sva-c15=""
                                                            class="form-group city-outer-bx has-feedback">
                                                            <label _ngcontent-sva-c15="">Institute
                                                                Location:</label>
                                                            <ngx-select-dropdown
                                                                [ngModel]="selectedObject(countryModelList,jobSeekerEducationDetail.instituteLocationId)"
                                                                (change)="assignInstituteLocationId(
                                                                $event.value.id
                                                            )" [instanceId]="'instance21'" [multiple]="false"
                                                                [config]="config"
                                                                [options]="countryModelList"></ngx-select-dropdown>

                                                        </div>
                                                        <span class="mandatory" *ngIf="
                                                                jobSeekerEducationDetail.instituteLocationId ==
                                                                    0 &&
                                                                submitEducation
                                                            ">This field is
                                                            requied</span>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-6 col-md-12">
                                                    <div class="form-group">

                                                        <label _ngcontent-sva-c15="">Course
                                                            Enrolled:</label>
                                                        <ngx-select-dropdown
                                                            [ngModel]="selectedObject(courseModelList,jobSeekerEducationDetail.courseId)"
                                                            (change)="assignCourseId(
                                                                $event.value.id
                                                            )" [instanceId]="'instance21'" [multiple]="false"
                                                            [config]="config"
                                                            [options]="courseModelList"></ngx-select-dropdown>


                                                        <span class="mandatory" *ngIf="
                                                                jobSeekerEducationDetail.courseId ==
                                                                    0 &&
                                                                submitEducation
                                                            ">This field is
                                                            requied</span>
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-3">
                                                    <button type="submit" class="site-button" (click)="
                                                            saveEducation()
                                                        ">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="modal-footer">
                                <span class="modal-f-title">Login or Sign up with</span>
                                <ul class="twm-modal-social">
                                    <li><a href="javascript" class="facebook-clr"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="javascript" class="twitter-clr"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="javascript" class="linkedin-clr"><i class="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="javascript" class="google-clr"><i class="fab fa-google"></i></a></li>
                                </ul>
                            </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Candidate Detail V2 END -->
            </div>

            <!-- <div class="row">
                <div class="col-xl-3 col-lg-4 col-md-12 rightSidebar m-b30" style="
                        position: relative;
                        overflow: visible;
                        box-sizing: border-box;
                        min-height: 1px;
                    ">
                </div>
                <div class="col-xl-9 col-lg-8 col-md-12 me-0">
                    <app-footer-style-one></app-footer-style-one>

                </div>
            </div> -->

        </div>
        <div class="margin-dynamic">
            <app-footer-style-one></app-footer-style-one>
        </div>
    </div>
</div>
