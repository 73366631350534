import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JobsModel } from 'src/app/model/Jobs';
import { JobSeekerModel } from 'src/app/model/JobSeeker';
import { JobSeekerEducationDetailModel } from 'src/app/model/JobSeekerEducationDetail';
import { JobSeekerEmploymentDetailModel } from 'src/app/model/JobSeekerEmploymentDetail';
import { JobSeekerPersonalDetailModel } from 'src/app/model/JobSeekerPersonalDetail';
import { JobSeekerProfileSummaryModel } from 'src/app/model/JobSeekerProfileSummary';
import { JobSeekerSkillModel } from 'src/app/model/JobSeekerSkill';
import { CityModel } from 'src/app/model/master/City';
import { CountryModel } from 'src/app/model/master/Country';
import { CourseModel } from 'src/app/model/master/Course';
import { FunctionalAreaModel } from 'src/app/model/master/FunctionalArea';
import { IndustryModel } from 'src/app/model/master/Industry';
import { JobTypeModel } from 'src/app/model/master/JobType';
import { MartialStatusModel } from 'src/app/model/master/MartialStatus';
import { NationalityModel } from 'src/app/model/master/Nationality';
import { ReligionModel } from 'src/app/model/master/Religion';
import { VisaStatusModel } from 'src/app/model/master/VisaStatus';
import { UploadMedia } from 'src/app/model/UploadMedia';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { JobService } from 'src/app/services/job.service';
import { JobSeekerService } from 'src/app/services/jobSeeker';
import { MasterService } from 'src/app/services/master.service';
import { UploadMediaService } from 'src/app/services/uploadMedia.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  // Masters
  error: boolean = false;
  cVError: boolean = false;
  coverLetterError: boolean = false;
  religion: ReligionModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  religionList: Array<ReligionModel> = []

  city: CityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  cityList: Array<CityModel> = []

  nationality: NationalityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  nationalityList: Array<NationalityModel> = []

  countryModel: CountryModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  countryModelList: Array<CountryModel> = []

  visaStatusModel: VisaStatusModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  visaStatusModelList: Array<VisaStatusModel> = []

  martialStatusModel: MartialStatusModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  martialStatusModelList: Array<MartialStatusModel> = []

  courseModel: CourseModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    userDefined1: '',
    userDefined2: '',
    userDefined3: undefined,
    userDefined4: '',
    isDeleted: false
  }
  courseModelList: Array<CourseModel> = []
  selectedElement: Array<string> = ["My Profile", "Applied Jobs", "My Resume", "Saved Jobs"];
  selectedIndex: number = 0;
  yearList: number[] = [];
  monthList: number[] = [];

  config = {
  };
  configStatic = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: true,
    // searchOnKey: '',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };
  configStaticNoSearch = {
    displayFn: (item: any) => { return item; },
    // displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
    search: false,
    // searchOnKey: '',
    limitTo: 0,
    height: "250px",
    enableSelectAll: true,
  };

  // End

  url: string = "";
  urlCl: string = "";
  urlUser: string = "";
  industry0: string = "";
  location: string = "";
  name: string = "";
  isSubmit: boolean = false;
  userId: string = this.authService.getAuthStorage().id;
  media: UploadMedia = {
    id: 0,
    base64: "",
    name: ""
  }
  constructor(private authService: AuthService, private uploadMedia: UploadMediaService,
    private companyService: CompanyService, public masterService: MasterService,
    private jobService: JobService, private router:Router,private tost: ToastrService, private jobSeekerSrv: JobSeekerService) {

  }
  ngOnInit(): void {
    this.yearList = this.masterService.yearList();
    this.monthList = this.masterService.monthList();
    this.config = {
      displayFn: (item: any) => { return item.nameEn; },
      displayKey: "nameEn", // if objects array passed which key to be displayed defaults to description
      search: true,
      searchOnKey: 'nameEn',
      limitTo: 0,
      height: "231px",
      enableSelectAll: true,
    };
    this.name = this.authService.getAuthStorage().username;
    this.masterService.getNationalitAll().subscribe((data) => {
      this.nationalityList = data;
    });
    this.masterService.getCountryAll().subscribe((data) => {
      this.countryModelList = data;
    });
    this.masterService.getReligionAll().subscribe((data) => {
      this.religionList = data;
    });
    this.masterService.getMartialStatusAll().subscribe((data) => {
      this.martialStatusModelList = data;
    });
    this.masterService.getVisaStatusAll().subscribe((data) => {
      this.visaStatusModelList = data;
    });
    this.masterService.getCourseAll().subscribe((data) => {
      this.courseModelList = data;
    });
    this.getProfileInfo(this.userId);
    this.getProfileSummary(this.authService.getAuthStorage().jobseekerId)
    this.getEmploymentAll(this.authService.getAuthStorage().jobseekerId)
    this.getEducationAll(this.authService.getAuthStorage().jobseekerId)
    this.gePersonalDetails(this.authService.getAuthStorage().jobseekerId)
  }

  // Job Seeker Profile Info

  submitProfileInfo: boolean = false;
  jobSeekerModel: JobSeekerModel = {
    id: 0,
    userId: this.authService.getAuthStorage().id,
    fullNameEn: '',
    fullNameAr: '',
    cvheadline: '',
    eamil: '',
    mobileNo: '',
    currentCity: '',
    profilePicturePath: '',
    resumePath: '',
    coverLetterPath: ''
  }
  jobSeekerMadatory = [
    'fullNameEn',
    'fullNameAr',
    'eamil',
    'mobileNo',
    'currentCity',
    'cvheadline',
  ]
  saveProfileInfo() {
    console.log(this.masterService.validate(this.jobSeekerMadatory, this.jobSeekerModel), "xzklklklklsklkklx", this.jobSeekerModel.id);
    if (this.masterService.validate(this.jobSeekerMadatory, this.jobSeekerModel)) {
      //Api call
      if (this.jobSeekerModel.id == 0) {
        this.jobSeekerSrv.saveProfileInfo(this.jobSeekerModel).subscribe((data) => {
          this.tost.success("Operation Succeeded");
        });
      } else {
        this.jobSeekerSrv.updateProfileInfo(this.jobSeekerModel).subscribe((data) => {
          this.getProfileInfo(this.authService.getAuthStorage().id);
          this.tost.success("Operation Succeeded");
        });
      }
    } else {
      this.submitProfileInfo = true;
    }
  }
  getProfileInfo(id: string) {
    this.jobSeekerSrv.getProfileInfo(id).subscribe((data) => {
      this.jobSeekerModel = data;
      this.assignUrl();
    });
  }
  // End


  // Job Seeker Personal Details

  submitPersonalDetails: boolean = false;
  jobSeekerPersonalDetailModel: JobSeekerPersonalDetailModel = {
    id: 0,
    jobSeekerId: this.authService.getAuthStorage().jobseekerId,
    dob: '',
    gender: '',
    nationalityId: 0,
    maritalStatusId: 0,
    isDrivingLicense: true,
    issuingCountryId: 1,
    currentCityId: 0,
    languagesKnown: '',
    visaStatusId: 0,
    religionId: 0,
    alternateEmailId: '',
    alternateCountryCode: '',
    alternateMobile: '',
    maritalStatus: undefined,
    religion: undefined,
    nationality: undefined,
    visaStatus: undefined,
    issuingCountry: undefined,
    currentCity: undefined,
    currentCountry: undefined,
    currentCountryId: 0
  }
  jobSeekerPersonalDetailMandatory = [
    'dob',
    'gender',
    'nationalityId',
    'maritalStatusId',
    'issuingCountryId',
    'currentCityId',
    'languagesKnown',
    'visaStatusId',
    'religionId',
  ]

  savePersonalDetails() {
    if (this.masterService.validate(this.jobSeekerPersonalDetailMandatory, this.jobSeekerPersonalDetailModel)) {
      //Api call
      if (this.jobSeekerPersonalDetailModel.id == 0) {
        this.jobSeekerSrv.savePersonalInfo(this.jobSeekerPersonalDetailModel).subscribe((data) => {
          this.tost.success("Operation Succeeded");
        });
      } else {
        this.jobSeekerSrv.updatePersonalInfo(this.jobSeekerPersonalDetailModel).subscribe((data) => {
          this.getProfileInfo(this.authService.getAuthStorage().id);
          this.tost.success("Operation Succeeded");
        });
      }

    } else {
      this.submitPersonalDetails = true;
    }
  }
  gePersonalDetails(id: Number) {

    this.jobSeekerSrv.getPPersonalInfo(id).subscribe((data) => {
      if (data.dob.includes("T")) {
        data.dob = data.dob.split("T")[0];
      }
      this.jobSeekerPersonalDetailModel = data;
      this.assignCurrCountry(this.jobSeekerPersonalDetailModel.currentCountryId);
    });
  }

  assignGender(gender: string) {
    this.jobSeekerPersonalDetailModel.gender = gender;
  }

  // End

  // Job Seeker Skill

  jobSeekerSkill: JobSeekerSkillModel = {
    id: 0,
    jobSeekerId: 0,
    nameEn: '',
    nameAr: ''
  }
  jobSeekerSkillList: Array<JobSeekerSkillModel> = [];

  // End

  // Job Seeker Profile Summary

  submitProfile: boolean = false;
  showEdit: boolean = false;

  jobSeekerProfileSummary: JobSeekerProfileSummaryModel = {
    id: 0,
    jobSeekerId: this.authService.getAuthStorage().jobseekerId,
    summary: ''
  }
  jobSeekerProfileSummaryMandatory = [
    "summary"
  ]

  showEditToggle() {
    this.showEdit = !this.showEdit;
  }

  saveProfileSummary() {
    if (this.masterService.validate(this.jobSeekerProfileSummaryMandatory, this.jobSeekerProfileSummary)) {
      //Api call
      if (this.jobSeekerProfileSummary.id == 0) {
        this.jobSeekerSrv.saveProfileSummary(this.jobSeekerProfileSummary).subscribe((data) => {
          this.getProfileSummary(this.authService.getAuthStorage().jobseekerId);
          this.tost.success("Operation Succeeded");
        });
      } else {
        this.jobSeekerSrv.updateProfileSummary(this.jobSeekerProfileSummary).subscribe((data) => {
          this.getProfileSummary(this.authService.getAuthStorage().jobseekerId);
          this.tost.success("Operation Succeeded");
        });;
      }
    } else {
      this.submitProfile = true;
    }
  }
  getProfileSummary(id: string) {
    this.jobSeekerSrv.getProfileSummary(id).subscribe((data) => {
      this.jobSeekerProfileSummary = data;
    });
  }

  // End

  // Job Seeker Education Detail

  submitEducation: boolean = false;
  jobSeekerEducationDetail: JobSeekerEducationDetailModel = {
    id: 0,
    jobSeekerId: this.authService.getAuthStorage().jobseekerId,
    courseId: 0,
    specialization: '',
    instituteName: '',
    instituteLocationId: 0,
    passingYear: undefined,
  }
  jobSeekerEducationDetailList: Array<JobSeekerEducationDetailModel> = [];
  jobSeekerEducationMadatory = [
    "courseId",
    "specialization",
    "instituteName",
    "instituteLocationId",
    'passingYear'
  ]

  assignInstituteLocationId(id: Number) {
    this.jobSeekerEducationDetail.instituteLocationId = id;
  }
  assignCourseId(id: Number) {
    this.jobSeekerEducationDetail.courseId = id;
  }

  saveEducation() {
   
    if (this.masterService.validate(this.jobSeekerEducationMadatory, this.jobSeekerEducationDetail)) {
      //Api call
      this.jobSeekerEducationDetail.passingYear = parseInt(this.jobSeekerEducationDetail.passingYear.toString());
      if (this.jobSeekerEducationDetail.id == 0) {
        this.jobSeekerSrv.saveEducationDetails(this.jobSeekerEducationDetail).subscribe((data) => {
          this.getEducationAll(this.authService.getAuthStorage().jobseekerId);
          this.tost.success("Operation Succeeded");
          (document.getElementById("close-btn2") as any).click();

          this.newEducation();
        });
      } else {
        this.jobSeekerSrv.updateEducationDetails(this.jobSeekerEducationDetail).subscribe((data) => {
          this.getEducationAll(this.authService.getAuthStorage().jobseekerId);
          this.tost.success("Operation Succeeded");
          (document.getElementById("close-btn2") as any).click();

          this.newEducation();

        });
      }
    } else {
      this.submitEducation = true;
    }
  }
  newEducation() {
    this.jobSeekerEducationDetail = {
      id: 0,
      jobSeekerId: this.authService.getAuthStorage().jobseekerId,
      courseId: 0,
      specialization: '',
      instituteName: '',
      instituteLocationId: 0,
      passingYear: undefined,
    }
  }
  getByIdEducation(id: Number) {
    this.jobSeekerSrv.getEducationById(id).subscribe((data) => {
      this.jobSeekerEducationDetail = data;
    });
  }
  deleteEducation(id: Number) {
    this.jobSeekerSrv.deleteEducationById(id).subscribe((data) => {
      this.getEducationAll(this.authService.getAuthStorage().jobseekerId);
      this.tost.success("Operation Succeeded");
    });
  }
  getEducationAll(id: Number) {
    this.jobSeekerSrv.getEducationAll(id).subscribe((data) => {
      this.jobSeekerEducationDetailList = data;
    });
  }

  // Ended Job Seeker Education Detail


  // Job Seeker Employment Detail

  submitEmployment: boolean = false;
  jobSeekerEmploymentDetail: JobSeekerEmploymentDetailModel = {
    id: 0,
    jobSeekerId: this.authService.getAuthStorage().jobseekerId,
    designation: '',
    companyName: '',
    companyLocation: '',
    isCurrentCompany: false,
    fromMonth: '',
    fromYear: 0,
    toMonth: '',
    toYear: 0,
    jobProfile: ''
  }
  jobSeekerEmploymentDetailList: Array<JobSeekerEmploymentDetailModel> = [];
  jobSeekerEmploymentMandatory = [
    'designation',
    'companyName',
    'companyLocation',
    'fromMonth',
    'fromYear',
    'toMonth',
    'toYear',
    'jobProfile'
  ]
  currEmployee: boolean = false;
  saveEmployment() {
    console.log(this.masterService.validate(this.jobSeekerEmploymentMandatory, this.jobSeekerEmploymentDetail), "yoyoyy")
    if (this.currEmployee) {
      this.jobSeekerEmploymentDetail.fromMonth = this.jobSeekerEmploymentDetail.fromMonth.toString();
      this.jobSeekerEmploymentDetail.toMonth = "12";
      this.jobSeekerEmploymentDetail.toYear = 2099;
      this.jobSeekerEmploymentDetail.fromYear = parseInt(this.jobSeekerEmploymentDetail.fromYear.toString());
    }
    if (this.masterService.validate(this.jobSeekerEmploymentMandatory, this.jobSeekerEmploymentDetail)) {
      //Api call
      console.log(this.currEmployee)
      if (!this.currEmployee) {
        this.jobSeekerEmploymentDetail.fromMonth = this.jobSeekerEmploymentDetail.fromMonth.toString();
        this.jobSeekerEmploymentDetail.toMonth = this.jobSeekerEmploymentDetail.toMonth.toString();
        this.jobSeekerEmploymentDetail.toYear = parseInt(this.jobSeekerEmploymentDetail.toYear.toString());
        this.jobSeekerEmploymentDetail.fromYear = parseInt(this.jobSeekerEmploymentDetail.fromYear.toString());
      } else {
        this.jobSeekerEmploymentDetail.fromMonth = this.jobSeekerEmploymentDetail.fromMonth.toString();
        this.jobSeekerEmploymentDetail.toMonth = "12";
        this.jobSeekerEmploymentDetail.toYear = 2099;
        this.jobSeekerEmploymentDetail.fromYear = parseInt(this.jobSeekerEmploymentDetail.fromYear.toString());
      }
      if (this.jobSeekerEmploymentDetail.id == 0) {
        this.jobSeekerSrv.saveEmploymentDetails(this.jobSeekerEmploymentDetail).subscribe((data) => {
          this.getEmploymentAll(this.authService.getAuthStorage().jobseekerId);
          (document.getElementById("close-btn1") as any).click();

          this.newEmployment();

          this.tost.success("Operation Succeeded");
        });
      } else {
        this.jobSeekerSrv.updateEmploymentDetails(this.jobSeekerEmploymentDetail).subscribe((data) => {
          this.getEmploymentAll(this.authService.getAuthStorage().jobseekerId);
          (document.getElementById("close-btn1") as any).click();
          this.newEmployment();
          this.tost.success("Operation Succeeded");
        });;
      }
    } else {
      this.submitEmployment = true;
    }
  }
  newEmployment() {
    this.jobSeekerEmploymentDetail = {
      id: 0,
      jobSeekerId: this.authService.getAuthStorage().jobseekerId,
      designation: '',
      companyName: '',
      companyLocation: '',
      isCurrentCompany: false,
      fromMonth: '',
      fromYear: 0,
      toMonth: '',
      toYear: 0,
      jobProfile: ''
    }
    this.currEmployee = false;
  }
  getByIdEmployment(id: Number) {
    this.jobSeekerSrv.getEmploymentById(id).subscribe((data) => {
      this.jobSeekerEmploymentDetail = data;
      if(data.toYear == 2099){
        this.currEmployee = true;
      }
    });
  }
  deleteEmployment(id: Number) {
    this.jobSeekerSrv.deleteEmploymentById(id).subscribe((data) => {
      this.getEmploymentAll(this.authService.getAuthStorage().jobseekerId);
      this.tost.success("Operation Succeeded");
    });
  }
  getEmploymentAll(id: Number) {
    this.jobSeekerSrv.getEmploymentAll(id).subscribe((data) => {
      this.jobSeekerEmploymentDetailList = data;
    });
  }
  // Ended Job Seeker Employment Detail


  assignNationality(id: Number) {
    this.jobSeekerPersonalDetailModel.nationalityId = id;
  }

  assignCity(id: Number) {
    this.jobSeekerPersonalDetailModel.currentCityId = id;
  }

  assignMartialStatus(id: Number) {
    this.jobSeekerPersonalDetailModel.maritalStatusId = id;
  }

  assignCountry(id: Number) {
    this.jobSeekerPersonalDetailModel.issuingCountryId = id;
  }
  assignCurrCountry(id: Number) {
    this.jobSeekerPersonalDetailModel.currentCountryId = id;

    this.cityList = [];
    this.masterService.getCityByCountryId(id).subscribe((data) => {
      this.cityList = data;
      console.log(this.cityList);
    });
  }

  assignVisaId(id: Number) {
    this.jobSeekerPersonalDetailModel.visaStatusId = id;
  }

  assignReligionId(id: Number) {
    this.jobSeekerPersonalDetailModel.religionId = id;
  }

  assignUrl() {
    this.url = environment.apiUrl + 'Cv/' + this.jobSeekerModel.resumePath;
    this.urlCl = environment.apiUrl + 'CoverLetter/' + this.jobSeekerModel.coverLetterPath;
    this.urlUser = environment.apiUrl + 'ProfilePhoto/' + this.jobSeekerModel.profilePicturePath;
    this.error = false;
    this.cVError = false;
    this.coverLetterError = false;
  }


  handleUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      console.log(reader.result);
      let type: string = reader.result.toString().split(";base64,")[0].split("/")[1];
      if (this.jobSeekerModel.resumePath == "" || this.jobSeekerModel.resumePath == undefined || this.jobSeekerModel.resumePath == null) {
        this.jobSeekerModel.resumePath = "new" + "." + type;
      } else {
        this.jobSeekerModel.resumePath = this.jobSeekerModel.resumePath.split(".")[0] + "." + type;
      }
      this.jobSeekerModel["base64"] = reader.result.toString().split(";base64,")[1];
      this.jobSeekerSrv.addCvProfileInfo(this.jobSeekerModel).subscribe((data) => {

        this.getProfileInfo(this.authService.getAuthStorage().id);
        this.tost.success("Uploaded successfully");
      });
    };
  }
  handleUploadCoverLetter(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      let type: string = reader.result.toString().split(";base64,")[0].split("/")[1];
      if (this.jobSeekerModel.coverLetterPath == "" || this.jobSeekerModel.coverLetterPath == undefined || this.jobSeekerModel.coverLetterPath == null) {
        this.jobSeekerModel.coverLetterPath = "new" + "." + type;
      } else {
        this.jobSeekerModel.coverLetterPath = this.jobSeekerModel.coverLetterPath.split(".")[0] + "." + type;
      }
      this.jobSeekerModel["base64"] = reader.result.toString().split(";base64,")[1];
      this.jobSeekerSrv.addClProfileInfo(this.jobSeekerModel).subscribe((data) => {

        this.getProfileInfo(this.authService.getAuthStorage().id);
        this.tost.success("Uploaded successfully");
      });
    };
  }
  handleUploadPic(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      console.log(reader.result);
      let type: string = reader.result.toString().split(";base64,")[0].split("/")[1];
      if (this.jobSeekerModel.profilePicturePath == "" || this.jobSeekerModel.profilePicturePath == undefined || this.jobSeekerModel.profilePicturePath == null) {
        this.jobSeekerModel.profilePicturePath = "new" + "." + type;
      } else {
        this.jobSeekerModel.profilePicturePath = this.jobSeekerModel.profilePicturePath.split(".")[0] + "." + type;
      }
      this.jobSeekerModel["base64"] = reader.result.toString().split(";base64,")[1];
      this.jobSeekerSrv.addPicProfileInfo(this.jobSeekerModel).subscribe((data) => {
        this.assignUrl();
        this.tost.success("Uploaded successfully");
        window.location.reload();
      });
      // console.log(reader.result,type);
    };
  }
  assignSalaryYear(val: string) {


  }

  toggle(index: number) {
    if (index == 4) {
      this.authService.LogOut();
      this.router.navigateByUrl("/")

      // window.location.href = "/"
    }
    this.selectedIndex = index;
    if (index == 1 || index == 3) {
      this.getByPartiId(this.authService.getAuthStorage().jobseekerId);
    }
    if (index == 5) {
      this.router.navigateByUrl("/change-pwd")

      // window.location.href = "/change-pwd"
    }
  }

  // Get Job By Participant Id
  p: any;
  job: JobsModel = {
    id: 0,
    companyId: this.authService.getAuthStorage().companyId,
    jobTitle: "",
    experieance: "",
    jobTypeId: 0,
    jobLocationId: 0,
    nationalityId: 0,
    applicant: 0,
    gender: "",
    totalNoOfVacancy: 0,
    functinalAreaId: 0,
    jobDescription: "",
    jobViews: 0,
    creationDate: "",
    jobType: null,
    jobLocation: null,
    functinalArea: null,
    nationality: null,
    company: null,
    userDefined1: "",
    userDefined2: "",
    userDefined3: "",
    userDefined4: "",
    countryId: 0,
    country: "",
    keySkill: ''
  }
  jobList: Array<JobsModel> = [];
  paginate(p: any) {
    this.p = p;
  }
  getByPartiId(id: number) {
    this.jobService.getByPartiId(id).subscribe((data) => {
      console.log(data);
      this.jobList = data;
    })
  }

  imageError() {
    this.error = true;
  }

  previewCVError() {
    this.masterService.linkChecker(this.url).subscribe((data: any) => {
      alert("lls")
      this.cVError = true;
    })
  }

  previewCoverLetterError() {
    this.coverLetterError = true;
  }

  selectedObject(list, val) {
    return list.find(n => n.id == val);
  }
}
