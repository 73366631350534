<div class="navbar" *ngIf="selected != -1">
    <div (click)="route(0,'/')" [class]="selected == 0?'ind active':'ind'">
        <svg *ngIf="selected != 0" width="31" height="31" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M9 17.25C8.58579 17.25 8.25 17.5858 8.25 18C8.25 18.4142 8.58579 18.75 9 18.75H15C15.4142 18.75 15.75 18.4142 15.75 18C15.75 17.5858 15.4142 17.25 15 17.25H9Z"
                    fill="#1C274C"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 1.25C11.2919 1.25 10.6485 1.45282 9.95055 1.79224C9.27585 2.12035 8.49642 2.60409 7.52286 3.20832L5.45628 4.4909C4.53509 5.06261 3.79744 5.5204 3.2289 5.95581C2.64015 6.40669 2.18795 6.86589 1.86131 7.46263C1.53535 8.05812 1.38857 8.69174 1.31819 9.4407C1.24999 10.1665 1.24999 11.0541 1.25 12.1672V13.7799C1.24999 15.6837 1.24998 17.1866 1.4027 18.3616C1.55937 19.567 1.88856 20.5401 2.63236 21.3094C3.37958 22.0824 4.33046 22.4277 5.50761 22.5914C6.64849 22.75 8.10556 22.75 9.94185 22.75H14.0581C15.8944 22.75 17.3515 22.75 18.4924 22.5914C19.6695 22.4277 20.6204 22.0824 21.3676 21.3094C22.1114 20.5401 22.4406 19.567 22.5973 18.3616C22.75 17.1866 22.75 15.6838 22.75 13.7799V12.1672C22.75 11.0541 22.75 10.1665 22.6818 9.4407C22.6114 8.69174 22.4646 8.05812 22.1387 7.46263C21.8121 6.86589 21.3599 6.40669 20.7711 5.95581C20.2026 5.5204 19.4649 5.06262 18.5437 4.49091L16.4771 3.20831C15.5036 2.60409 14.7241 2.12034 14.0494 1.79224C13.3515 1.45282 12.7081 1.25 12 1.25ZM8.27953 4.50412C9.29529 3.87371 10.0095 3.43153 10.6065 3.1412C11.1882 2.85833 11.6002 2.75 12 2.75C12.3998 2.75 12.8118 2.85833 13.3935 3.14119C13.9905 3.43153 14.7047 3.87371 15.7205 4.50412L17.7205 5.74537C18.6813 6.34169 19.3559 6.76135 19.8591 7.1467C20.3487 7.52164 20.6303 7.83106 20.8229 8.18285C21.0162 8.53589 21.129 8.94865 21.1884 9.58104C21.2492 10.2286 21.25 11.0458 21.25 12.2039V13.725C21.25 15.6959 21.2485 17.1012 21.1098 18.1683C20.9736 19.2163 20.717 19.8244 20.2892 20.2669C19.8649 20.7058 19.2871 20.9664 18.2858 21.1057C17.2602 21.2483 15.9075 21.25 14 21.25H10C8.09247 21.25 6.73983 21.2483 5.71422 21.1057C4.71286 20.9664 4.13514 20.7058 3.71079 20.2669C3.28301 19.8244 3.02642 19.2163 2.89019 18.1683C2.75149 17.1012 2.75 15.6959 2.75 13.725V12.2039C2.75 11.0458 2.75076 10.2286 2.81161 9.58104C2.87103 8.94865 2.98385 8.53589 3.17709 8.18285C3.36965 7.83106 3.65133 7.52164 4.14092 7.1467C4.6441 6.76135 5.31869 6.34169 6.27953 5.74537L8.27953 4.50412Z"
                    fill="#1C274C"></path>
            </g>
        </svg>
        <svg *ngIf="selected == 0" width="31" height="31" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M22 12.2039V13.725C22 17.6258 22 19.5763 20.8284 20.7881C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.7881C2 19.5763 2 17.6258 2 13.725V12.2039C2 9.91549 2 8.77128 2.5192 7.82274C3.0384 6.87421 3.98695 6.28551 5.88403 5.10813L7.88403 3.86687C9.88939 2.62229 10.8921 2 12 2C13.1079 2 14.1106 2.62229 16.116 3.86687L18.116 5.10812C20.0131 6.28551 20.9616 6.87421 21.4808 7.82274"
                    stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
                <path d="M15 18H9" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path>
            </g>
        </svg>
        <p [class]="selected == 0?'text activeText':'text'">Home</p>

    </div>

    <div (click)="route(1,'/view-company')" [class]="selected == 1?'ind active':'ind'">
        <svg *ngIf="selected != 1" width="31" height="31" fill="#000000" viewBox="0 0 50 50"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M8 2L8 6L4 6L4 48L46 48L46 14L30 14L30 6L26 6L26 2 Z M 10 4L24 4L24 8L28 8L28 46L19 46L19 39L15 39L15 46L6 46L6 8L10 8 Z M 10 10L10 12L12 12L12 10 Z M 14 10L14 12L16 12L16 10 Z M 18 10L18 12L20 12L20 10 Z M 22 10L22 12L24 12L24 10 Z M 10 15L10 19L12 19L12 15 Z M 14 15L14 19L16 19L16 15 Z M 18 15L18 19L20 19L20 15 Z M 22 15L22 19L24 19L24 15 Z M 30 16L44 16L44 46L30 46 Z M 32 18L32 20L34 20L34 18 Z M 36 18L36 20L38 20L38 18 Z M 40 18L40 20L42 20L42 18 Z M 10 21L10 25L12 25L12 21 Z M 14 21L14 25L16 25L16 21 Z M 18 21L18 25L20 25L20 21 Z M 22 21L22 25L24 25L24 21 Z M 32 22L32 24L34 24L34 22 Z M 36 22L36 24L38 24L38 22 Z M 40 22L40 24L42 24L42 22 Z M 32 26L32 28L34 28L34 26 Z M 36 26L36 28L38 28L38 26 Z M 40 26L40 28L42 28L42 26 Z M 10 27L10 31L12 31L12 27 Z M 14 27L14 31L16 31L16 27 Z M 18 27L18 31L20 31L20 27 Z M 22 27L22 31L24 31L24 27 Z M 32 30L32 32L34 32L34 30 Z M 36 30L36 32L38 32L38 30 Z M 40 30L40 32L42 32L42 30 Z M 10 33L10 37L12 37L12 33 Z M 14 33L14 37L16 37L16 33 Z M 18 33L18 37L20 37L20 33 Z M 22 33L22 37L24 37L24 33 Z M 32 34L32 36L34 36L34 34 Z M 36 34L36 36L38 36L38 34 Z M 40 34L40 36L42 36L42 34 Z M 32 38L32 40L34 40L34 38 Z M 36 38L36 40L38 40L38 38 Z M 40 38L40 40L42 40L42 38 Z M 10 39L10 44L12 44L12 39 Z M 22 39L22 44L24 44L24 39 Z M 32 42L32 44L34 44L34 42 Z M 36 42L36 44L38 44L38 42 Z M 40 42L40 44L42 44L42 42Z">
                </path>
            </g>
        </svg>
        <svg *ngIf="selected == 1" width="31" height="31" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" stroke="#ffffff">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path
                    d="M8 2L8 6L4 6L4 48L46 48L46 14L30 14L30 6L26 6L26 2 Z M 10 4L24 4L24 8L28 8L28 46L19 46L19 39L15 39L15 46L6 46L6 8L10 8 Z M 10 10L10 12L12 12L12 10 Z M 14 10L14 12L16 12L16 10 Z M 18 10L18 12L20 12L20 10 Z M 22 10L22 12L24 12L24 10 Z M 10 15L10 19L12 19L12 15 Z M 14 15L14 19L16 19L16 15 Z M 18 15L18 19L20 19L20 15 Z M 22 15L22 19L24 19L24 15 Z M 30 16L44 16L44 46L30 46 Z M 32 18L32 20L34 20L34 18 Z M 36 18L36 20L38 20L38 18 Z M 40 18L40 20L42 20L42 18 Z M 10 21L10 25L12 25L12 21 Z M 14 21L14 25L16 25L16 21 Z M 18 21L18 25L20 25L20 21 Z M 22 21L22 25L24 25L24 21 Z M 32 22L32 24L34 24L34 22 Z M 36 22L36 24L38 24L38 22 Z M 40 22L40 24L42 24L42 22 Z M 32 26L32 28L34 28L34 26 Z M 36 26L36 28L38 28L38 26 Z M 40 26L40 28L42 28L42 26 Z M 10 27L10 31L12 31L12 27 Z M 14 27L14 31L16 31L16 27 Z M 18 27L18 31L20 31L20 27 Z M 22 27L22 31L24 31L24 27 Z M 32 30L32 32L34 32L34 30 Z M 36 30L36 32L38 32L38 30 Z M 40 30L40 32L42 32L42 30 Z M 10 33L10 37L12 37L12 33 Z M 14 33L14 37L16 37L16 33 Z M 18 33L18 37L20 37L20 33 Z M 22 33L22 37L24 37L24 33 Z M 32 34L32 36L34 36L34 34 Z M 36 34L36 36L38 36L38 34 Z M 40 34L40 36L42 36L42 34 Z M 32 38L32 40L34 40L34 38 Z M 36 38L36 40L38 40L38 38 Z M 40 38L40 40L42 40L42 38 Z M 10 39L10 44L12 44L12 39 Z M 22 39L22 44L24 44L24 39 Z M 32 42L32 44L34 44L34 42 Z M 36 42L36 44L38 44L38 42 Z M 40 42L40 44L42 44L42 42Z">
                </path>
            </g>
        </svg>
        <p [class]="selected == 1?'text activeText':'text'">Company</p>

    </div>
    <div (click)="route(2,'/view-job')" [class]="selected == 2?'ind active':'ind'">
        <svg width="31" height="31" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 392.598 392.598" xml:space="preserve" fill="#ffffff"
            stroke="#ffffff">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path style="fill:#FFFFFF;"
                    d="M150.073,238.158c0-51.459,41.891-93.285,93.285-93.285c13.964,0,27.216,3.168,39.111,8.727v-49.455 h-71.305c-2.844,0-5.624-1.164-7.758-3.168c-2.004-2.004-3.168-4.784-3.168-7.758V21.786H32.739 c-6.012,0-10.925,4.848-10.925,10.925v327.111c0,6.012,4.848,10.925,10.925,10.925h238.933c6.012,0,10.925-4.848,10.925-10.925 v-37.107c-11.895,5.56-25.083,8.727-39.111,8.727C191.964,331.442,150.073,289.552,150.073,238.158z">
                </path>
                <polygon style="fill:#000000;" points="222.089,37.236 222.089,82.295 267.147,82.295 "></polygon>
                <path style="fill:#FFFFFF;"
                    d="M243.422,166.594c-39.434,0-71.499,32.065-71.499,71.499s32.129,71.434,71.499,71.434 s71.499-32.065,71.499-71.499S282.857,166.594,243.422,166.594z">
                </path>
                <path style="fill:#000000;"
                    d="M243.422,287.806c-27.41,0-49.713-22.238-49.713-49.713s22.238-49.713,49.713-49.713 s49.713,22.238,49.713,49.713C293.135,265.503,270.768,287.806,243.422,287.806z">
                </path>
                <g>
                    <path style="fill:#000000;"
                        d="M57.822,166.723h90.505c6.012,0,10.925-4.848,10.925-10.925c0-6.012-4.848-10.925-10.925-10.925 H57.822c-6.012,0-10.925,4.848-10.925,10.925C46.897,161.81,51.81,166.723,57.822,166.723z">
                    </path>
                    <path style="fill:#000000;"
                        d="M57.822,222.836h66.715c6.012,0,10.925-4.848,10.925-10.925c0-6.012-4.848-10.925-10.925-10.925 H57.822c-6.012,0-10.925,4.848-10.925,10.925S51.81,222.836,57.822,222.836z">
                    </path>
                    <path style="fill:#000000;"
                        d="M57.822,278.95h66.715c6.012,0,10.925-4.849,10.925-10.925s-4.848-10.925-10.925-10.925H57.822 c-6.012,0-10.925,4.849-10.925,10.925S51.81,278.95,57.822,278.95z">
                    </path>
                    <path style="fill:#000000;"
                        d="M148.327,313.277H57.822c-6.012,0-10.925,4.848-10.925,10.925c0,6.012,4.848,10.925,10.925,10.925 h90.505c6.012,0,10.925-4.848,10.925-10.925C159.188,318.125,154.339,313.277,148.327,313.277z">
                    </path>
                </g>
                <g>
                    <path style="fill:#000000;"
                        d="M46.444,53.333h14.675v26.505c0,7.434-4.073,8.275-5.624,8.275c-3.232,0-6.335-1.875-9.374-5.495 l-5.689,9.051c4.396,5.236,9.632,7.887,15.515,7.887c4.784,0,16.291-1.939,16.291-20.17V42.537H46.444V53.333z">
                    </path>
                    <path style="fill:#000000;"
                        d="M171.859,69.624c0.84-0.453,7.24-2.78,7.24-12.218c0-14.998-14.158-14.998-17.455-14.998H142.38 v56.501h21.657c6.788,0,17.39-1.939,17.842-15.709C182.202,73.568,174.962,70.4,171.859,69.624z M153.499,53.139h5.301 c3.232,0,5.56,0.388,6.982,1.228c1.422,0.776,2.069,2.457,2.069,4.913c0,2.457-0.776,4.202-2.263,4.848 c-1.487,0.776-3.814,1.164-6.788,1.164h-5.236V53.075h-0.065V53.139z M168.174,87.014c-1.552,0.905-3.943,1.293-7.111,1.293h-7.564 V75.313h6.465c3.685,0,6.335,0.388,8.016,1.228c1.681,0.84,2.457,2.521,2.457,5.172C170.566,84.364,169.725,86.044,168.174,87.014z ">
                    </path>
                    <path style="fill:#000000;"
                        d="M107.147,41.18c-17.261,0-25.988,15.709-25.988,29.22c0,11.055,6.853,29.22,25.988,29.22 c17.261,0,25.988-15.709,25.988-29.22C133.071,59.345,126.218,41.18,107.147,41.18z M121.822,70.4 c0,8.727-5.624,18.166-14.675,18.166c-7.564,0-14.675-7.564-14.675-18.166h0.065c0-8.727,5.624-18.166,14.675-18.166 C114.776,52.17,121.822,59.733,121.822,70.4L121.822,70.4z">
                    </path>
                    <path style="fill:#000000;"
                        d="M312.659,300.671c-0.065,0-0.129,0-0.259,0c-2.521,2.78-5.172,5.495-8.145,7.952v7.758 l51.911,51.911c3.168,3.168,8.792,3.168,11.96,0c3.297-3.297,3.297-8.663,0-11.96L312.659,300.671z">
                    </path>
                </g>
                <path style="fill:#000000;"
                    d="M383.705,340.816l-58.311-58.44c7.176-13.188,11.442-28.186,11.442-44.283 c0-28.121-12.606-53.398-32.388-70.465V93.156c0-2.844-1.164-5.624-3.168-7.758l-82.36-82.23C216.917,1.164,214.137,0,211.164,0 H32.739C14.703,0,0.028,14.675,0.028,32.711v327.111c0,18.101,14.675,32.776,32.711,32.776h238.933 c18.036,0,32.711-14.675,32.711-32.711v-12.671l36.461,36.461c12.283,12.671,32.517,10.99,42.796,0 C395.535,371.846,395.535,352.646,383.705,340.816z M368.319,368.291c-3.232,3.168-8.792,3.168-11.96,0l-51.911-51.911v-7.758 c2.844-2.457,5.56-5.172,8.145-7.952c0.065,0,0.129,0,0.259,0l55.661,55.661C371.551,359.564,371.551,364.929,368.319,368.291z M314.921,238.158c0,39.434-32.065,71.499-71.499,71.499s-71.499-32.065-71.499-71.499s32.129-71.499,71.499-71.499 C282.857,166.594,314.921,198.659,314.921,238.158z M222.089,37.236l45.059,45.059h-45.059L222.089,37.236L222.089,37.236z M282.533,359.822c0,6.012-4.848,10.925-10.925,10.925H32.739c-6.012,0-10.925-4.848-10.925-10.925V32.711 c0-6.012,4.848-10.925,10.925-10.925h167.499v71.305c0,2.844,1.164,5.624,3.168,7.758c2.004,2.004,4.784,3.168,7.758,3.168h71.305 v49.455c-11.895-5.56-25.083-8.727-39.111-8.727c-51.459,0-93.285,41.891-93.285,93.285c0,51.459,41.891,93.285,93.285,93.285 c13.964,0,27.216-3.168,39.111-8.727v37.236H282.533z">
                </path>
            </g>
        </svg>
        <p [class]="selected == 2?'text activeText':'text'">Jobs</p>

    </div>
    <div (click)="route(3,'/user-profile')" [class]="selected == 3?'ind active':'ind'">
        <svg *ngIf="selected != 3" width="31" height="31" viewBox="0 0 20 20" version="1.1"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <title>profile_round [#1346]</title>
                <desc>Created with Sketch.</desc>
                <defs> </defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Dribbble-Light-Preview" transform="translate(-380.000000, -2119.000000)" fill="#000000">
                        <g id="icons" transform="translate(56.000000, 160.000000)">
                            <path
                                d="M338.083123,1964.99998 C338.083123,1962.79398 336.251842,1960.99998 334,1960.99998 C331.748158,1960.99998 329.916877,1962.79398 329.916877,1964.99998 C329.916877,1967.20599 331.748158,1968.99999 334,1968.99999 C336.251842,1968.99999 338.083123,1967.20599 338.083123,1964.99998 M341.945758,1979 L340.124685,1979 C339.561214,1979 339.103904,1978.552 339.103904,1978 C339.103904,1977.448 339.561214,1977 340.124685,1977 L340.5626,1977 C341.26898,1977 341.790599,1976.303 341.523154,1975.662 C340.286989,1972.69799 337.383888,1970.99999 334,1970.99999 C330.616112,1970.99999 327.713011,1972.69799 326.476846,1975.662 C326.209401,1976.303 326.73102,1977 327.4374,1977 L327.875315,1977 C328.438786,1977 328.896096,1977.448 328.896096,1978 C328.896096,1978.552 328.438786,1979 327.875315,1979 L326.054242,1979 C324.778266,1979 323.773818,1977.857 324.044325,1976.636 C324.787453,1973.27699 327.107688,1970.79799 330.163906,1969.67299 C328.769519,1968.57399 327.875315,1966.88999 327.875315,1964.99998 C327.875315,1961.44898 331.023403,1958.61898 334.733941,1959.04198 C337.422678,1959.34798 339.650022,1961.44698 340.05323,1964.06998 C340.400296,1966.33099 339.456073,1968.39599 337.836094,1969.67299 C340.892312,1970.79799 343.212547,1973.27699 343.955675,1976.636 C344.226182,1977.857 343.221734,1979 341.945758,1979 M337.062342,1978 C337.062342,1978.552 336.605033,1979 336.041562,1979 L331.958438,1979 C331.394967,1979 330.937658,1978.552 330.937658,1978 C330.937658,1977.448 331.394967,1977 331.958438,1977 L336.041562,1977 C336.605033,1977 337.062342,1977.448 337.062342,1978"
                                id="profile_round-[#1346]"> </path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <svg *ngIf="selected == 3" width="31" height="31" viewBox="0 0 20 20" version="1.1"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#ffffff">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <title>profile_minus [#1340]</title>
                <desc>Created with Sketch.</desc>
                <defs> </defs>
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Dribbble-Light-Preview" transform="translate(-220.000000, -2159.000000)" fill="#ffffff">
                        <g id="icons" transform="translate(56.000000, 160.000000)">
                            <path
                                d="M178,2005 C178,2002.794 176.206,2001 174,2001 C171.794,2001 170,2002.794 170,2005 C170,2007.206 171.794,2009 174,2009 C176.206,2009 178,2007.206 178,2005 L178,2005 Z M184,2019 L179,2019 L179,2017 L181.784,2017 C180.958,2013.214 177.785,2011 174,2011 C170.215,2011 167.042,2013.214 166.216,2017 L169,2017 L169,2019 L164,2019 C164,2014.445 166.583,2011.048 170.242,2009.673 C168.876,2008.574 168,2006.89 168,2005 C168,2001.686 170.686,1999 174,1999 C177.314,1999 180,2001.686 180,2005 C180,2006.89 179.124,2008.574 177.758,2009.673 C181.417,2011.048 184,2014.445 184,2019 L184,2019 Z M171,2019 L177,2019 L177,2017 L171,2017 L171,2019 Z"
                                id="profile_minus-[#1340]"> </path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        <p [class]="selected == 3?'text activeText':'text'">Profile</p>
    </div>
</div>