import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CompanyFilterModel } from 'src/app/model/CompanyFilter';
import { CompanyHomeModel } from 'src/app/model/CompanyHome';
import { CompanyModel } from 'src/app/model/Compnay';
import { JobsHomeModel } from 'src/app/model/JoblHome';
import { JobsModel } from 'src/app/model/Jobs';
import { CityModel } from 'src/app/model/master/City';
import { CourseModel } from 'src/app/model/master/Course';
import { FunctionalAreaModel } from 'src/app/model/master/FunctionalArea';
import { AuthService } from 'src/app/services/auth.service';
import { BlogService } from 'src/app/services/blog.service';
import { CompanyService } from 'src/app/services/company.service';
import { JobService } from 'src/app/services/job.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MasterService } from 'src/app/services/master.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-three',
  templateUrl: './home-three.component.html',
  styleUrls: ['./home-three.component.scss']
})
export class HomeThreeComponent implements OnInit {

  masterService: MasterService;
  baseUrl: string = environment.apiUrl + "ProfilePhoto/";
  baseBlogUrl: string = environment.blogImg + "BlogsPic/";
  error: boolean = false;
  constructor(masterService: MasterService, private jobSrv: JobService,
    private companySrv: CompanyService,
    private authService: AuthService,
    public loader: LoaderService,
    private blogService: BlogService,
    private router: Router,
  ) {
    this.masterService = masterService;
    this.loader.loader = true;
  }
  blogList: any = []
  course: FunctionalAreaModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
    count: 0
  }
  courseList: Array<FunctionalAreaModel> = [];
  search: string = "";

  city: CityModel = {
    id: 0,
    nameEn: "",
    nameAr: "",
  }
  cityList: Array<CityModel> = [];

  ngOnInit(): void {
    this.loader.loader = true;
    this.masterService.getfunctionalAreaAll().subscribe((data) => {
      this.courseList = data;
      if (data.length > 9) {
        this.courseList.splice(9, data.length);
      }
      this.loader.loader = false;
    })
    this.masterService.getCityAll().subscribe((data) => {
      this.cityList = data;
      var ua = navigator.userAgent;

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        if (data.length > 6) {
          this.cityList.splice(6, data.length);
        }
      } else if (data.length > 9) {
        this.cityList.splice(9, data.length);
      }
    })
    this.blogService.getAll().subscribe((response) => {
      console.log(response)
      this.blogList = response;
    });
    // this.dataSource.sort = this.sort;
    this.getJobList();
    this.getCompanyList();

  }
  filter(event: any) {
    this.search = event.target.value;
  }
  job: JobsHomeModel = {
    id: 0,
    companyId: this.authService.getAuthStorage().companyId,
    jobTitle: "",
    experieance: "",
    jobTypeId: 0,
    jobLocationId: 0,
    nationalityId: 0,
    gender: "",
    totalNoOfVacancy: 0,
    functinalAreaId: 0,
    jobDescription: "",
    applicant: 0,
    jobViews: 0,
    creationDate: "",
    jobType: null,
    jobLocation: null,
    functinalArea: null,
    nationality: null,
    company: null,
    userDefined1: "",
    userDefined2: "",
    userDefined3: "",
    userDefined4: "",
    countryId: 0,
    country: "",
    keySkill: '',
    error: false
  }
  jobList: Array<JobsHomeModel> = [];
  jobListShow: Array<JobsHomeModel> = [];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay: false,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    responsive: {
    },
    nav: true
  }

  /* Job by employer */
  company: CompanyHomeModel = {
    id: 0,
    creationUserName: '',
    creationDate: '',
    lastUpdateUserName: '',
    lastUpdateDate: '',
    deletedOn: '',
    deletedBy: '',
    nameEn: '',
    nameAr: '',
    descriptions: '',
    emailId: '',
    locationId: 0,
    phoneNo: '',
    industryId: 0,
    crnumber: '',
    location: undefined,
    industry: undefined,
    establishmentYear: 0,
    address: '',
    userDefined1: '',
    userDefined2: '',
    userDefined3: '',
    userDefined4: '',
    countryId: 0,
    country: '',
    error: false
  }
  companyList: Array<CompanyHomeModel> = [];

  companyFilter: CompanyFilterModel = {
    locationId: [],
    industryId: [],
    companyName: '',
    countPerPage: 0,
    page: 0
  }

  getCompanyList() {
    this.loader.loader = true;

    this.companySrv.getByFilter(this.companyFilter).subscribe((data) => {
      data = data.reverse()
      this.companyList = data;
      if (data.length > 6) {
        this.companyList.splice(6, data.length);
      }
      this.loader.loader = false;
    });
  }
  /******************/

  getJobList() {
    this.jobSrv.getForHomePage().subscribe((data) => {
      this.jobList = data;
      this.jobListShow = data;
      if (this.jobList.length > 6) {
        for (let i = 0; i < 6; i++) {
          this.jobListShow.push(this.jobList[i]);
        }
      }
      this.loader.loader = false;
    });
  }

  resume() {
    let auth = this.authService.isAuth();
    if (auth) {
      let val = this.authService.getAuthStorage();
      if (val.type == "user") {
        this.router.navigateByUrl("/user-profile")

        // window.location.href = "/user-profile";
      }
    } else {
      this.router.navigateByUrl("/login")

      // window.location.href = "/login"
    }
  }

  imageError(i: number) {
    this.jobListShow[i].error = true;
  }
  imageErrorComp(i: number) {
    this.companyList[i].error = true;
  }

  urlChange(url: string, item) {
    try {
      // url = url + "&job=" + (item.jobTitle as any).toString().replaceAll(" ", "-").toLowerCase() + "-in-company-" + item?.company[0]['nameEn'].replaceAll(" ", "-").toLowerCase() + "-" + "at-location" + "-" + item.jobLocation.replaceAll(" ", "-").toLowerCase() + "-" + item?.country?.nameEn.replaceAll(" ", "-").toLowerCase();
      let readPath = "" + (item.jobTitle as any).toString().replaceAll(" ", "-").toLowerCase() + "-in-company-" + item?.company[0]['nameEn'].replaceAll(" ", "-").toLowerCase() + "-" + "at-location" + "-" + item.jobLocation.replaceAll(" ", "-").toLowerCase() + "-" + item?.country?.nameEn.replaceAll(" ", "-").toLowerCase();
      this.router.navigate(['/job-detail'], { queryParams: { Id: item.id, job: readPath } });
      // return url;
    } catch (e) {
      return url;
    }
  }

  handelCardClick(id: number) {
    this.router.navigateByUrl(`/blog-details/${id}`)
  }

}
