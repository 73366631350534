import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JobApplyService {
  private jobpath = environment.apiUrl + 'JobApply/';

  private eventguestpath = environment.apiUrl + 'EventGuests/';

  constructor(private http: HttpClient) { }

  create(data: any): Observable<any> {
    return this.http.post<any>(this.jobpath + 'create', data);
  }

  getById(id: Number): Observable<any> {
    return this.http.get<any>(this.jobpath + 'getById?Id=' + id);
  }

  getByJobId(id: Number): Observable<any> {
    return this.http.get<any>(this.jobpath + 'getByJobId?Id=' + id);
  }

  getByJobSeekerId(id: Number): Observable<any> {
    return this.http.get<any>(this.jobpath + 'getByJobSeekerId?Id=' + id);
  }

  checkJobById(id: Number, seekerId: Number): Observable<any> {
    return this.http.get<any>(this.jobpath + 'checkJobApplied?id=' + id + '&seekerId=' + seekerId);
  }

  saveJobViewId(jobId: Number) {
    localStorage.setItem('jobView' + jobId, 'true');
  }
  hasViewedJob(jobId: Number): boolean {
    let val: string | null | undefined = localStorage.getItem('jobView' + jobId);
    if (val == "true") {
      return true;
    }
    return false;
  }
}