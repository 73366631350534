<!-- HEADER START -->
<header class="site-header header-style-3 mobile-sider-drawer-menu">
    <div class="sticky-wrapper" style="height: 90px">
        <div class="sticky-header main-bar-wraper navbar-expand-lg is-fixed main-nav">
            <div class="main-bar" style="border-bottom: 5px solid #cb3925 !important">
                <div class="container clearfix ">
                    <div class="logo-header">
                        <div class="logo-header-inner logo-header-one d-flex">
                            <div *ngIf="isMobile && selected == -1" (click)="back()"><svg width="39" height="39"
                                    viewBox="0 0 1024 1024" fill="#cb3925" class="icon" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M669.6 849.6c8.8 8 22.4 7.2 30.4-1.6s7.2-22.4-1.6-30.4l-309.6-280c-8-7.2-8-17.6 0-24.8l309.6-270.4c8.8-8 9.6-21.6 2.4-30.4-8-8.8-21.6-9.6-30.4-2.4L360.8 480.8c-27.2 24-28 64-0.8 88.8l309.6 280z"
                                            fill=""></path>
                                    </g>
                                </svg></div>
                            <a routerLink="/">
                                <img src="assets/img/logo_mahad.png" alt="" />
                            </a>
                        </div>

                    </div>

                    <!-- NAV Toggle Button -->
                    <button id="mobile-side-drawer" data-target="#navbarSupportedContent" data-toggle="collapse"
                        (click)="toggle()" type="button" class="navbar-toggler collapsed">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar icon-bar-first"></span>
                        <span class="icon-bar icon-bar-two"></span>
                        <span class="icon-bar icon-bar-three"></span>
                    </button>

                    <!-- MAIN Vav -->
                    <div [class]="'nav-animation header-nav navbar-collapse collapse collapse navbar-collapse mean-menu d-flex justify-content-center yobro ' + (menu?'show-nav':'hide-nav')"
                        id="navbarSupportedContent">
                        <div class="">
                            <ul class="nav navbar-nav">
                                <li class="has-child">
                                    <a routerLink="/">Home</a>
                                    <!-- <div class="fa fa-angle-right submenu-toogle"></div> -->
                                </li>
                                <li class="has-child">
                                    <a routerLink="/about">About</a>
                                    <!-- <div class="fa fa-angle-right submenu-toogle"></div> -->
                                </li>
                                <li class="has-child">
                                    <a routerLink="/view-job">Job Search</a>
                                    <!-- <div class="fa fa-angle-right submenu-toogle"></div> -->
                                </li>
                                <li class="has-child">
                                    <a routerLink="/view-company">Companies </a>
                                    <!-- <div class="fa fa-angle-right submenu-toogle"></div> -->
                                </li>
                                <li class="has-child">
                                    <a routerLink="/contact-us">Contact Us</a>
                                    <!-- <div class="fa fa-angle-right submenu-toogle"></div> -->
                                </li>
                                <li class="has-child">
                                    <a routerLink="/blog">Blogs</a>
                                    <!-- <div class="fa fa-angle-right submenu-toogle"></div> -->
                                </li>
                                <!--  <li class="nav-item" *ngIf="login">
                                    <a class="nav-link" routerLink="/user-profile">User Profile</a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a routerLink="/login" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Login</a>
                                        </li>

                                        <li class="nav-item">
                                            <a routerLink="/register" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                exact: true
                                            }">Register</a>
                                        </li>
                                    </ul>
                                </li> -->
                            </ul>
                        </div>

                        <!-- Header Right Section-->
                        <div class="extra-nav header-2-nav">
                            <!-- <div class="extra-cell">
                        <div class="header-search">
                            <a href="#search" class="header-search-icon"><mat-icon class="feather-search mat-icon">login</mat-icon> </a>
                        </div>
                    </div> -->
                            <div class="extra-cell">
                                <div class="header-nav-btn-section">
                                    <div class="twm-nav-btn-left" *ngIf="!IsAuthenticated">
                                        <a class="twm-nav-sign-up" routerLink="/signup" data-bs-toggle="modal"
                                            href="#sign_up_popup" role="button">
                                            <mat-icon class="feather-search mat-icon">badge</mat-icon><span
                                                class="span-class">Sign Up</span>
                                        </a>
                                    </div>
                                    <div class="twm-nav-btn-left" *ngIf="!IsAuthenticated">
                                        <a class="twm-nav-sign-up" routerLink="/login" data-bs-toggle="modal"
                                            href="#sign_up_popup" role="button">
                                            <mat-icon class="feather-search mat-icon">login</mat-icon><span
                                                class="span-class">Login</span>
                                        </a>
                                    </div>

                                    <div class="twm-nav-btn-left hide-nav" *ngIf="!IsAuthenticated">
                                        <a class="twm-nav-sign-up bg-warning" href="https://employer.mahadjobs.in/"
                                            target="_blank" role="button">
                                            <span class="span-class">Employer</span>
                                        </a>
                                    </div>

                                    <div class="twm-nav-btn-right" *ngIf="IsAuthenticated">
                                        <a routerLink="/user-profile" class="twm-nav-post-a-job">
                                            <mat-icon class="feather-search mat-icon">person</mat-icon>
                                            <span class="span-class">{{
                                                name
                                                }}</span>
                                        </a>
                                    </div>
                                    <div class="twm-nav-btn-right" *ngIf="IsAuthenticated">
                                        <a (click)="logout()" class="twm-nav-post-a-job">
                                            <mat-icon class="feather-search mat-icon">logout</mat-icon>
                                            <span class="span-class">Logout</span>
                                        </a>
                                    </div>
                                    <div class="twm-nav-btn-right" *ngIf="IsAuthenticated" (click)="openNoti()">
                                        <div class="count mt-1">
                                            <span class="mandatory1">{{count}}</span>
                                        </div>
                                        <mat-icon class="mt-2" matBadge="15"
                                            matBadgeColor="warn">notifications</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- SITE Search -->
            <div id="search">
                <span class="close"></span>
                <form role="search" id="searchform" action="/search" method="get" class="radius-xl">
                    <input class="form-control" value="" name="q" type="search" placeholder="Type to search" />
                    <span class="input-group-append">
                        <button type="button" class="search-btn">
                            <i class="fa fa-paper-plane"></i>
                        </button>
                    </span>
                </form>
            </div>
        </div>
    </div>
</header>
<div class="side-nav" *ngIf="sideNav">
    <div class="btn-cont">
        <h4 class="ms-3 noti">Notification Center</h4>
        <mat-icon (click)="close()" class="material-symbols-outlined">
            cancel
        </mat-icon>
    </div>
    <app-side-nav-noti [close]="close"
        [jobSeekerNotificationModelList]="jobSeekerNotificationModelList"></app-side-nav-noti>
</div>