import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  @Input() active:string
  display:string = "none";
  selected:Number = 0;

  constructor(private authSrv:AuthService, private routerLink:Router){}
  ngOnInit(): void {
    let url = window.location.pathname;
    this.change(url);
  }

  change(url:string){
    if(url.includes("employer-dashboard")){
      this.selected = 0;
    } else if(url.includes("employer-company")){
      this.selected = 1;
    } else if(url.includes("employer-postjob") || url.includes("employer-managejob")){
      this.selected = 2;
    } else if(url.includes("employer-viewcandidate")){
      this.selected = 3;
    } else{
      this.selected = 10;
    }
  }

  toggleMenu(){
  
    if(this.display == "none"){
      this.display = "block";
    } else{
      this.display = "none";
    }
  }

  logout(){
    this.authSrv.logout();
    this.routerLink.navigateByUrl("/");
    // window.location.href = "/"
  }

  route(link:string){
    this.change(link);
    this.routerLink.navigate([link]);
  }

}
