import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

    blogList: any = []
    baseUrl: string = environment.blogImg + "BlogsPic/";
    currentYear:string = new Date().getFullYear().toString()
    constructor(
        private router: Router,
        private blogService: BlogService
    ) { }

    ngOnInit(): void {
        this.blogService.getAll().subscribe((response) => {
            console.log(response)
            this.blogList = response;
        });
    }

    handelCardClick(id: number) {
        this.router.navigateByUrl(`/blog-details/${id}`)
    }

}
