import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';

@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.scss'],
  standalone: true,
  imports: [BottombarComponent, CommonModule]

})
export class BottombarComponent implements OnInit {
  urlList: Array<string> = ['/', '/view-company', '/view-job', '/user-profile']
  selected: number = 0;
  constructor(private router: Router) { }
  ngOnInit(): void {
    this.routerListner()
    this.setIndex();
  }
  routerListner() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe((event1: NavigationEnd) => {
        let index = this.urlList.indexOf(event1.url);
        if (event1.url.includes("/view-job")) {
          localStorage.setItem("botomIndex", "2");
          this.selected = 2;
        } else {
          console.log(event1.url, "botombar", index)
          localStorage.setItem("botomIndex", index.toString());
          this.selected = index;
        }
        // if (!(event instanceof NavigationEnd)) {
        //   return;
        // }
      });
  }
  setIndex() {
    this.selected = parseInt(localStorage.getItem("botomIndex") ?? "-1")
  }

  route(index: number, path: string) {
    this.selected = index;
    this.router.navigateByUrl(path);
    localStorage.setItem("botomIndex", index.toString());
  }

}
