import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { JobQuestionModel } from 'src/app/model/JobQuestion';
import { JobSeekerModel } from 'src/app/model/JobSeeker';
import { AuthService } from 'src/app/services/auth.service';
import { JobApplyService } from 'src/app/services/jobApply.service';
import { JobQuestionService } from 'src/app/services/jobQuestion.service';
import { JobSeekerService } from 'src/app/services/jobSeeker';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { QuestionAnswerModel } from 'src/app/model/QuestionAnswer';
import { QuestionAnswerService } from 'src/app/services/questionAnswer.service';
import { JobApplyModel } from 'src/app/model/JobApply';
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-question',
  templateUrl: './job-question.component.html',
  styleUrls: ['./job-question.component.scss']
})
export class JobQuestionComponent implements OnInit {

  @Input() jobId: number = 0;
  @Input() applyToJob: () => void;
  @Input() getJobId: () => void;
  submit: boolean = false;
  questionList: JobQuestionModel[] = [];
  questionAnswerList: QuestionAnswerModel[] = [];
  cVError: boolean = false;
  resumePath: SafeResourceUrl = '';
  coverLetterPath: SafeResourceUrl = '';
  coverLetterError: boolean = false;
  multiPage: boolean = false;
  page: number = 0;
  jobSeekerModel: JobSeekerModel = {
    id: 0,
    userId: this.authService.getAuthStorage().id,
    fullNameEn: '',
    fullNameAr: '',
    cvheadline: '',
    eamil: '',
    mobileNo: '',
    currentCity: '',
    profilePicturePath: '',
    resumePath: '',
    coverLetterPath: ''
  }
  jobApply: JobApplyModel = {
    id: 0,
    jobId: 0,
    jobSeekerId: 0,
    creationDate: '',
    jobSeeker: undefined,
    job: undefined,
  };

  constructor(private authService: AuthService,
    private tost: ToastrService,
    private jobApplyService: JobApplyService,
    private jobSeekerSrv: JobSeekerService,
    private router:Router,
    public loader: LoaderService,
    private questionAnsSrv: QuestionAnswerService,
    private sanitizer: DomSanitizer,
    private jobQuestionSrv: JobQuestionService) {
  }
  ngOnInit(): void {
    this.loader.loader = true;
    this.getQuestion();
    this.getProfileInfo(this.authService.getAuthStorage().id);
  }
  getQuestion() {
    if (this.authService.isAuthenticated()) {
  
      this.jobQuestionSrv.getByJobId(this.jobId.toString()).subscribe((value) => {
        this.questionList = value;
        this.loader.loader = false;
        if (this.questionList.length > 0) {
          for (let i of this.questionList) {
            let answer: QuestionAnswerModel = {
              id: 0,
              jobSeekerId: this.authService.getAuthStorage().jobseekerId,
              questionId: i.id,
              answer: '',
              userDefined1: '',
              question: i
            }
            this.questionAnswerList.push(answer);
          }
          // this.applyJob();
          this.multiPage = true
        }
      })
    } else {
      this.loader.loader = false;;
      this.router.navigateByUrl("/login");
      // window.location.href = "/login";
      this.tost.warning('Please Login');
    }
  }

  getProfileInfo(id: string) {
    this.jobSeekerSrv.getProfileInfo(id).subscribe((data) => {
      this.jobSeekerModel = data;
      this.assignUrl();
    });
  }
  assignUrl() {
    this.resumePath = this.sanitizer.bypassSecurityTrustResourceUrl(environment.apiUrl + 'Cv/' + this.jobSeekerModel.resumePath);

    this.coverLetterPath = this.sanitizer.bypassSecurityTrustResourceUrl(environment.apiUrl + 'CoverLetter/' + this.jobSeekerModel.coverLetterPath);
    this.cVError = false;
    this.coverLetterError = false;
  }
  cvError() {
    this.cVError = true;
  }
  clError() {
    this.coverLetterError = true;
  }
  handleUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      let type: string = reader.result.toString().split(";base64,")[0].split("/")[1];
      if (this.jobSeekerModel.resumePath == "" || this.jobSeekerModel.resumePath == undefined || this.jobSeekerModel.resumePath == null) {
        this.jobSeekerModel.resumePath = "new" + "." + type;
      } else {
        this.jobSeekerModel.resumePath = this.jobSeekerModel.resumePath.split(".")[0] + "." + type;
      }
      this.jobSeekerModel["base64"] = reader.result.toString().split(";base64,")[1];
      this.jobSeekerSrv.addCvProfileInfo(this.jobSeekerModel).subscribe((data) => {

        this.getProfileInfo(this.authService.getAuthStorage().id);
        this.tost.success("Uploaded successfully");
      });
    };
  }

  next() {
    this.page = 1;
  }
  prev() {
    this.page = 0;
  }

  apply() {
    this.submit = true;
    let valid: boolean = true;
    for (let i of this.questionAnswerList) {
      if (i.question.userDefined1 == 'active' && (i.answer.trim() == "" || i.answer == null)) {
        valid = false;
        break;
      }
    }
    if (valid && this.jobSeekerModel.resumePath != "" && this.jobSeekerModel.resumePath != undefined && this.jobSeekerModel.resumePath != null) {
      this.submitAnswer()
    } else if (this.jobSeekerModel.resumePath == "" || this.jobSeekerModel.resumePath == undefined || this.jobSeekerModel.resumePath == null) {
      this.tost.info("Please upload Cv");
    }
  }

  submitAnswer() {
    this.loader.loader = true;
    this.questionAnsSrv.createMultiple(this.questionAnswerList).subscribe((data) => {
      if (data["isSuccess"]) {
        this.applyToJob();
        // this.router
        window.location.reload();
      } else {
        this.loader.loader = false;
      }
    })
  }


}

