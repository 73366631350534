<div class="twm-jobs-list-style1 mb-5" *ngIf="job != null">
    <div class="twm-media flex">
        <img *ngIf="!error" [src]="baseUrl + job.company?.userDefined2" alt="#" (error)="imageError()">
        <i *ngIf="error" class="fas fa-business-time i"></i>
        <p class="twm-job-address">{{job.company?.location}}</p>
    </div>
    <div class="twm-mid-content">
        <h4>{{job.company['nameEn']}}</h4>
        <a (click)="urlChange('/job-detail?Id=' + job.id)" class="twm-job-title">
            <h4>{{job.jobTitle}}<br /><span class="twm-job-post-duration">Posted
                    {{masterService.getDateDiff(job.creationDate)}}</span></h4>
        </a>
        <p class="twm-job-address">{{job?.jobLocation}}, {{job?.country?.nameEn}}</p>
        <!-- <a href="https://themeforest.net/user/thewebmax/portfolio" class="twm-job-websites site-text-primary">https://thewebmax.com</a> -->
        <!-- <div class="twm-jobs-category green pointer"><span class="twm-bg-sky"><i class="fa fa-save"></i> Save</span>
        </div> -->
        <!-- <button _ngcontent-lkq-c45="" class="site-button" style="color: white;"><i class="fa fa-save"></i>
            Save</button> -->
    </div>
    <div class="twm-right-content">
        <div class="twm-jobs-category green"><span class="twm-bg-green">{{job.jobType}}</span></div>
        <div class="twm-jobs-amount">{{job.userDefined1}} - {{job.userDefined3}} <span>/ {{job.userDefined2}}</span>
        </div>
        <a (click)="urlChange('/job-detail?Id=' + job.id)" class="twm-jobs-browse site-text-primary">Browse Job</a>

    </div>
</div>