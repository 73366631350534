import {Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class MessageService {
    private subject = new Subject();
 
    sendMessage(message: string) {
        this.subject.next({ text: message });
    }
 
    clearMessages() {
        this.subject.next();
    }
 
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}