<!-- Navbar -->
<app-navbar-style-one></app-navbar-style-one>
<!-- End Navbar -->

<!-- Page Title -->
<div>
    <!-- Add this HTML code to your page -->
    <section class="blog-area pt-100 pb-60;" style="background-color: #1e3142;">
        <div class="container">
            <div class="row">
                <div class="text-center">
                    <div class="content">
                        <h2 class="text-white">Blog Details Section</h2>
                        <h4 class="text-white h2">{{ blogDetail.headingEn }}</h4>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<div class="py-4">
    <div class="container">
        <div class="img">
            <img [src]="baseUrl + blogDetail.userDefined1" class="rounded" />
        </div>
    </div>
</div>
<div class="container d-flex justify-content-between align-items-center">
    <p class="h6 advice-color text-center m-0">ABOUT THE AUTHOR</p>
    <p class="card-subtitle  text-muted text-center"> <img style="height: 25px; width: 25px"
            src="../../../../assets/img/mahad_favico.png" /> BY <span style="color:#1969d9">MAHAD JOBS</span> |
        {{currentYear}}</p>
</div>
<div class="container mt-2">
    <div class="">
        <div class="card-body">
            <h5 class="card-title font-weight-bold text-dark h3">{{ blogDetail.headingEn }}</h5>
            <p class="advice-color h6">Informational blog posts</p>
            <p class="card-text" style="overflow: hidden;" [innerHtml]="blogDetail?.descriptions"></p>
        </div>
    </div>
</div>

<section class="blog-area  pb-70">
    <div class="container">
        <div class="row">
        </div>
    </div>
</section>
